import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Divider,
  Button,
  Table,
  Space,
  Modal,
  message,
  Drawer,
  Tag,
} from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import TransactionMap from "./TransactionMap";
import { CSVLink } from "react-csv";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";
import moment from "moment";
import SearchForm from "./SearchForm";
import { v4 as uuidv4 } from "uuid";
import { getOverridedColumns } from "../../utils/tableLoader";

const TransactionsSTD = (props) => {
  const { mainStore } = useMobxStores();
  const [isModalOpen, setIsModalOpen] = useState(props.openModal);
  const [showmap, setShowMap] = useState(false);
  const [coords, setCoords] = useState([[10], [10]]);

  useEffect(() => {
    // mainStore.GetTransactions("86b8b6bd42ce110000000900");
    console.log("1");
    mainStore.GetTransactions(props.ids.projectId);
  }, []);

  const onChange = (info) => {
    if (info.file.status !== "uploading") {
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const [reportModalData, setReportModalData] = useState([]);
  const [csvData, setData] = useState([]);

  const OnMap = (record) => {
    setShowMap(true);
    setCoords([record.attributes.latitude, record.attributes.longitude]);
  };
  const closeMap = () => {
    mainStore.setSupplyChain([]);
    setShowMap(false);
  };

  const showModal = (record) => {
    setReportModalData(record);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  let user = window.localStorage.getItem("user");
  user = JSON.parse(user);

  const onClickStartTransaction = () => {
    let values = mainStore.transaction;
    values.title = "true";
    mainStore.StartTransactions(values);
  };

  const onClickStopTransaction = () => {
    let values = mainStore.transaction;
    values.title = "false";
    mainStore.StopTransactions(values);
  };

  const columns = [
    {
      title: "Amount",
      responsive: ["lg", "sm", "md", "xs"],
      key: "Amount",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.attributes.amount}</>
        </p>
      ),
    },
    {
      title: "Buyer",
      responsive: ["lg", "xl"],
      key: "Buyer",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.attributes.buyer}</>
        </p>
      ),
    },
    {
      title: "Buyer licence",
      responsive: ["lg", "xs"],
      key: "title",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.attributes.buyerlicence}</>
        </p>
      ),
    },
    {
      title: "Country",
      responsive: ["lg", "sm", "md", "xs"],
      key: "title",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <>{record && record.attributes.country}</>
        </p>
      ),
    },

    {
      title: "Suspicious",
      responsive: ["lg", "xs"],
      key: "title",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.attributes.suspicious}</>
        </p>
      ),
    },
    {
      title: "Seller",
      responsive: ["lg", "sm", "md", "xs"],
      key: "title",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.attributes.seller}</>
        </p>
      ),
    },
    {
      title: "Item Description",
      responsive: ["lg", "xl"],
      key: "title",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.attributes.item_description}</>
        </p>
      ),
    },

    {
      title: "Time date",
      responsive: ["lg", "xl"],
      key: "title",
      render: (record) => (
        <Tag style={{ color: "orange" }}>
          <>
            {record &&
              moment(record.attributes.timedate).format(
                "MMMM Do YYYY, h:mm:ss a"
              )}
          </>
        </Tag>
      ),
    },
    {
      title: "Actions",
      responsive: ["lg", "sm", "md", "xs"],
      key: "action",
      width: "auto",
      render: (record) => (
        <Space direction="vertical">
          <Button
            size="small"
            key={record + record}
            type="primary"
            onClick={() => OnMap(record)}
            style={{ width: "100px" }}
          >
            On Map
          </Button>
        </Space>
      ),
    },
  ];

  console.log(coords);

  return (
    <Row justify="center" align="center" style={{ marginBottom: "-32px" }}>
      <Modal
        title="Report Information"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {/* {reportModalData !=[] && reportModalData.attributes.map((item) => {
              return item;
            })} */}
      </Modal>
      <SearchForm
        projectId={props.ids.projectId}
        onClick={(data) => {
          props.onClick(data);
        }}
      />
      <br />
      <br />

      <Drawer
        title="Transaction on Map"
        placement="right"
        size={"large"}
        onClose={() => closeMap()}
        open={showmap}
        headerStyle={{ textAlign: "center" }}
      >
        <TransactionMap key={coords[0][0]} coords={coords} />
      </Drawer>
      {/* </Col> */}
    </Row>
  );
};

export default observer(TransactionsSTD);
