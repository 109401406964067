import React, { useEffect } from "react";
import Icon from "@mdi/react";
import { mdiChartBubble } from "@mdi/js";
import { mdiPencil } from "@mdi/js";

import { Col, Row, Divider, Button, Table, Space, Popover, Tag } from "antd";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import CreateMission from "../mission/CreateMission";
import { getOverridedColumns } from "../../utils/tableLoader";
import CreateOperation from "../mission/CreateOperation";
import { useNavigate } from "react-router-dom";
import { dateForTables } from "../../utils/formatDate";
import AddIngredient from "../../components/Transactions/AddIngredient";
import AddRecipe from "../../components/Transactions/AddRecipe";

var _ = require("lodash");

const MissionsTable = (props) => {
  const { transactionsStore, missionsStore } = useMobxStores();
  const navigate = useNavigate();

  useEffect(() => {
    transactionsStore.GetRecipes();
  }, []);

  useEffect(() => {
    transactionsStore.GetIngredients();
  }, []);

  const onAddIngredient = (record) => {
    transactionsStore.setIsIngredientModalOpen(true);
  };

  const onAddRecipe = (record) => {
    transactionsStore.setIsRecipeModalOpen(true);
  };
  const deleteRecipe = (record) => {
    console.log(record);
    transactionsStore.DeleteRecipeFromProject(record);
  };
  const deleteIngredient = (ingredient) => {
    console.log(ingredient);
    transactionsStore.DeleteIngredient(ingredient);
  };

  const columns = [
    {
      title: "Name - Alias",
      responsive: ["lg", "sm", "md", "xs"],
      render: (_, record) => (
        <div style={{ height: "20px", width: "100%" }}>
          {" "}
          <span style={{ width: "60%" }}>{record.name}</span>{" "}
          {record.attributes.alias[0] && (
            <span>
              <span style={{ marginLeft: "4px", marginRight: "4px" }}>-</span>
              <span> {record.attributes.alias[0]}</span>
            </span>
          )}
        </div>
      ),
      width: "37%",
    },
    {
      title: (
        <div>
          {" "}
          <div>Ingredients (Percentage - Name -Threshold)</div>
        </div>
      ),
      responsive: ["lg", "sm", "md", "xs"],
      render: (_, record) => (
        <div
          class="showOnPrintAllHeight"
          style={{
            height: "60px",
            overflowY: "auto",
            border: "#B0BEC5 solid 1px",
          }}
        >
          {JSON.parse(record.attributes.ingredients[0]).map((ingredient) => {
            return (
              <Row>
                <div style={{ height: "20px", width: "100%" }}>
                  {" "}
                  <span style={{ width: "60%" }}>
                    <span> {ingredient.amount}%</span>
                  </span>
                  <span style={{ marginLeft: "4px", marginRight: "4px" }}>
                    -
                  </span>
                  <span style={{ marginLeft: "4px" }}>{ingredient.name}</span>
                  <span style={{ marginLeft: "4px", marginRight: "4px" }}>
                    -
                  </span>
                  <span> {ingredient.threshold}</span>
                </div>
                <Divider
                  style={{ marginTop: "2px", marginBottom: "2px" }}
                ></Divider>
              </Row>
            );
          })}
        </div>
      ),
      width: "60%",
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              deleteRecipe(record);
            }}
            type="default" // Set the button type to "default" to get a white button
            // onClick={() => missionsStore.DeleteMission(record.id)}
            size="medium"
            style={{
              width: "60px",
              backgroundColor: "red",
              color: "white",
              marginLeft: "6px",
            }} // Set the background color to white and font color to blue
          >
            <Row align="center">
              <span>
                <DeleteFilled
                  style={{ fontSize: "16px", marginRight: "0px" }}
                />
              </span>
            </Row>
          </Button>

          {/* <Button
            type="default" // Set the button type to "default" to get a white button
            size="medium"
            style={{
              backgroundColor: "blue",
              color: "white",
              marginLeft: "6px",
            }} // Set the background color to white and font color to blue
          >
            <Row align="center">
              <span>
                <Icon style={{ color: "white" }} path={mdiPencil} size={1} />
              </span>
              <span>Edit</span>{" "}
            </Row>
          </Button> */}
        </Space>
      ),
      width: "3%",
    },
  ];
  const columnsIngredients = [
    {
      title: "Name - Alias",
      responsive: ["lg", "sm", "md", "xs"],
      render: (_, record) => (
        <div style={{ height: "20px", width: "100%" }}>
          {" "}
          <span style={{ width: "60%" }}>{record.title}</span>{" "}
          {record.attributes.threshold[0] && (
            <span>
              <span style={{ marginLeft: "4px", marginRight: "4px" }}>-</span>
              <span> {record.attributes.threshold[0]}</span>
            </span>
          )}
        </div>
      ),
      width: "95%",
    },

    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              deleteIngredient(record);
            }}
            type="default" // Set the button type to "default" to get a white button
            // onClick={() => missionsStore.DeleteMission(record.id)}
            size="medium"
            style={{
              width: "60px",
              backgroundColor: "red",
              color: "white",
              marginLeft: "6px",
            }} // Set the background color to white and font color to blue
          >
            <Row align="center">
              <span>
                <DeleteFilled
                  style={{ fontSize: "16px", marginRight: "0px" }}
                />
              </span>
            </Row>
          </Button>
        </Space>
      ),
      width: "5%",
    },
  ];

  return (
    <div>
      <Row justify="start" align="start" gutter={[20, 20]}>
        <Col span={15} lg={15} md={15} xs={15} sm={15}>
          <Row
            style={{
              marginTop: "4px",
              // backgroundColor: "#003B73",
              // color: "white",
              height: "40px",
              marginBottom: "16px",
              marginTop: "-12px",
            }}
            justify="center"
          >
            <span
              style={{
                fontSize: "20px",
                marginTop: "18px",
              }}
            >
              Recipes
            </span>
          </Row>{" "}
          <Row
            className="addRecipeButton"
            style={{
              marginTop: "4px",
              // backgroundColor: "#003B73",
              // color: "white",
              height: "40px",
              marginBottom: "24px",
              marginTop: "-12px",
            }}
            justify="end"
          >
            <span style={{ marginTop: "16px" }}>
              <span style={{ marginRight: "4px" }}>
                <Button
                  className="addRecipeButton"
                  type="default" // Set the button type to "default" to get a white button
                  size="medium"
                  onClick={() => transactionsStore.setIsRecipeModalOpen(true)}
                  style={{ backgroundColor: "#354289", color: "white" }} // Set the background color to white and font color to blue
                >
                  <div align="center">
                    <span>
                      <PlusOutlined
                        style={{
                          fontSize: "20px",
                          marginRight: "4px",
                          color: "white",
                        }}
                      />
                    </span>
                    <span>Add Recipe</span>
                  </div>
                </Button>
              </span>
            </span>
          </Row>{" "}
          <Row justify="center">
            <AddRecipe />
            <AddIngredient />
            {/* <Divider orientation="left">Missions</Divider> */}
            {transactionsStore.Recipes.length === 0 && <div> </div>}
            {transactionsStore.Recipes.length !== 0 && (
              <Table
                className="custom-table"
                style={{ width: "100%" }}
                size="small"
                indentSize={12}
                bordered={true}
                scroll={{ x: "400px" }}
                rowClassName="custom-row-hover"
                dataSource={
                  transactionsStore.Recipes != null
                    ? _.cloneDeep(transactionsStore.Recipes)
                    : Array(5).fill({})
                }
                columns={
                  transactionsStore.Recipes != null
                    ? columns
                    : getOverridedColumns(columns)
                }
                rowKey={(record) => record.id}
              />
            )}
          </Row>
        </Col>
        <Divider style={{ height: "500px" }} type="vertical"></Divider>
        <Col span={8} lg={8} md={8} xs={8} sm={8}>
          <Row
            style={{
              marginTop: "4px",
              // backgroundColor: "#003B73",
              // color: "white",
              height: "40px",
              marginBottom: "16px",
              marginTop: "-12px",
            }}
            justify="center"
          >
            <span
              style={{
                fontSize: "20px",
                marginTop: "18px",
              }}
            >
              Ingredients
            </span>
          </Row>{" "}
          <Row
            className="addRecipeButton"
            style={{
              marginTop: "4px",
              // backgroundColor: "#003B73",
              // color: "white",
              height: "40px",
              marginBottom: "24px",
              marginTop: "-12px",
            }}
            justify="end"
          >
            <span style={{ marginTop: "16px" }}>
              <span style={{ marginRight: "4px" }}>
                <Button
                  className="addRecipeButton"
                  type="default" // Set the button type to "default" to get a white button
                  size="medium"
                  onClick={() =>
                    transactionsStore.setIsIngredientModalOpen(true)
                  }
                  style={{ backgroundColor: "#354289", color: "white" }} // Set the background color to white and font color to blue
                >
                  <div align="center">
                    <span>
                      <PlusOutlined
                        style={{
                          fontSize: "20px",
                          marginRight: "4px",
                          color: "white",
                        }}
                      />
                    </span>
                    <span>Add Ingredient</span>
                  </div>
                </Button>
              </span>
            </span>
          </Row>{" "}
          <Row justify="center">
            <AddRecipe />
            <AddIngredient />
            {/* <Divider orientation="left">Missions</Divider> */}
            {transactionsStore.Ingredients.length === 0 && <div> </div>}
            {transactionsStore.Ingredients.length !== 0 && (
              <Table
                className="custom-table"
                style={{ width: "100%" }}
                size="small"
                indentSize={12}
                bordered={true}
                // scroll={{ x: "400px" }}
                rowClassName="custom-row-hover"
                dataSource={
                  transactionsStore.Ingredients != null
                    ? _.cloneDeep(transactionsStore.Ingredients)
                    : Array(5).fill({})
                }
                columns={
                  transactionsStore.Ingredients != null
                    ? columnsIngredients
                    : getOverridedColumns(columnsIngredients)
                }
                rowKey={(record) => record.id}
              />
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default observer(MissionsTable);
