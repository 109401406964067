import React, { useState, useEffect, useRef } from "react";
import { PlusOutlined, ImportOutlined } from "@ant-design/icons";
import { Outlet, Link, useParams, useNavigate } from "react-router-dom";
import { Row, Col, Divider, Button, Layout, Menu } from "antd";
import { RollbackOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import { useMobxStores } from "../stores/stores";
import CreateOperation from "../components/mission/CreateOperation";
import ImportOperation from "../components/mission/ImportOperation";
import Loader from "../components/loader/Loader";
import { dateForTables } from "../utils/formatDate";
import { mdiQuadcopter, mdiCash100 } from "@mdi/js";
import { ReactComponent as AiIcon } from "../assets/AiIcon.svg";
import Icon from "@mdi/react";
import UserStore from "../stores/UserStore";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
const { Header, Content, Footer, Sider } = Layout;

const Mission = () => {
  const { missionsStore, usersStore, mainStore, transactionsStore } =
    useMobxStores();
  const navigate = useNavigate();
  const [operationShown, setOperationShown] = useState("");
  const [hoveredOperation, setHoveredOperation] = useState("");
  const [itemsGroup, setItemsGroup] = useState(1);
  const [items, setItems] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [mission, setMission] = useState(missionsStore.pickedMission);
  const [operationPicked, setOperationPicked] = useState(
    missionsStore.operationPicked
  );

  const [siderWidth, setSiderWidth] = useState(window.innerWidth * 0.17);

  let params = useParams();
  const location = useLocation();

  // Create a URLSearchParams object to work with the query parameters
  const queryParams = new URLSearchParams(location.search);

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  const dele = () => {
    transactionsStore.DeleteThisRecipeFromProject();
  };
  const parentRef = useRef();

  useEffect(() => {
    mainStore.GetArtefacts(params.pk);
    missionsStore.setOperationsOfPickedMission([]);
    missionsStore.GetMissionsById(params.pk);
    missionsStore.GetMissionOperations(params.pk);
    mainStore.setClusteringModuleEmpty();

    if (params.id) {
      missionsStore.GetOperationPicked(params.pk, params.id);
    }

    const handleResize = () => {
      if (window.innerWidth <= 1280) {
        setSiderWidth(window.innerWidth * 0.22); // Adjust width based on new window width
        // console.log(siderWidth + "small");
      }
      if (window.innerWidth > 1280) {
        setSiderWidth(window.innerWidth * 0.12); // Adjust width based on new window width
        // console.log(siderWidth + "big");
      }

      // setSiderHeight(window.innerHeight - 30);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up the event listener
    };
  }, [params.id, params.pk]);

  useEffect(() => {
    // console.log("params.id");
    // console.log(window.innerWidth);
    const handleResize = () => {
      // setSiderHeight(window.innerHeight - 30);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up the event listener
    };
  }, [window.innerWidth]);

  useEffect(() => {
    missionsStore.setOperationsOfPickedMission([]);
    missionsStore.GetMissionOperations(params.pk);
    setMission(missionsStore.pickedMission);
    if (params.id) {
      missionsStore.GetOperationPicked(params.pk, params.id);
    }
  }, [missionsStore.pickedMission]);

  useEffect(() => {
    if (
      usersStore.currentUser &&
      usersStore.currentUser.type &&
      usersStore.currentUser.type.includes("platform_administrator")
    ) {
      navigate(`/users`);
    }
  }, [usersStore.currentUser]);

  useEffect(() => {
    if (params.id) {
      missionsStore.GetOperationPicked(params.pk, params.id);
    }
    // console.log(params.id);
  }, [params.id]);

  useEffect(() => {
    setOperationPicked(missionsStore.operationPicked);
  }, [missionsStore.operationPicked]);

  const onRollBackClicked = () => {
    navigate(`/missions`);
  };

  const showSpinner = () => {
    if (!showLoader) {
      setShowLoader(true);
      setTimeout(() => {
        setShowLoader(false);
      }, 2000);
    }
  };

  const showItem = (item) => {
    console.log(item);
    setOperationShown(item);
  };
  const showHoverItem = (item) => {
    console.log(item);
    setHoveredOperation(item);
  };

  const onAddOperation = () => {
    console.log(params);
    missionsStore.setIsOperationModalOpen(true);
    missionsStore.setPid(params.pk);
  };
  const onImportOperation = () => {
    console.log(params);
    missionsStore.setIsImportOperationModalOpen(true);
    missionsStore.setPid(params.pk);
  };

  useEffect(() => {
    if (missionsStore.operationsOfPickedMission.length > 0) {
      // console.log(missionsStore.operationsOfPickedMission);
      console.log(missionsStore.operationsOfPickedMission);
      let currentUserOperations = [];
      if (
        usersStore.currentUser &&
        usersStore.currentUser.type &&
        (usersStore.currentUser.type.includes("webanalyst") ||
          usersStore.currentUser.type.includes("transaction_analyst") ||
          usersStore.currentUser.type.includes("field_operator"))
      ) {
        currentUserOperations = missionsStore.operationsOfPickedMission.filter(
          (operation) => {
            console.log(operation.attributes.assignee);
            console.log(usersStore.currentUser.name);
            return (
              operation.attributes.assignee &&
              operation.attributes.assignee.includes(
                usersStore.currentUser.name
              )
            );
          }
        );
      }
      if (
        usersStore.currentUser &&
        usersStore.currentUser.type &&
        usersStore.currentUser.type.includes("mission_commander")
      ) {
        currentUserOperations = missionsStore.operationsOfPickedMission;
      }

      //   missionsStore.operationsOfPickedMission.attributes.assignee.includes(
      //     usersStore.currentUser.name
      //   );
      console.log("currentUserOperations");
      console.log(currentUserOperations);
      let newItems = [];

      for (let i = 0; i < currentUserOperations.length; i++) {
        if (currentUserOperations[i].type === "field") {
          newItems.push({
            // title: currentUserOperations[i].title,
            title: currentUserOperations[i].name,
            type: "field",
            id: currentUserOperations[i].attributes.operationId,
            projectId: currentUserOperations[i].projectId,
            key: currentUserOperations[i].id,
            icon: <Icon path={mdiQuadcopter} size={1} />,
          });
        }
        if (currentUserOperations[i].type === "transaction analysis") {
          newItems.push({
            // title: currentUserOperations[i].title,
            title: currentUserOperations[i].name,
            type: "transaction analysis",
            id: currentUserOperations[i].attributes.operationId,
            projectId: currentUserOperations[i].projectId,
            key: currentUserOperations[i].id,
            icon: <Icon path={mdiCash100} size={1} />,
          });
        }
        if (
          currentUserOperations[i].type === "web_crawl" &&
          currentUserOperations[i].id !== operationShown.key &&
          currentUserOperations[i].id !== hoveredOperation.key
        ) {
          newItems.push({
            // title: currentUserOperations[i].title,
            title: currentUserOperations[i].name,
            type: "web_crawl",
            id: currentUserOperations[i].attributes.operationId,
            projectId: currentUserOperations[i].projectId,
            key: currentUserOperations[i].id,
            icon: (
              <AiIcon
                style={{ width: "28px", height: "28px", fill: "#8C8C8C" }}
              />
            ),
          });
        }
        if (
          currentUserOperations[i].type === "web_crawl" &&
          currentUserOperations[i].id === operationShown.key &&
          currentUserOperations[i].id !== hoveredOperation.key
        ) {
          newItems.push({
            // title: currentUserOperations[i].title,
            title: currentUserOperations[i].name,
            type: "web_crawl",
            id: currentUserOperations[i].attributes.operationId,
            projectId: currentUserOperations[i].projectId,
            key: currentUserOperations[i].id,
            icon: (
              <AiIcon
                style={{ width: "28px", height: "28px", fill: "#ffffff" }}
              />
            ),
          });
        }
        if (
          currentUserOperations[i].type === "web_crawl" &&
          currentUserOperations[i].id === hoveredOperation.key
        ) {
          newItems.push({
            // title: currentUserOperations[i].title,
            title: currentUserOperations[i].name,
            type: "web_crawl",
            id: currentUserOperations[i].attributes.operationId,
            projectId: currentUserOperations[i].projectId,
            key: currentUserOperations[i].id,
            icon: (
              <AiIcon
                style={{ width: "28px", height: "28px", fill: "#ffffff" }}
              />
            ),
          });
        }
      }
      // console.log("newItems");
      console.log(newItems);
      setItems(newItems);
      // setItemsReactive(items.slice(0, 8));
    } else {
      setItems([]);
      // console.log([]);
    }
  }, [
    missionsStore.operationsOfPickedMission,
    operationShown,
    hoveredOperation,
  ]);

  function getItem(label, key, icon) {
    return {
      key,
      icon,
      label,
    };
  }

  return (
    <Layout
      style={{
        minHeight: "580px",
        height: "100%",
        marginTop: "10px",
      }}
    >
      <Header
        style={{
          backgroundColor: "#003B73",
          height: "80px",
          marginLeft: "-10px",
          marginTop: "-10px",
          width: "99vw",
        }}
      >
        <Row
          justify={"space-between"}
          style={{
            marginTop: "0px",
            backgroundColor: "#003B73",
            color: "white",
            // width: "97vw",
            left: 0,
            height: "60px",
            marginLeft: "-30px",
          }}
        >
          <span style={{ marginLeft: "0px" }}>
            <div style={{ marginTop: "-12px" }}>
              <span style={{ fontWeight: "bold" }}>Mission name:</span>
              <span style={{ marginLeft: "10px" }}>{mission.name}</span>
            </div>
            <div style={{ marginTop: "-44px" }}>
              <span style={{ fontWeight: "bold" }}>Description:</span>
              <span style={{ marginLeft: "10px" }}>
                {mission.description &&
                mission.description.includes("?creator_name=") ? (
                  <span>{mission.description.split("?creator_name=")[0]} </span>
                ) : (
                  <span>{mission.description} </span>
                )}
              </span>
            </div>
            <div style={{ marginTop: "-44px", marginBottom: "8px" }}>
              <span style={{ fontWeight: "bold" }}>Created on: </span>
              <span style={{ marginLeft: "10px" }}>
                {dateForTables(mission.created)}
              </span>
              <span style={{ marginLeft: "20px", fontWeight: "bold" }}>
                Created by:{" "}
              </span>
              <span style={{ marginLeft: "10px" }}>
                {mission.description &&
                mission.description.includes("?creator_name=") ? (
                  <span>{mission.description.split("?creator_name=")[1]} </span>
                ) : (
                  <span> Unknown </span>
                )}
              </span>
            </div>
          </span>
          <span style={{ marginTop: "16px", marginRight: "12px" }}>
            <span style={{ marginRight: "4px" }}>
              <Button
                type="default" // Set the button type to "default" to get a white button
                onClick={onImportOperation} // Handle the click event
                size="medium"
                style={{ backgroundColor: "#354289", color: "white" }} // Set the background color to white and font color to blue
              >
                <div align="center">
                  <span>
                    <ImportOutlined
                      style={{
                        fontSize: "20px",
                        marginRight: "4px",
                        color: "white",
                      }}
                    />
                  </span>
                  <span>Import data</span>
                </div>
              </Button>
            </span>
            <span style={{ marginRight: "-2px" }}>
              <Button
                type="default" // Set the button type to "default" to get a white button
                onClick={onAddOperation} // Handle the click event
                size="medium"
                style={{ backgroundColor: "#354289", color: "white" }} // Set the background color to white and font color to blue
              >
                <div align="center">
                  <span>
                    <PlusOutlined
                      style={{
                        fontSize: "20px",
                        marginRight: "4px",
                        color: "white",
                      }}
                    />
                  </span>
                  <span>Add operation</span>
                </div>
              </Button>
            </span>
            <span>
              <Button
                type="default" // Set the button type to "default" to get a white button
                onClick={onRollBackClicked} // Handle the click event
                size="medium"
                style={{
                  backgroundColor: "#354289",
                  color: "white",
                  marginLeft: "6px",
                }} // Set the background color to white and font color to blue
              >
                <Row align="center">
                  <span>
                    <RollbackOutlined
                      style={{ fontSize: "20px", marginRight: "4px" }}
                    />
                  </span>
                  <span>Go to missions</span>{" "}
                </Row>
              </Button>
            </span>
          </span>
        </Row>
      </Header>
      <Layout height="100vh">
        <Sider
          trigger={null}
          width="16rem"
          height="96vh"
          // height="88vh"
          style={{
            marginLeft: "-8px",
            overflow: "auto",
            // height: "100vh",
            // minHeight: "88vh",
            // minHeight: "800px",
            backgroundColor: "rgb(5, 10, 48)",
            position: "relative",
          }}
          // class="responsive-div"
        >
          {/* <div className="demo-logo-vertical" /> */}
          <div
            style={{
              // width: "200px",
              // width: "20rem",
              backgroundColor: "#050A30",
              color: "white",
              maxHeight: "300px",
            }}
            class="responsive-div"
          >
            <Row justify="center">
              <div
                style={{
                  marginTop: "18px",
                  marginBottom: "18px",
                  fontSize: "18px",
                }}
              >
                Operations
              </div>
            </Row>
          </div>
          <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
            {" "}
            {items.length > 0 &&
              items.map((item) => (
                <Menu.Item
                  onMouseEnter={() => {
                    showHoverItem(item);
                  }}
                  onMouseLeave={() => {
                    showHoverItem("");
                  }}
                  // onClick={() => {
                  //   showSpinner();
                  // }}
                  key={item.key}
                  icon={item.icon}
                >
                  {" "}
                  {item.type === "web_crawl" && (
                    <Link
                      to={"/mission/" + params.pk + "/web_crawl/" + item.id[0]}
                    >
                      {item.title}
                    </Link>
                  )}
                  {item.type === "transaction analysis" && (
                    <Link
                      to={
                        "/mission/" + params.pk + "/transactions/" + item.id[0]
                      }
                    >
                      {item.title}
                    </Link>
                  )}
                  {item.type === "field" && (
                    <Link
                      to={
                        "/mission/" +
                        params.pk +
                        "/field_operator/" +
                        item.id[0] +
                        "/session/realtime"
                      }
                    >
                      {item.title}
                    </Link>
                  )}
                  {/* Link to the desired route */}
                </Menu.Item>
              ))}
          </Menu>
        </Sider>
        {showLoader && <Loader></Loader>}
        {!showLoader && (
          <Content
            style={{
              margin: "24px 16px",
              marginTop: "0px ",
              padding: 24,
              height: "120%",
              background: "rgb(0, 0, 0,0)",
              borderRadius: 80,
              // border: "solid black 1px",
              width: "90vw",
            }}
          >
            {!params.id && (
              <Row
                justify={"start"}
                style={{
                  color: "black",

                  marginTop: "-10px",
                }}
              >
                <span style={{ marginLeft: "8px" }}>
                  <div style={{ marginBottom: "80vh" }}>
                    <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                      No Operation selected
                    </span>
                  </div>
                </span>
              </Row>
            )}
            {params.id && (
              <Row
                ref={parentRef}
                justify={"start"}
                style={{
                  color: "black",

                  marginTop: "-10px",
                }}
              >
                <span style={{ marginLeft: "8px" }}>
                  <div>
                    <span style={{ fontWeight: "bold" }}>Operation name:</span>
                    <span style={{ marginLeft: "10px" }}>
                      {operationPicked.name}
                    </span>
                    <span style={{ fontWeight: "bold", marginLeft: "8px" }}>
                      Operation Description:
                    </span>
                    <span style={{ marginLeft: "10px" }}>
                      {operationPicked.title}
                    </span>
                  </div>
                  <div
                    style={{
                      marginTop: "6px",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Operation id:</span>
                    <span style={{ marginLeft: "10px" }}>
                      {operationPicked.attributes.operationId[0]}
                    </span>
                  </div>

                  <div style={{ marginTop: "6px", marginBottom: "2px" }}>
                    <span style={{ fontWeight: "bold" }}>Created on: </span>
                    <span style={{ marginLeft: "10px" }}>
                      {dateForTables(operationPicked.created)}
                    </span>
                    <span style={{ fontWeight: "bold", marginLeft: "10px" }}>
                      Location:{" "}
                    </span>
                    {operationPicked &&
                    operationPicked.attributes &&
                    operationPicked.attributes.location ? (
                      <span style={{ marginLeft: "10px" }}>
                        {operationPicked.attributes.location}
                      </span>
                    ) : (
                      <span> Unknown </span>
                    )}
                  </div>
                  <div style={{ marginTop: "6px", marginBottom: "2px" }}>
                    <span style={{ fontWeight: "bold" }}>Assigned to: </span>
                    {operationPicked &&
                    operationPicked.attributes &&
                    operationPicked.attributes.assignee &&
                    operationPicked.attributes.assignee.length > 0 ? (
                      operationPicked.attributes.assignee.map(
                        (assignee, index) => {
                          return (
                            <span style={{ marginLeft: "10px" }}>
                              {assignee}{" "}
                              {index !==
                                operationPicked.attributes.assignee.length -
                                  1 && <span>,</span>}
                            </span>
                          );
                        }
                      )
                    ) : (
                      <span> Unknown </span>
                    )}
                  </div>
                  {/* <div style={{ marginTop: "6px", marginBottom: "2px" }}></div> */}
                  {/* <div class="responsive-div">ojojom</div> */}
                </span>
              </Row>
            )}

            {/* <div ref={componentRef}> */}
            <Outlet context={{ parentRef }} />
            {/* </div> */}
            <div style={{ minWidth: "1000px" }}>
              {" "}
              <CreateOperation projectId={params.pk} />
              <ImportOperation projectId={params.pk} />
              {missionsStore.pickedMission &&
                missionsStore.operationsOfPickedMission && (
                  <div
                    style={{
                      marginTop: "6px",
                    }}
                  >
                    <Row align={"start"} justify={"start"}>
                      <Col span={4}>
                        <div> </div>
                        <></>
                      </Col>

                      <Col span={1}></Col>
                      <Col span={18}></Col>
                    </Row>
                  </div>
                )}
            </div>
          </Content>
        )}
      </Layout>
    </Layout>
  );
};

export default observer(Mission);
