import { makeAutoObservable } from "mobx";
import {
  POST,
  PUBLISH,
  GET_SDMA,
  POST_SDMA,
  DELETE_SDMA,
  PUT_SDMA,
  GET_SDMA_IMAGE,
  GET_SDMA_MANY,
  POST_Test,
} from "../apis/apis";
import { constants } from "../consts/Variables";
import { v4 as uuidv4 } from "uuid";

class WebCrawlStore {
  constructor({ errStore }) {
    makeAutoObservable(this);
    this.errStore = errStore;
  }
  showGroups = false;

  blueGroup = [];
  orangeGroup = [];
  redGroup = [];
  purpleGroup = [];
  limeGroup = [];
  greenGroup = [];
  cyanGroup = [];
  yellowGroup = [];

  analyzedCharacters = [];
  repliesObjects = [];
  replies = [];
  projectId = "";
  filteredPosts = [];
  authors = [];
  analysis_entities = "";
  channel = "";
  webCrawlTypeIsSocialMedial = false;
  reportPage = "";
  webCrawlView = "list";
  withTransaltionChecked = false;
  withVisualAnalysis = false;
  selectedClassification = "All";
  selectedCluster = "All";
  filteredPagesByCluster = [];
  alertsOn = false;
  selectedKey = "1";
  dateFormat = "YYYY/MM/DD";
  status = "success";
  webdata = [];
  field_operation = null;
  changeLogItem = [];
  artefacts = [];
  projects = [];
  artefact = null;
  entities = [];
  entity = null;
  links = [];
  link = null;
  accessLogItem = [];
  currentKey = window.location.pathname;
  date = new Date();

  receivedItems = [];
  pageSize = 100000000;
  waterSensorData = [];
  classification_module = [];
  clustering_module = [];
  social_network_analysis = [];
  filteredItems = [];
  searchedData = [];
  isSearching = false;
  battery = "";
  notifications = [];
  temp = { body: null, images: [] };
  wp_connection = false;
  pieData = [];
  analyse = false;
  analyse_entities = [];
  mapping = [
    { type2: "ner:chemical", type1: "hasSubstance", color: "#F44336" },
    { type2: "ner:date", type1: "hasDate", color: "#BDB76B" },
    { type2: "vu:glassware", type1: "vu:Glassware", color: "#FAEBD7" },
    { type2: "ner:location", type1: "hasLocation", color: "#7FFF00" },
    { type2: "ner:group", type1: "hasGroup", color: "#D2691E" },

    { type2: "ner:item", type1: "hasItem", color: "#FF7F50" },
    // { type2: "ner:person", type1: "hasPerson", color: "#DC143C" },
    { type2: "ner:person", type1: "hasPerson", color: "#40C4FF" },

    { type2: "ner:quantity", type1: "hasQuantity", color: "#00FFFF" },
    { type2: "ner:status", type1: "hasStatus", color: "#00008B" },
    // { type2: "ner:art", type1: "hasArt", color: "#FF8C00" },
    { type2: "ner:art", type1: "hasArt", color: "#E040FB" },
  ];
  pieData = [
    {
      color: "#F44336",
      name: "Substance",
      value: 0,
    },
    {
      color: "#BDB76B",
      name: "Date",
      value: 0,
    },
    {
      color: "#FAEBD7",
      name: "Glassware",
      value: 0,
    },
    {
      color: "#7FFF00",
      name: "Location",
      value: 0,
    },
    {
      color: "#D2691E",
      name: "Group",
      value: 0,
    },
    {
      color: "#FF7F50",
      name: "Item",
      value: 0,
    },
    {
      color: "#40C4FF",
      name: "Person",
      value: 0,
    },
    {
      color: "#00FFFF",
      name: "Quantity",
      value: 0,
    },
    {
      color: "#00008B",
      name: "Status",
      value: 0,
    },
    {
      color: "#E040FB",
      name: "Art",
      value: 0,
    },
  ];

  transactionsETDRealTime = [];
  isMissionModalOpen = false;
  isOperationModalOpen = false;
  clusterDomainId = "";
  projectIdForClustering = "";
  projectIdForSN = "";
  snDomainId = "";

  webCrawlPaginationPage = 1;
  webCrawlPaginationLastPage = false;

  characters = [];

  setCharachters = (value) => {
    if (value[0] === "e" && value[1] === "n" && value[2] === ":") {
      this.characters = value.slice(3);
    } else {
      this.characters = value;
    }

    console.log(this.characters);
  };

  setNextWebCrawlPaginationPage = () => {
    this.webCrawlPaginationPage++;
    if (this.webCrawlPaginationPage * 4 >= this.filteredItems.length) {
      this.webCrawlPaginationLastPage = true;
    }
  };
  setPreviousWebCrawlPaginationPage = () => {
    if (this.webCrawlPaginationPage > 1) {
      this.webCrawlPaginationPage--;
      if (this.webCrawlPaginationPage * 4 < this.filteredItems.length) {
        this.webCrawlPaginationLastPage = false;
      }
    }
  };

  setWebCrawlPaginationPageToReport = (item) => {
    this.reportPage = item;
    this.webCrawlView = "report";
  };
  setWebCrawlPaginationPageToList = () => {
    this.webCrawlPaginationPage = 1;
    this.webCrawlView = "list";
  };

  setWithVisualAnalysis = (value) => {
    this.withVisualAnalysis = value;
    this.filterPages();
    this.webCrawlPaginationPage = 1;
  };

  setWithTransaltionChecked = (value) => {
    this.withTransaltionChecked = value;
    this.filterPages();
    this.webCrawlPaginationPage = 1;
  };

  setSelectedClassification = (value) => {
    this.selectedClassification = value;
    this.filterPages();
    this.webCrawlPaginationPage = 1;
  };

  setSelectedCluster = (value) => {
    this.selectedCluster = value;
    this.filterPages();
    this.webCrawlPaginationPage = 1;
  };

  setFilteredPagesByCluster = (value) => {
    this.filteredPagesByCluster = value;
    this.filterPages();
    this.webCrawlPaginationPage = 1;
  };

  filterPages = () => {
    let filteredByTranslation = this.receivedItems;

    let filterBySelectedCluster = [];
    console.log(this.filteredPagesByCluster);
    if (this.filteredPagesByCluster.length > 0) {
      filterBySelectedCluster = filteredByTranslation.filter((item) => {
        return this.filteredPagesByCluster.includes(item.domainId);
      });
    } else {
      filterBySelectedCluster = filteredByTranslation;
    }
    console.log(filterBySelectedCluster);
    let comments = filterBySelectedCluster.filter((item) => {
      return item.type.includes("comment") && !item.attributes.commentedOn;
    });
    let replies = filterBySelectedCluster.filter((item) => {
      return item.attributes.commentedOn;
    });
    this.replies = replies;
    console.log(comments);
    // this.filteredItems = comments;
    let authors = filterBySelectedCluster.filter((item) => {
      return item.type.includes("author");
    });
    this.authors = authors;
    console.log(authors);
    let posts = filterBySelectedCluster.filter((item) => {
      return item.type.includes("post");
    });
    console.log(posts);
    this.filteredPosts = posts;
    let channel = filterBySelectedCluster.filter((item) => {
      return item.type.includes("channel");
    });
    console.log(channel);
    console.log(comments);
    this.filteredItems = comments;
    console.log(this.filteredItems);
    this.channel = channel;
  };
  filterComments = () => {
    this.webCrawlPaginationPage = 1;
    if (this.webCrawlPaginationPage * 4 >= this.filteredItems.length) {
      this.webCrawlPaginationLastPage = true;
    } else {
      this.webCrawlPaginationLastPage = false;
    }
  };

  setIsOperationModalOpen = (value) => {
    this.isOperationModalOpen = value;
  };

  setIsMissionModalOpen = (value) => {
    this.isMissionModalOpen = value;
  };

  setAlertsOn = (value) => {
    this.alertsOn = value;
  };

  setAnalyseEntities = (value) => {
    this.analyse_entities = value;
  };
  setAnalysisEntities = (value) => {
    this.analysis_entities = value;
  };

  setAnalyse = (value) => {
    this.analyse = value;
  };

  analysisPercent = 0;

  setAnalysisPercent = (value) => {
    this.analysisPercent = value;
  };

  setPieData = (value) => {
    this.setAnalysisPercent(0);
    this.pieData = [];
    let metaDataTemp = {};
    for (let j = 0; j < this.mapping.length; j++) {
      let value = this.analyse_entities.filter(
        (item) => item.type == this.mapping[j].type2
      ).length;
      metaDataTemp = {
        color: this.mapping[j].color,
        name: this.mapping[j].type1,
        value: value,
        // value: 2,
      };
      this.pieData.push(metaDataTemp);
      // this.setAnalysisPercent(((j + 1) / this.mapping.length) * 100);
    }
  };

  setPieDataSocial = (value) => {
    this.setAnalysisPercent(0);
    this.pieData = [];
    let metaDataTemp = {};
    for (let j = 0; j < this.mapping.length; j++) {
      let value = this.analyse_entities.filter(
        (item) => item.type == this.mapping[j].type2
      ).length;
      metaDataTemp = {
        color: this.mapping[j].color,
        name: this.mapping[j].type1,
        value: value,
        // value: 2,
      };
      this.pieData.push(metaDataTemp);
      // this.setAnalysisPercent(((j + 1) / this.mapping.length) * 100);
    }
  };

  setTemp = (value) => {
    this.temp = value;
  };

  setNotifications = (value) => {
    this.notifications = value;
  };

  setSessionId = (value) => {
    this.session_id = value;
  };

  setIsSearching = (value) => {
    this.isSearching = value;
  };

  setBattery = (value) => {
    this.battery = value;
  };

  setSelectedKey = (value) => {
    this.selectedKey = value;
  };

  // handle deleting objects
  isItemDeleting = false;

  setIsItemDeleting = (value) => {
    this.isItemDeleting = value;
  };

  addMinutes(date, minutes) {
    date.setMinutes(date.getMinutes() + minutes);

    return date;
  }

  resetSession = () => {
    this.date = new Date();
    this.session = this.addMinutes(this.date, 60);
  };

  // Api Calls SDMA

  // test SDMA
  TestSDMA = () => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects`)
      .then((data) => {
        this.errStore.setMessage("SDMA connected");
        this.errStore.setInfo(true);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // ETD

  GetETDRealTime = (pId, id) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/artefacts?domainId=${id}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.setTransactionsETDRealTime(JSON.parse(data.data[0].raw));
        this.setETDCoordsRealTime(JSON.parse(data.data[0].raw));
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // drones

  Session = (body) => {
    this.session_data.push(body);
  };

  // transactions
  CreateTransactions = (values) => {
    POST_SDMA(`/api/projects/86b8b6bd42ce110000000900/entities`, values)
      .then((data) => {
        this.errStore.setMessage("Transactions data collection started");
        this.errStore.setInfo(true);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA Error");

        console.log(error);
      });
  };

  // get project
  GetProject = () => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects`)
      .then((data) => {
        this.errStore.setMessage("");
        this.projects = data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // Artefacts
  GetArtefacts = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/artefacts?pageSize=${this.pageSize}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.artefacts = data.data;
        this.transaction = data.data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  getReply = (pId, reply) => {
    // this.errStore.setLoading(true);
    console.log(reply);
    // ` /api/projects/662ea9afd814970001a9a09d/artefacts?domainId=reddit:post:harmq9`
    return GET_SDMA(`/api/projects/${pId}/artefacts?domainId=${reply}`)
      .then((data) => {
        console.log(data);
        this.errStore.setMessage("");

        this.errStore.setLoading(false);
        return data.data;
      })
      .catch((error) => {
        // this.errStore.setError(true);
        // this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  getReplies = (subDomains) => {
    let repliesUrls = subDomains.map((url) => {
      return `/api/projects/${this.projectId}/artefacts?domainId=${url}`;
    });

    GET_SDMA_MANY(repliesUrls).then((data) => {
      console.log(data);

      let repliesObjects = data.map((object) => {
        return object.data.data[0];
      });
      this.repliesObjects = repliesObjects;
      console.log(this.repliesObjects);
    });
  };

  setVisualImages = (value) => {
    this.visual_images = value;
  };

  GetVisualImages = (id, media) => {
    console.log(id, media);
    this.errStore.setLoading(true);
    this.setVisualImages([]);
    // let newImages = [];
    Object.entries(media).map((item) => {
      GET_SDMA_IMAGE(`${constants.SDMA_API.slice(0, -1)}${item[1].raw.url}`)
        .then((data) => {
          var reader = new window.FileReader();
          reader.readAsDataURL(data);
          reader.onload = () => {
            this.errStore.setMessage("");
            this.errStore.setLoading(false);
            this.visual_images.push(reader.result);
          };
        })
        .catch((error) => {
          this.errStore.setError(true);
          this.errStore.setMessage("SDMA ERROR");
          console.log(error);
        });
    });
    // this.setVisualImages(newImages);
  };
  GetVisualImagesNew = (id, media) => {
    console.log(id, media);
    this.errStore.setLoading(true);
    this.setVisualImages([]);
    Object.entries(media).map((item) => {
      GET_SDMA_IMAGE(`${constants.SDMA_API.slice(0, -1)}${item[1].raw.url}`)
        .then((data) => {
          var reader = new window.FileReader();
          reader.readAsDataURL(data);
          reader.onload = () => {
            this.errStore.setMessage("");
            this.errStore.setLoading(false);
            this.visual_images.push(reader.result);
          };
        })
        .catch((error) => {
          this.errStore.setError(true);
          this.errStore.setMessage("SDMA ERROR");
          console.log(error);
        });
    });
  };

  GetVU = (pId, pageSize) => {
    this.errStore.setLoading(true);
    this.SetUV_data([]);
    GET_SDMA(
      `/api/projects/${pId}/artefacts?type=certh:vu:images&pageSize=${pageSize}`
    )
      .then((data) => {
        data.data.map((item) => {
          GET_SDMA(`/api/projects/${pId}/artefacts/${item.id}`)
            .then((data) => {
              this.errStore.setMessage("");
              this.errStore.setLoading(false);
              this.UV_data.push(data.data);
            })
            .catch((error) => {
              this.errStore.setError(true);
              this.errStore.setMessage("SDMA ERROR");
              console.log(error);
            });
        });
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  setReceivedItems = (pId, value) => {
    this.receivedItems = value;
    this.projectId = pId;
    this.filterPages();
  };
  setReceivedComments = (value) => {
    this.receivedItems = value;
    this.filterPages();
  };

  nonHME = [];
  HME = [];
  non = [];

  GetAllPagesFromWebCrawl = (pId, oId) => {
    console.log(oId);
    this.errStore.setLoading(true);
    this.setVisualImages([]);

    GET_SDMA(`/api/projects/${pId}/artefacts?tags=t:operationId:${oId}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        console.log(data.data);
        let newdata = data.data;
        for (let i = 0; i < newdata.length; i++) {
          if (
            this.classification_module.filter(
              (link) => link.sourceDomainId == newdata[i].domainId
            )[0]
          ) {
            var temp = this.classification_module
              .filter((link) => link.sourceDomainId == newdata[i].domainId)[0]
              .targetDomainId.split(":")[2];

            if (temp) {
              newdata[i].classification = temp;
            } else {
              newdata[i].classification = "Unclassified";
            }

            //     if (temp == "non-HME") {
            //       this.nonHME.push(item);
            //     } else if (temp == "HME") {
            //       this.HME.push(item);
            //     } else {
            //       this.non.push(item);
            //     }
          } else {
            newdata[i].classification = "Unclassified";
          }
        }
        console.log(newdata);

        if (
          newdata[0].domainId.includes("reddit") ||
          newdata[0].domainId.includes("youtube") ||
          newdata[0].domainId.includes("tiktok")
        ) {
          this.setReceivedComments(newdata);
          this.webCrawlTypeIsSocialMedial = true;
        } else {
          this.setReceivedItems(newdata);
          this.webCrawlTypeIsSocialMedial = false;
        }
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // Artefacts
  GetClusteringFromArtefacts = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(
      `/api/projects/${"641da605f611310001fbe67d"}/artefacts?type=ma:clustering&pageSize=${10}`
    )
      .then((data) => {
        this.errStore.setMessage("");
        this.clustering_module = data.data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  GetClusteringFromArtefactsNew = (x) => {
    this.errStore.setLoading(true);

    GET_SDMA(
      `/api/projects/${this.projectIdForClustering}/artefacts?type=ma:clustering&domainId=${x}`
    )
      .then((data) => {
        this.errStore.setMessage("");
        console.log(data.data);
        this.clustering_module = data.data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  //social network analysis
  GetSocialNetworkAnalysis = (pId) => {
    console.log("SNA7");
    this.errStore.setLoading(true);
    GET_SDMA(
      `api/projects/86b8b6bd42ce110000000900/artefacts?domainId=ma:sna:1712049342730 `
    )
      .then((data) => {
        this.errStore.setMessage("");
        this.social_network_analysis = data.data.filter(
          (item) => item.type == "ma:sna"
        );
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetSocialNetworkAnalysisNew = (pId) => {
    console.log("SNA8");
    this.errStore.setLoading(true);
    GET_SDMA(
      // `/api/projects/6423f44ccf6a810001866fc5/artefacts?pageSize=${this.pageSize}`
      // `api/projects/${projectIdForSN}/artefacts?domainId=${snDomainId}`

      //new social network
      `api/projects/86b8b6bd42ce110000000900/artefacts?domainId=ma:sna:1712049056454`
      //   `api/projects/86b8b6bd42ce110000000900/artefacts?domainId=ma:sna:1712049342730`
      // `api/projects/86b8b6bd42ce110000000900/artefacts?domainId=ma:sna:1707817667584`
    )
      .then((data) => {
        this.errStore.setMessage("");
        this.social_network_analysis = data.data.filter(
          (item) => item.type == "ma:sna"
        );
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetArtefactByID = (id, pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/artefacts/${id}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.artefact = data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  CreateArtefacts = (values, pId) => {
    POST_SDMA(`/api/projects/${pId}/artefacts`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.changeLogItem.push(data);
        this.errStore.handlerCussess("ChangeLogItem Created");
      })
      .catch(this.errStore.handlerErrors);
  };

  GetArtefactByDomainId = (pId, domaiId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/artefacts?domainId=${domaiId}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        console.log(data);
        this.GetVisualImages(data.data[0].id, data.data[0].media);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // Links
  GetLinksSourceDomainId = (pId, sourceDomainId) => {
    console.log(sourceDomainId);
    this.errStore.setLoading(true);
    GET_SDMA(
      `/api/projects/${pId}/links?sourceDomainId=${sourceDomainId}&type=hasVisualAnalysis`
      // "/api/projects/86b8b6bd42ce110000000900/links?sourceDomainId=web:page:20240319T123955Z:565ddc4c2b2a5d681991dc313f34bb70ebc1c00a&type=hasVisualAnalysis"
      // `/api/projects/${pId}/links?sourceDomainId=${sourceDomainId}&type=hasImage`

      // 86b8b6bd42ce110000000900
    )
      .then((data) => {
        console.log(data);
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        if (data.data) {
          this.GetArtefactByDomainId(pId, data.data[0].targetDomainId);
        }
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // Links
  GetLinks = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/links?pageSize=${this.pageSize}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.links = data;
        this.errStore.setLoading(false);
        this.classification_module = data.data.filter(
          (item) => item.type == "classifiedAs"
        );
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // Links
  GetclassificationModule = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/links?type=classifiedAs`)
      .then((data) => {
        this.errStore.setMessage("");
        this.links = data;
        this.errStore.setLoading(false);
        this.classification_module = data.data;
        console.log("classific");
        console.log(data.data);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetLinkByID = (id, pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/links/${id}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.link = data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  CreateLinks = (values, pId) => {
    POST_SDMA(`/api/projects/${pId}/links`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.links.push(data);
        this.errStore.handlerCussess("ChangeLogItem Created");
      })
      .catch(this.errStore.handlerErrors);
  };

  // Entities
  GetEntities = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/entities?pageSize=${this.pageSize}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.entities = data;
        this.errStore.setLoading(false);
        this.transaction = data.data.filter(
          (item) => item.type == "transactions"
        )[0];
        this.drones = data.data.filter((item) => item.type == "drone");
        this.field_operation = data.data.filter(
          (item) => item.type == "field_operation"
        )[0];
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // get drones
  GetDrones = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/entities?type=drone`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.drones = data.data;
      })
      .catch((error) => {
        console.log(error);
        this.errStore.setError(true);
        this.errStore.setMessage("Drones were not loaded");
      });
  };

  GetEntityByID = (id, pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/entities?tags=t:operationId:${id}`)
      // /entities/${id}
      .then((data) => {
        console.log(data);
        this.errStore.setMessage("");
        this.entity = data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  CreateEntities = (values, pId) => {
    POST_SDMA(`/api/projects/${pId}/entities`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.entities.push(data);
        this.errStore.handlerCussess("ChangeLogItem Created");
      })
      .catch(this.errStore.handlerErrors);
  };
  // audit Items

  GetAuditItems = () => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/audit?pageSize=${this.pageSize}`)
      .then((data) => {
        this.errStore.setMessage("");
        // this.audit_items = data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetAuditItemsById = (id) => {
    GET_SDMA(`/api/auditItems/${id}`)
      .then((data) => {
        this.audit_item = data;
      })
      .catch(this.errStore.handlerErrors);
  };

  // Change Log Items

  CreateChangeLogItem = (values) => {
    POST_SDMA(`/api/changeLogItem`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.changeLogItem.push(data);
        this.errStore.handlerCussess("ChangeLogItem Created");
      })
      .catch(this.errStore.handlerErrors);
  };

  // Access Log Items

  CreateAccessLogItem = (values) => {
    POST_SDMA(`/api/accessLogItem`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.accessLogItem.push(data);
        this.errStore.handlerCussess("AccessLogItem Created");
      })
      .catch(this.errStore.handlerErrors);
  };

  // Change Log Items

  PostKafka = (values) => {
    POST(`/test`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setInfo(true);
        this.errStore.handlerCussess("Message Published");
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("Something went wrong");
      });
  };

  // Publish to kafka

  Publish = (values) => {
    PUBLISH(`/kafka/publish`, values)
      .then((data) => {
        // this.errStore.setMessage(data);
        // this.errStore.setInfo(true);
      })
      .catch(this.errStore.handlerErrors);
  };

  isAnalysing = false;

  setIsAnalyzing = (value) => {
    this.isAnalysing = value;
  };

  isAnalysingFinished = false;

  setIsAnalyzingFinished = (value) => {
    this.isAnalysingFinished = value;
  };

  setAnalyseModal = (value) => {
    this.analyseModal = value;
  };

  textWords = [];

  setTextWords = (value) => {
    this.textWords = value;
  };

  GetAnalyse = async (pId, sourceDomainId) => {
    console.log(sourceDomainId);
    // this.setAnalyseModal(true);
    this.errStore.setLoading(true);
    this.setIsAnalyzing(true);
    this.setAnalyseEntities([]);
    let temp = [];
    this.analyzedCharacters = [];
    this.pieData[0].value = 0;
    this.pieData[1].value = 0;
    this.pieData[2].value = 0;
    this.pieData[3].value = 0;
    this.pieData[4].value = 0;
    this.pieData[5].value = 0;
    this.pieData[6].value = 0;
    this.pieData[7].value = 0;
    this.pieData[8].value = 0;
    this.pieData[9].value = 0;
    GET_SDMA(
      // `/api/projects/662a4a3ed814970001a99c3a/links?sourceDomainId=${type}&type=hasReference`
      `/api/projects/${pId}/links?sourceDomainId=${sourceDomainId}&type=hasReference`
    )
      .then((data) => {
        console.log("analyse-2");
        console.log(data);
        data.data.map((item) => {
          console.log("analyse-3");
          console.log(item);
          GET_SDMA(
            `/api/projects/${pId}/entities?domainId=${item.targetDomainId}`
          ).then((dataNew) => {
            console.log("analyse-4");
            console.log(dataNew);
            console.log(dataNew.data[0].domainId);
            let range = [];
            if (
              dataNew.data.length > 0 &&
              dataNew.data[0].attributes &&
              dataNew.data[0].attributes.beginsAt &&
              dataNew.data[0].attributes.beginsAt[0]
            ) {
              range = [
                Number(dataNew.data[0].attributes.beginsAt[0]),
                Number(dataNew.data[0].attributes.endsAt[0]),
              ];
              GET_SDMA(
                `/api/projects/${pId}/links?sourceDomainId=${dataNew.data[0].domainId}&type=referencesEntity`
              ).then((link) => {
                console.log("analyse-5");
                console.log(link);
                console.log("link.data");
                let category = "";
                let categoryWiki = "";

                if (link.data.length > 0) {
                  let foundNer = link.data.find((entity) => {
                    return entity.targetDomainId.includes("ner:");
                  });
                  if (foundNer) {
                    category = foundNer.targetDomainId.split(":")[1];
                  }
                }
                if (link.data.length > 0) {
                  let foundWiki = link.data.find((entity) => {
                    return entity.targetDomainId.includes("ed:wiki");
                  });
                  if (foundWiki) {
                    categoryWiki = foundWiki.targetDomainId.split(":")[2];
                    GET_SDMA(
                      `/api/projects/${pId}/entities?domainId=${foundWiki.targetDomainId}`
                    ).then((response) => {
                      console.log("analyse-6");
                      console.log(response);
                      let wikiLink = response.data[0].attributes.wikiLink[0];

                      if (category === "substance") {
                        this.pieData[0].value++;
                      }
                      if (category === "date") {
                        this.pieData[1].value++;
                      }
                      if (category === "glassware") {
                        this.pieData[2].value++;
                      }
                      if (category === "location") {
                        this.pieData[3].value++;
                      }

                      if (category === "group") {
                        this.pieData[4].value++;
                      }
                      if (category === "item") {
                        this.pieData[5].value++;
                      }
                      if (category === "person") {
                        this.pieData[6].value++;
                      }
                      if (category === "quantity") {
                        this.pieData[7].value++;
                      }
                      if (category === "status") {
                        this.pieData[8].value++;
                      }
                      if (category === "art") {
                        this.pieData[9].value++;
                      }

                      let dataN = {
                        range: range,
                        category: category,
                        wikiLink: wikiLink,
                      };

                      this.analyzedCharacters.push(dataN);
                    });
                  } else {
                    if (category === "substance") {
                      this.pieData[0].value++;
                    }
                    if (category === "date") {
                      this.pieData[1].value++;
                    }
                    if (category === "glassware") {
                      this.pieData[2].value++;
                    }
                    if (category === "location") {
                      this.pieData[3].value++;
                    }

                    if (category === "group") {
                      this.pieData[4].value++;
                    }
                    if (category === "item") {
                      this.pieData[5].value++;
                    }
                    if (category === "person") {
                      this.pieData[6].value++;
                    }
                    if (category === "quantity") {
                      this.pieData[7].value++;
                    }
                    if (category === "status") {
                      this.pieData[8].value++;
                    }
                    if (category === "art") {
                      this.pieData[9].value++;
                    }

                    let dataN = {
                      range: range,
                      category: category,
                      wikiLink: "",
                    };
                    this.analyzedCharacters.push(dataN);
                  }
                }

                // if (link.data.length > 0 && link.data[0].targetDomainId) {
                //   category = link.data[0].targetDomainId.split(":")[1];
                // }

                // this.analyzedCharacters.push({
                //   range: range,
                //   category: category,
                // });
                // if (category === "substance") {
                //   this.pieData[0].value++;
                // }
                // if (category === "date") {
                //   this.pieData[1].value++;
                // }
                // if (category === "glassware") {
                //   this.pieData[2].value++;
                // }
                // if (category === "location") {
                //   this.pieData[3].value++;
                // }

                // if (category === "group") {
                //   this.pieData[4].value++;
                // }
                // if (category === "item") {
                //   this.pieData[5].value++;
                // }
                // if (category === "person") {
                //   this.pieData[6].value++;
                // }
                // if (category === "quantity") {
                //   this.pieData[7].value++;
                // }
                // if (category === "status") {
                //   this.pieData[8].value++;
                // }
                // if (category === "art") {
                //   this.pieData[9].value++;
                // }
                // console.log(this.analyzedCharacters);

                // if (link.data.length > 0) {
                //   GET_SDMA(
                //     `/api/projects/662a4a3ed814970001a99c3a/entities?domainId=${link.data[0].targetDomainId}`
                //   ).then((entity) => {
                //     console.log(entity);
                //   });
                // }
              });
            }
          });
        });
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
    this.setIsAnalyzing(false);
    this.setIsAnalyzingFinished(true);
  };

  GetAnalyseOld = async (pId, type) => {
    console.log(type);
    // this.setAnalyseModal(true);
    this.errStore.setLoading(true);
    this.setIsAnalyzing(true);
    this.setAnalyseEntities([]);
    let temp = [];
    GET_SDMA(`/api/projects/${pId}/links?sourceDomainId=${type}`)
      .then((data) => {
        // this.errStore.setMessage("");
        // this.errStore.setLoading(false);
        data.data.map((item) => {
          if (
            this.mapping.filter((data) => data.type1 == item.type).length > 0
          ) {
            let request_type = this.mapping.filter(
              (data) => data.type1 == item.type
            )[0].type2;
            GET_SDMA(
              `/api/projects/${pId}/entities?type=${request_type}&domainId=${item.targetDomainId}`
            )
              .then((data) => {
                // this.errStore.setMessage("");
                // this.errStore.setLoading(false);
                if (data.data != []) {
                  let word = {
                    text: data.data[0].title,
                    type: data.data[0].type,
                    color: this.mapping.filter(
                      (item) => item.type2 == data.data[0].type
                    )[0].color,
                  };
                  temp.push(word);
                  // this.analyse_entities.push(word);
                  this.setAnalyseEntities(temp);
                }
              })
              .catch((error) => {
                // this.errStore.setError(true);
                // this.errStore.setMessage("SDMA ERROR");
                console.log(error);
              });
          }
        });
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
    this.setIsAnalyzing(false);
    this.setIsAnalyzingFinished(true);
  };
  GetAnalysis = async (domainId) => {
    console.log(domainId);
    // this.setAnalyseModal(true);
    this.errStore.setLoading(true);
    this.setIsAnalyzing(true);
    this.setAnalysisEntities([]);
    let temp = [];
    // GET_SDMA(
    //   `/api/projects/66179504d814970001a990cc/links?sourceDomainId=web:page:post025:11`
    // )
    // /api/projects/66179504d814970001a990cc/links?sourceDomainId=web:page:post025:11
    GET_SDMA(`/api/projects/${this.projectId}/links?sourceDomainId=${domainId}`)
      .then((data) => {
        console.log(data);
        // this.errStore.setMessage("");
        // this.errStore.setLoading(false);
        data.data.map((item) => {
          if (
            this.mapping.filter((data) => data.type1 == item.type).length > 0
          ) {
            let request_type = this.mapping.filter(
              (data) => data.type1 == item.type
            )[0].type2;
            // GET_SDMA(
            //   `/api/projects/${this.projectId}/entities?type=${request_type}&domainId=${item.targetDomainId}`
            // )
            GET_SDMA(
              `/api/projects/${this.projectId}/entities?type=${request_type}&domainId=${item.targetDomainId}`
            )
              .then((data) => {
                console.log(data);
                // this.errStore.setMessage("");
                // this.errStore.setLoading(false);
                if (data.data != []) {
                  let word = {
                    text: data.data[0].title,
                    type: data.data[0].type,
                    color: this.mapping.filter(
                      (item) => item.type2 == data.data[0].type
                    )[0].color,
                  };
                  temp.push(word);
                  // this.analyse_entities.push(word);
                  this.setAnalyseEntities(temp);
                }
              })
              .catch((error) => {
                // this.errStore.setError(true);
                // this.errStore.setMessage("SDMA ERROR");
                console.log(error);
              });
          }
        });
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
    this.setIsAnalyzing(false);
    this.setIsAnalyzingFinished(true);
  };
  GetAuthor = async (pId, targetDomainId) => {
    console.log(targetDomainId);
    // this.setAnalyseModal(true);
    this.errStore.setLoading(true);
    this.setIsAnalyzing(true);
    this.setAnalysisEntities([]);
    let temp = [];

    GET_SDMA(`/api/projects/${pId}/links?targetDomainId=${targetDomainId}`)
      .then((response) => {
        console.log(response.data);
        //artefacts sdsfdfsf

        let foundAuthorDomainObject = response.data.find((object) => {
          return object.type === "authorOf";
        });
        console.log(foundAuthorDomainObject);
        //         sourceDomainId
        // :
        // "youtube:author:UCwMu79zPSY2rGWol0lYXYBA"
        GET_SDMA(
          `/api/projects/${pId}/artefacts?domainId=${foundAuthorDomainObject.sourceDomainId}`
        ).then((data) => {
          console.log(data);
        });

        // data.data.map((item) => {
        //   if (
        //     this.mapping.filter((data) => data.type1 == item.type).length > 0
        //   ) {
        //     let request_type = this.mapping.filter(
        //       (data) => data.type1 == item.type
        //     )[0].type2;
        //     GET_SDMA(
        //       `/api/projects/${pId}/entities?type=${request_type}&domainId=${item.targetDomainId}`
        //     )
        //       .then((data) => {
        //         if (data.data != []) {
        //           let word = {
        //             text: data.data[0].title,
        //             type: data.data[0].type,
        //             color: this.mapping.filter(
        //               (item) => item.type2 == data.data[0].type
        //             )[0].color,
        //           };
        //           temp.push(word);
        //           this.setAnalyseEntities(temp);
        //         }
        //       })
        //       .catch((error) => {
        //         console.log(error);
        //       });
        //   }
        // });
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
    this.setIsAnalyzing(false);
    this.setIsAnalyzingFinished(true);
  };
  GetConfer = async (pId, type) => {
    this.showGroups = false;
    this.blueGroup = [];
    this.orangeGroup = [];
    this.redGroup = [];
    this.purpleGroup = [];
    this.limeGroup = [];
    this.greenGroup = [];
    this.cyanGroup = [];
    this.yellowGroup = [];
    console.log(type);
    // this.setAnalyseModal(true);
    this.errStore.setLoading(true);
    this.setIsAnalyzing(true);
    // this.setAnalyseEntities([]);
    let temp = [];
    function removeDuplicateArrays(arr) {
      let uniqueArrays = [];
      let seen = new Set();

      for (let subArray of arr) {
        let stringified = JSON.stringify(subArray);
        if (!seen.has(stringified)) {
          uniqueArrays.push(subArray);
          seen.add(stringified);
        }
      }

      return uniqueArrays;
    }

    GET_SDMA(
      `/api/projects/${pId}/links?sourceDomainId=${type}&type=hasReference`
    ).then((data) => {
      console.log(data.data);
    });

    GET_SDMA(
      `/api/projects/${pId}/links?sourceDomainId=${type}&type=containsEntity`
    )
      // GET_SDMA(
      //   `/api/projects/662ea9afd814970001a9a09d/links?sourceDomainId=youtube:post:INf4hLJyDoI`
      // )

      .then((data) => {
        console.log(data.data);

        let rangesU = [];
        data.data.map((item) => {
          //   if (
          //     this.mapping.filter((data) => data.type1 == item.type).length > 0
          //   ) {
          //     let request_type = this.mapping.filter(
          //       (data) => data.type1 == item.type
          //     )[0].type2;
          GET_SDMA(
            `/api/projects/${pId}/entities?type=ner:coref&domainId=${item.targetDomainId}`
          ).then((dataNew) => {
            console.log(dataNew);
            console.log(dataNew.data);
            console.log(data.data.length > 0);
            // console.log(data.data[0].name.includes("confer"));

            if (
              dataNew.data &&
              dataNew.data.length === 1 &&
              dataNew.data[0].name
              // &&
              // dataNew.data[0].name.includes("confer")
            ) {
              console.log("ioioio");
              console.log(dataNew.data[0].attributes);
              console.log(dataNew.data[0].attributes);
              let beginArray = dataNew.data[0].attributes.beginsAt.map(
                (item) => {
                  return Number(item);
                }
              );
              beginArray.sort((a, b) => a - b);

              let endArray = dataNew.data[0].attributes.endsAt.map((item) => {
                return Number(item);
              });
              endArray.sort((a, b) => a - b);
              let newArray = [];
              for (let y = 0; y < beginArray.length; y++) {
                newArray.push([beginArray[y], endArray[y]]);
              }
              rangesU.push(newArray);
              // this.blueGroup = newArray;
              console.log(rangesU);

              let newRanges = [];
              let aaa = [...rangesU];
              console.log(aaa);
              newRanges = removeDuplicateArrays(aaa);

              if (newRanges.length > 0) {
                this.showGroups = true;
                if (newRanges[0]) {
                  this.blueGroup = newRanges[0];
                  console.log(this.blueGroup);
                }
                if (newRanges[1]) {
                  this.orangeGroup = newRanges[1];
                  console.log(this.orangeGroup);
                }
                if (newRanges[2]) {
                  this.redGroup = newRanges[2];
                  console.log(this.redGroup);
                }
                if (newRanges[3]) {
                  this.purpleGroup = newRanges[3];
                  console.log(this.purpleGroup);
                }
                if (newRanges[4]) {
                  this.limeGroup = newRanges[4];
                }
                if (newRanges[5]) {
                  this.greenGroup = newRanges[5];
                }
                if (newRanges[6]) {
                  this.cyanGroup = newRanges[6];
                }
                if (newRanges[7]) {
                  this.yellowGroup = newRanges[7];
                }
              }
              console.log(newRanges);
            } else {
              this.showGroups = true;
            }
          });
        });
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
    this.setIsAnalyzing(false);
    this.setIsAnalyzingFinished(true);
  };

  GetAnalyseEntities = (type) => {
    this.errStore.setLoading(true);
    GET_SDMA(
      `/api/projects/${"86b8b6bd42ce110000000900"}/entities?sourceDomainId=${type}`
    )
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.setAnalyseEntities(data.data);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
      });
  };

  // missions
}

export default WebCrawlStore;
