import { makeAutoObservable, runInAction } from "mobx";
import {
  POST,
  PUBLISH,
  GET_SDMA,
  POST_SDMA,
  DELETE_SDMA,
  PUT_SDMA,
  GET_SDMA_IMAGE,
  POST_Test,
  POST_SDMA_MANY,
  PUBLISH_MANY,
} from "../apis/apis";
import { constants } from "../consts/Variables";
import { v4 as uuidv4 } from "uuid";
import { saveAs } from "file-saver";
import { mdiCommentArrowLeftOutline } from "@mdi/js";

function flattenArrays(obj) {
  // Get all the values (which are arrays of arrays) from the object
  const arrays = Object.values(obj);

  // Use flat() to flatten the array of arrays into a single array
  const flattenedArray = arrays.flat();

  return flattenedArray;
}
const currentDate = new Date();
const formattedDate = currentDate.toISOString().slice(0, 19) + "+0000";
class MainStore {
  constructor({ errStore }) {
    makeAutoObservable(this);
    this.errStore = errStore;
  }

  Recipes = [];
  Ingredients = [];
  recipesForETDReal = [];
  TransactionsProjectId = "";
  isRecipeModalOpen = false;
  isIngredientModalOpen = false;

  analyzedCharacters = [];
  storedMissions = null;
  alertsOn = false;
  selectedKey = "1";
  dateFormat = "YYYY/MM/DD";
  status = "success";
  audit_items = [];
  audit_item = null;
  transaction = null;
  transactions = [];
  webdata = [];
  field_operation = null;
  changeLogItem = [];
  artefacts = [];
  projects = [];
  artefact = null;
  entities = [];
  entity = null;
  links = [];
  link = null;
  accessLogItem = [];
  currentKey = window.location.pathname;

  isKafkaConnected = "failed";
  transcation_coords = ["23.123456", "23.123456"];

  pageSize = 100000000;
  searchedData = [];
  isSearching = false;
  battery = "";
  notifications = [];

  temp = { body: null, images: [] };
  wp_connection = false;
  analyse = false;
  analyse_entities = [];

  transactionsETD = [];
  isETDSearching = false;
  ETDCoords = null;
  transactionsETDRealTime = {
    ASFOBBB: {
      1: {
        name: "fd",
      },
      2: {
        name: "eg",
      },
    },
    ANFO: {
      3: {
        name: "io",
      },
      4: {
        name: "bb",
      },
    },
  };

  ETDCoordsRealTime = null;
  isMissionModalOpen = false;
  isOperationModalOpen = false;
  clusterDomainId = "";
  projectIdForClustering = "";
  projectIdForSN = "";
  snDomainId = "";

  ETD_HeatMap_realtime = {};
  AID_HeatMap_realtime = [];
  SelectedRecipeETD_HeatMap_realtime = [];
  SelectedRecipe = "";
  SelectedRecipeForETDReal = "All";

  openModalRecipes = false;

  addETD_HeatMap_realtime = (newData) => {
    {
      runInAction(() => {
        // Loop over the new data to update etdData
        Object.keys(newData).forEach((key) => {
          if (this.ETD_HeatMap_realtime[key]) {
            // If the key exists, append (concatenate) the new values
            this.ETD_HeatMap_realtime[key] = [
              ...this.ETD_HeatMap_realtime[key],
              ...newData[key],
            ];
          } else {
            // If the key doesn't exist, add it
            this.ETD_HeatMap_realtime[key] = [...newData[key]];
          }
        });

        // Remove keys from etdData that are not in the new data
        Object.keys(this.ETD_HeatMap_realtime).forEach((key) => {
          if (!newData[key]) {
            delete this.ETD_HeatMap_realtime[key];
          }
        });
      });
      // }

      // runInAction(() => {
      //   // Loop over the new data to update etdData
      //   Object.keys(newData).forEach((key) => {
      //     if (this.ETD_HeatMap_realtime[key]) {
      //       // If the key exists, push the new values
      //       this.ETD_HeatMap_realtime[key] = [...newData[key]];
      //     } else {
      //       // If the key doesn't exist, add it
      //       this.ETD_HeatMap_realtime[key] = [...newData[key]];
      //     }
      //   });

      //   // Remove keys from etdData that are not in the new data
      //   Object.keys(this.ETD_HeatMap_realtime).forEach((key) => {
      //     if (!newData[key]) {
      //       delete this.ETD_HeatMap_realtime[key];
      //     }
      //   });
      // });
    }
    this.createSetSelectedRecipeETDHeatMapData();
    // }
    // this.ETD_HeatMap_realtime.push(...newCoordinates);
  };
  addAID_HeatMap_realtime = (newCoordinates) => {
    console.log(newCoordinates);
    this.AID_HeatMap_realtime = newCoordinates.heatmap_coordinates;
  };

  CreateRecipe = (values) => {
    // this.errStore.setLoading(true);
    POST_SDMA(`/api/projects`, values)
      .then((data) => {
        console.log(data);
        this.GetRecipes();
        // this.setIsMissionModalOpen(false);
        // this.errStore.setInfo(true);
        // this.errStore.setMessage("Mission Created!");
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  setIsRecipeModalOpen = (value) => {
    this.isRecipeModalOpen = value;
  };
  setIsIngredientModalOpen = (value) => {
    this.isIngredientModalOpen = value;
  };

  setIsMissionModalOpen = (value) => {
    this.isMissionModalOpen = value;
  };
  startReal = () => {
    POST_Test("play");
  };
  stepReal = () => {
    POST_Test("step");
  };
  // https://etd-api.odysseus-online.rid-intrasoft.eu/start
  // https://etd-api.odysseus-online.rid-intrasoft.eu/stop
  // https://etd-api.odysseus-online.rid-intrasoft.eu/step
  // https://etd-api.odysseus-online.rid-intrasoft.eu/reset

  setETDCoords = (value) => {
    let temp = [];

    Object.entries(value).map((item) => {
      // console.log(item);
      if (item[1] != {}) {
        Object.entries(item[1]).map((item) => {
          console.log(item);
          if (item[1]) {
            temp.push({
              coords: { lat: item[1].centroid[0], lon: item[1].centroid[1] },
              properties: item[1].properties,
              id: item[0],
              recipe: item[1].properties.recipe,
              transactions: item[1].transactions,
            });
          }
        });
      }
    });

    this.ETDCoords = temp;
  };

  setETDCoordsRealTime = (value) => {
    let temp = [];

    Object.entries(value).map((item) => {
      // console.log(item);
      if (item[1] != {}) {
        Object.entries(item[1]).map((item) => {
          if (item[1]) {
            temp.push({
              coords: { lat: item[1].centroid[0], lon: item[1].centroid[1] },
              properties: item[1].properties,
              id: item[0],
              recipe: item[1].properties.recipe,
            });
          }
        });
      }
    });
    this.ETDCoordsRealTime = temp;
  };

  setIsETDSearching = (value) => {
    this.isETDSearching = value;
  };

  setTransactionsETD = (value) => {
    this.transactionsETD = value;
  };

  setTransactionsETDRealTime = (value) => {
    console.log(value);
    this.transactionsETDRealTime = value;
  };

  setStoredMissions = (value) => {
    this.storedMissions = value;
  };

  setAlertsOn = (value) => {
    this.alertsOn = value;
  };

  SetWSConnection = (value) => {
    this.wp_connection = value;
  };

  CreateMission = (values) => {
    this.errStore.setLoading(true);
    POST_SDMA(`/api/projects`, values)
      .then((data) => {
        console.log(data);
        this.TransactionsProjectId = data.data.id;
        // return data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetProjectIdOfTransactions = () => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects`)
      .then((data) => {
        this.errStore.setLoading(false);
        // this.setMissions(data.data);
        console.log(data);
        let projects = data.data;
        let foundMission = projects.find((project) => {
          return project.name === "RecipesANDIngredientsForTransactions6";
        });
        console.log(foundMission);

        if (!foundMission) {
          this.CreateMission({
            name: "RecipesANDIngredientsForTransactions6",
            description: "RecipesANDIngredientsForTransactions6",
            status: "Enabled",
          });
        } else {
          this.TransactionsProjectId = foundMission.id;
          console.log(this.TransactionsProjectId);
        }
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetRecipes = (pid) => {
    console.log("rerrr");
    this.errStore.setLoading(true);
    //  GET_SDMA(`/api/projects/${pid}/entities`)
    // GET_SDMA(`/api/projects/${pid}/entities?type=web_crawl`)
    GET_SDMA(
      `/api/projects/${this.TransactionsProjectId}/entities?type=recipe`
      //   `/api/projects/${pid}/entities?tags=t:operationId:dea6defb-d02a-4087-aed1-1791f8231258`
    )
      // /api/projects/86b8b6bd42ce110000000900/entities?tags=t:operationId:operation_id_test
      .then((data) => {
        this.errStore.setLoading(false);
        console.log(data.data);
        let arrayOfObjects = data.data;
        this.Recipes = arrayOfObjects.sort((a, b) => {
          // Compare the name properties
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        // for (let i = 0; i < this.Recipes.length; i++) {
        //   this.transactionsETDRealTime[
        //     this.Recipes[i].name.replace(/\s+/g, "_")
        //   ] = [];
        // }
        console.log(this.transactionsETDRealTime);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  GetRecipesForETDReal = (pid) => {
    GET_SDMA(`/api/projects/${this.TransactionsProjectId}/entities?type=recipe`)
      .then((data) => {
        this.errStore.setLoading(false);
        console.log(data.data);
        let arrayOfObjects = data.data.sort((a, b) => {
          // Compare the name properties
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        let recipesForETD = arrayOfObjects.map((recipe) => {
          return { text: recipe.name, value: recipe.name };
        });
        console.log(recipesForETD);
        recipesForETD.unshift({
          text: "All",
          value: "All",
        });

        this.recipesForETDReal = recipesForETD;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  setSelectedRecipeForETDReal = (recipe) => {
    this.SelectedRecipeForETDReal = recipe;
    console.log(recipe);
  };

  GetRecipesForHeatMap = (pid) => {
    console.log("rerrr");
    this.errStore.setLoading(true);
    //  GET_SDMA(`/api/projects/${pid}/entities`)
    // GET_SDMA(`/api/projects/${pid}/entities?type=web_crawl`)
    return (
      GET_SDMA(
        `/api/projects/${this.TransactionsProjectId}/entities?type=recipe`
        //   `/api/projects/${pid}/entities?tags=t:operationId:dea6defb-d02a-4087-aed1-1791f8231258`
      )
        // /api/projects/86b8b6bd42ce110000000900/entities?tags=t:operationId:operation_id_test
        .then((data) => {
          this.errStore.setLoading(false);
          console.log(data.data);
          let arrayOfObjects = data.data;
          this.Recipes = arrayOfObjects.sort((a, b) => {
            // Compare the name properties
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          // for (let i = 0; i < this.Recipes.length; i++) {
          //   this.transactionsETDRealTime[
          //     this.Recipes[i].name.replace(/\s+/g, "_")
          //   ] = [];
          // }
          return data;
          console.log(this.transactionsETDRealTime);
        })
        .catch((error) => {
          this.errStore.setError(true);
          this.errStore.setMessage("SDMA ERROR");
          console.log(error);
        })
    );
  };

  GetIngredients = (pid) => {
    this.errStore.setLoading(true);
    GET_SDMA(
      `/api/projects/${this.TransactionsProjectId}/entities?type=ingredient`
    )
      .then((data) => {
        this.errStore.setLoading(false);
        console.log(data.data);
        let arrayOfObjects = data.data;
        this.Ingredients = arrayOfObjects.sort((a, b) => {
          // Compare the name properties
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  setTemp = (value) => {
    this.temp = value;
  };

  setNotifications = (value) => {
    this.notifications = value;
  };

  setIsSearching = (value) => {
    this.isSearching = value;
  };

  setSearchedData = (value) => {
    let newData = value.transactions;

    this.searchedData = value.transactions;
  };

  downloadRecipesANDIngredients = () => {
    GET_SDMA(
      `/api/projects/${this.TransactionsProjectId}/entities?type=recipe&type=ingredient`
    )
      // /entities/${id}
      .then((data) => {
        console.log(data);

        // let newData = [];

        // console.log(newData);
        // data.data = newData;

        const json = JSON.stringify(data.data, null, 2); // Convert data to JSON string
        const blob = new Blob([json], { type: "application/json" }); // Create a Blob with JSON MIME type
        saveAs(blob, "downloadData.json");
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  uploadRecipesANDIngredients = (importData) => {
    GET_SDMA(
      `/api/projects/${this.TransactionsProjectId}/entities?type=ingredient&&type=recipe`
    ).then((data) => {
      console.log(data.data);
      let arrayOfExistingIngredients = data.data.map((ingredient) => {
        return ingredient.name;
      });
      let arrayOfExistingRecipes = data.data.map((recipe) => {
        return recipe.name;
      });
      console.log(arrayOfExistingIngredients);
      console.log(arrayOfExistingRecipes);
      let ingredients = importData.filter((entity) => {
        return (
          entity.type === "ingredient" &&
          !arrayOfExistingIngredients.includes(entity.name)
        );
      });
      let recipes = importData.filter((entity) => {
        return (
          entity.type === "recipe" &&
          !arrayOfExistingRecipes.includes(entity.name)
        );
      });
      console.log(ingredients);
      console.log(recipes);
      let newIngredients = ingredients.map((ingredient) => {
        return {
          url: `/api/projects/${this.TransactionsProjectId}/entities`,
          value: {
            attributes: {
              products: [ingredient.attributes.products[0]],
              threshold: [ingredient.threshold],
            },
            name: ingredient.name,
            title: ingredient.title,
            type: ingredient.type,
            threshold: ingredient.threshold,
          },
        };
      });

      let newRecipes = recipes.map((recipe) => {
        console.log(recipe.attributes.ingredients);
        console.log(JSON.parse(recipe.attributes.ingredients));
        return {
          url: `/api/projects/${this.TransactionsProjectId}/entities`,
          value: {
            attributes: {
              alias: [recipe.attributes.alias[0]],
              ingredients: [
                JSON.stringify(JSON.parse(recipe.attributes.ingredients)),
              ],
              // [
              //   JSON.stringify([
              //     { name: "Absolute Ethanol", amount: 67, threshold: 44 },
              //   ]),
              // ]
            },
            name: recipe.name,
            title: recipe.title,
            type: recipe.type,
            domaiId: recipe.domaiId,
          },
        };
      });

      return POST_SDMA_MANY(newIngredients)
        .then((data) => {
          console.log(data);
          // this.setIsOperationModalOpen(false);
          this.errStore.setInfo(true);

          return POST_SDMA_MANY(newRecipes)
            .then((data) => {
              console.log(data);
              // this.setIsOperationModalOpen(false);
              if (data.length > 0) {
                let messages = [];
                for (let i = 0; i < data.length; i++) {
                  let kafka_message = {
                    header: {
                      topicName: "RECIPE_UPDATE",
                      sender: "INTRA",
                      sentUtc: formattedDate,
                      source: "Dashboard",
                      caseId: this.TransactionsProjectId,
                    },
                    body: {
                      deleted: false,
                      domainId: data[i].data.data.domainId,
                    },
                  };

                  let obj = {
                    topics: ["RECIPE_UPDATE"],
                    body: JSON.stringify(kafka_message),
                  };

                  messages.push(obj);
                }
                this.Publish_many(messages);
              }

              // this.errStore.setInfo(true);
              // this.errStore.setMessage("Operation added to mission!");
              // let kafka_message = {
              //   header: {
              //     topicName: "RECIPE_UPDATE",
              //     sender: "INTRA",
              //     sentUtc: formattedDate,
              //     source: "Dashboard",
              //     caseId: this.TransactionsProjectId,
              //   },
              //   body: {
              //     deleted: false,
              //     domainId: data.data.domainId,
              //   },
              // };

              // this.Publish({
              //   topics: ["RECIPE_UPDATE"],
              //   body: JSON.stringify(kafka_message),
              // });

              this.GetRecipes();
              // this.isRecipeModalOpen(false);
              // this.GetMissionOperations(this.pid);
            })
            .catch((error) => {
              this.errStore.setError(true);
              this.errStore.setMessage("SDMA ERROR");
              console.log(error);
            });

          return POST_SDMA_MANY(newRecipes)
            .then((data) => {
              console.log(data);
              // this.setIsOperationModalOpen(false);
              this.errStore.setInfo(true);
            })
            .catch((error) => {
              this.errStore.setError(true);
              this.errStore.setMessage("SDMA ERROR");
              console.log(error);
            });
        })
        .catch((error) => {
          this.errStore.setError(true);
          this.errStore.setMessage("SDMA ERROR");
          console.log(error);
        });
    });

    // console.log(newIngredients);
  };

  setOpenModalRecipes = (value) => {
    this.openModalRecipes = value;
  };

  importRecipes = () => {};

  setBattery = (value) => {
    this.battery = value;
  };

  setSelectedKey = (value) => {
    this.selectedKey = value;
  };

  setSelectedRecipe = (value) => {
    if (value) {
      this.SelectedRecipe = value;
      console.log(value);
      console.log(value.name);
      if (value.name !== "ALL") {
        console.log(this.ETD_HeatMap_realtime[value.name]);
        if (this.ETD_HeatMap_realtime[value.name]) {
          this.SelectedRecipeETD_HeatMap_realtime =
            this.ETD_HeatMap_realtime[value.name];
        }

        console.log(this.SelectedRecipeETD_HeatMap_realtime);
        // ETD_HeatMap_realtime;
      }
      if ((value.name !== "ALL") & !value.name) {
        if (this.ETD_HeatMap_realtime !== {}) {
          this.SelectedRecipeETD_HeatMap_realtime = flattenArrays(
            this.ETD_HeatMap_realtime
          );
        }
      }
    }
  };
  createSetSelectedRecipeETDHeatMapData = () => {
    console.log("this.SelectedRecipeETD_HeatMap_realtime");
    console.log(this.SelectedRecipeForETDReal);
    console.log(this.ETD_HeatMap_realtime);
    if (this.SelectedRecipeForETDReal !== "All") {
      console.log(this.ETD_HeatMap_realtime[this.SelectedRecipeForETDReal]);
      if (this.ETD_HeatMap_realtime[this.SelectedRecipeForETDReal]) {
        this.SelectedRecipeETD_HeatMap_realtime =
          this.ETD_HeatMap_realtime[this.SelectedRecipeForETDReal];
      }
    }
    if (
      this.SelectedRecipeForETDReal === "All" ||
      !this.SelectedRecipeForETDReal
    ) {
      // if (this.ETD_HeatMap_realtime !== {}) {
      this.SelectedRecipeETD_HeatMap_realtime = flattenArrays(
        this.ETD_HeatMap_realtime
      );
      // }
    }
    console.log(this.SelectedRecipeETD_HeatMap_realtime);
  };

  // handle deleting objects
  isItemDeleting = false;

  setIsItemDeleting = (value) => {
    this.isItemDeleting = value;
  };

  TestSDMA = () => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects`)
      .then((data) => {
        this.errStore.setMessage("SDMA connected");
        this.errStore.setInfo(true);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // ETD

  GetETDRealTime = (pId, id) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/artefacts?domainId=${id}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.setTransactionsETDRealTime(JSON.parse(data.data[0].raw));
        this.setETDCoordsRealTime(JSON.parse(data.data[0].raw));
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  session_data = [];

  Session = (body) => {
    this.session_data.push(body);
  };

  // transactions
  CreateTransactions = (values) => {
    POST_SDMA(`/api/projects/86b8b6bd42ce110000000900/entities`, values)
      .then((data) => {
        this.errStore.setMessage("Transactions data collection started");
        this.errStore.setInfo(true);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA Error");

        console.log(error);
      });
  };

  StartTransactions = (values) => {
    PUT_SDMA(
      `/api/projects/86b8b6bd42ce110000000900/entities/641ad1d10911fcb4f7c613f4`,
      values
    )
      .then((data) => {
        this.errStore.setMessage("Transactions data collection started");
        this.errStore.setInfo(true);
        this.transaction = data.data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA Error");
        console.log(error);
      });
  };

  StopTransactions = (values) => {
    PUT_SDMA(
      `/api/projects/86b8b6bd42ce110000000900/entities/641ad1d10911fcb4f7c613f4`,
      values
    )
      .then((data) => {
        this.errStore.setMessage("Transactions data collection stopped");
        this.errStore.setInfo(true);
        this.transaction = data.data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA Error");
        console.log(error);
      });
  };

  // web data collection

  CreateWebDataCollection = (values) => {
    POST_SDMA(`/api/projects/86b8b6bd42ce110000000900/entities`, values)
      .then((data) => {
        this.errStore.setMessage("Web data collection started");
        this.errStore.setInfo(true);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA Error");
        console.log(error);
      });
  };

  GetWebDataCollection = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(
      `/api/projects/86b8b6bd42ce110000000900/endpoints?pageSize=${this.pageSize}`
    )
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.webdata = data.data.filter((item) => item.type == "Web");
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  StartWebDataCollection = (values) => {
    POST_SDMA(`/api/projects/${values.projectId}/endpoints`, values)
      //   POST_SDMA(`/api/projects/86b8b6bd42ce110000000900/endpoints`, values)
      //   POST_SDMA(`/api/projects/${values.projectId}/endpoints`, values)
      .then((data) => {
        this.errStore.setMessage("Web data collection started");
        this.errStore.setInfo(true);
        this.webdata.push(data.data);
        this.webdata.reverse();
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA Error");
        console.log(error);
      });
  };

  StopWebDataCollection = (id) => {
    DELETE_SDMA(`/api/projects/86b8b6bd42ce110000000900/endpoints/${id}`)
      .then((data) => {
        this.errStore.setMessage("Web data collection stopped");
        this.errStore.setInfo(true);
        for (var i = 0; i < this.webdata.length; i++) {
          if (this.webdata[i].id == id) {
            this.webdata.splice(i, 1);
            break;
          }
        }
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA Error");
        console.log(error);
      });
  };

  StartOperation = (values) => {
    PUT_SDMA(
      `/api/projects/86b8b6bd42ce110000000900/entities/641aff330911fcb4f7c69362`,
      values
    )
      .then((data) => {
        this.errStore.setMessage("Operation started");
        this.errStore.setInfo(true);
        this.field_operation = data.data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA Error");
        console.log(error);
      });
  };

  StopOperation = (values) => {
    PUT_SDMA(
      `/api/projects/86b8b6bd42ce110000000900/entities/641aff330911fcb4f7c69362`,
      values
    )
      .then((data) => {
        this.errStore.setMessage("Operation stopped");
        this.errStore.setInfo(true);
        this.field_operation = data.data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA Error");
        console.log(error);
      });
  };

  // get project
  GetProject = () => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects`)
      .then((data) => {
        this.errStore.setMessage("");
        this.projects = data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // Artefacts
  GetArtefacts = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/artefacts?pageSize=${this.pageSize}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.artefacts = data.data;
        this.transaction = data.data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  nonHME = [];
  HME = [];
  non = [];
  GetTransactions = (pId) => {
    this.errStore.setLoading(true);
    // GET_SDMA(`/api/projects/${pId}/artefacts?type=STD_2:Classification`)
    GET_SDMA(
      `/api/projects/86b8b6bd42ce110000000900/artefacts?type=STD_2:Classification`
    )
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.transactions = data.data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetArtefactByID = (id, pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/artefacts/${id}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.artefact = data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  CreateArtefacts = (values, pId) => {
    POST_SDMA(`/api/projects/${pId}/artefacts`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.changeLogItem.push(data);
        this.errStore.handlerCussess("ChangeLogItem Created");
      })
      .catch(this.errStore.handlerErrors);
  };

  GetArtefactByDomainId = (pId, domaiId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/artefacts?domainId=${domaiId}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        console.log(data);
        this.GetVisualImages(data.data[0].id, data.data[0].media);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // Links

  GetLinkByID = (id, pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/links/${id}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.link = data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  CreateLinks = (values, pId) => {
    POST_SDMA(`/api/projects/${pId}/links`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.links.push(data);
        this.errStore.handlerCussess("ChangeLogItem Created");
      })
      .catch(this.errStore.handlerErrors);
  };

  // Entities
  GetEntities = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/entities?pageSize=${this.pageSize}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.entities = data;
        this.errStore.setLoading(false);
        this.transaction = data.data.filter(
          (item) => item.type == "transactions"
        )[0];
        this.drones = data.data.filter((item) => item.type == "drone");
        this.field_operation = data.data.filter(
          (item) => item.type == "field_operation"
        )[0];
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetEntityByID = (id, pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/entities?tags=t:operationId:${id}`)
      // /entities/${id}
      .then((data) => {
        console.log(data);
        this.errStore.setMessage("");
        this.entity = data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  CreateEntities = (values, pId) => {
    POST_SDMA(`/api/projects/${pId}/entities`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.entities.push(data);
        this.errStore.handlerCussess("ChangeLogItem Created");
      })
      .catch(this.errStore.handlerErrors);
  };
  // audit Items

  GetAuditItems = () => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/audit?pageSize=${this.pageSize}`)
      .then((data) => {
        this.errStore.setMessage("");
        // this.audit_items = data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetAuditItemsById = (id) => {
    GET_SDMA(`/api/auditItems/${id}`)
      .then((data) => {
        this.audit_item = data;
      })
      .catch(this.errStore.handlerErrors);
  };

  // Change Log Items

  CreateChangeLogItem = (values) => {
    POST_SDMA(`/api/changeLogItem`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.changeLogItem.push(data);
        this.errStore.handlerCussess("ChangeLogItem Created");
      })
      .catch(this.errStore.handlerErrors);
  };

  // Access Log Items

  CreateAccessLogItem = (values) => {
    POST_SDMA(`/api/accessLogItem`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.accessLogItem.push(data);
        this.errStore.handlerCussess("AccessLogItem Created");
      })
      .catch(this.errStore.handlerErrors);
  };

  // Change Log Items

  PostKafka = (values) => {
    POST(`/test`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setInfo(true);
        this.errStore.handlerCussess("Message Published");
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("Something went wrong");
      });
  };

  // Publish to kafka

  Publish = (values) => {
    PUBLISH(`/kafka/publish`, values)
      .then((data) => {
        // this.errStore.setMessage(data);
        // this.errStore.setInfo(true);
      })
      .catch(this.errStore.handlerErrors);
  };

  Publish_many = (values) => {
    PUBLISH_MANY(`/kafka/publish`, values)
      .then((data) => {
        // this.errStore.setMessage(data);
        // this.errStore.setInfo(true);
      })
      .catch(this.errStore.handlerErrors);
  };
  setAnalyseModal = (value) => {
    this.analyseModal = value;
  };

  AddRecipeToProject = (values) => {
    this.errStore.setLoading(true);
    return POST_SDMA(
      `/api/projects/${this.TransactionsProjectId}/entities`,
      values
    )
      .then((data) => {
        console.log(data);
        // this.setIsOperationModalOpen(false);
        this.errStore.setInfo(true);
        this.errStore.setMessage("Recipe added !");
        let kafka_message = {
          header: {
            topicName: "RECIPE_UPDATE",
            sender: "INTRA",
            sentUtc: formattedDate,
            source: "Dashboard",
            caseId: this.TransactionsProjectId,
          },
          body: {
            deleted: false,
            domainId: data.data.domainId,
          },
        };

        this.Publish({
          topics: ["RECIPE_UPDATE"],
          body: JSON.stringify(kafka_message),
        });
        this.GetRecipes();
        // this.isRecipeModalOpen(false);
        // this.GetMissionOperations(this.pid);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  DeleteThisRecipeFromProject = (record) => {
    this.errStore.setLoading(true);
    return DELETE_SDMA(
      `/api/projects/${this.TransactionsProjectId}/entities/66e016966c70a0185412d8c7`
    )
      .then((data) => {
        console.log(data);
        // this.setIsOperationModalOpen(false);
        this.errStore.setInfo(true);
        // this.errStore.setMessage("Operation added to mission!");
        let kafka_message = {
          header: {
            topicName: "RECIPE_UPDATE",
            sender: "INTRA",
            sentUtc: formattedDate,
            source: "Dashboard",
            caseId: this.TransactionsProjectId,
          },
          body: {
            deleted: true,
            domainId: record.domainId,
          },
        };

        this.Publish({
          topics: ["RECIPE_UPDATE"],
          body: JSON.stringify(kafka_message),
        });
        this.GetRecipes();
        // this.isRecipeModalOpen(false);
        // this.GetMissionOperations(this.pid);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  DeleteRecipeFromProject = (record) => {
    this.errStore.setLoading(true);
    return DELETE_SDMA(
      `/api/projects/${this.TransactionsProjectId}/entities/${record.id}`
    )
      .then((data) => {
        console.log(data);
        // this.setIsOperationModalOpen(false);
        this.errStore.setInfo(true);
        // this.errStore.setMessage("Operation added to mission!");
        let kafka_message = {
          header: {
            topicName: "RECIPE_UPDATE",
            sender: "INTRA",
            sentUtc: formattedDate,
            source: "Dashboard",
            caseId: this.TransactionsProjectId,
          },
          body: {
            deleted: true,
            domainId: record.domainId,
          },
        };

        this.Publish({
          topics: ["RECIPE_UPDATE"],
          body: JSON.stringify(kafka_message),
        });
        this.GetRecipes();
        // this.isRecipeModalOpen(false);
        // this.GetMissionOperations(this.pid);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  AddIngredient = (values) => {
    this.errStore.setLoading(true);
    return POST_SDMA(
      `/api/projects/${this.TransactionsProjectId}/entities`,
      values
    )
      .then((data) => {
        console.log(data);
        // this.setIsOperationModalOpen(false);
        this.errStore.setInfo(true);
        // this.errStore.setMessage("Operation added to mission!");
        this.GetIngredients();
        // this.isIngredientModalOpen(false);
        // this.GetMissionOperations(this.pid);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  DeleteIngredient = (record) => {
    this.errStore.setLoading(true);
    return DELETE_SDMA(
      `/api/projects/${this.TransactionsProjectId}/entities/${record.id}`
    )
      .then((data) => {
        console.log(data);
        // this.setIsOperationModalOpen(false);
        this.errStore.setInfo(true);
        // this.errStore.setMessage("Operation added to mission!");
        // let kafka_message = {
        //   header: {
        //     topicName: "RECIPE_UPDATE",
        //     sender: "INTRA",
        //     sentUtc: formattedDate,
        //     source: "Dashboard",
        //     caseId: this.TransactionsProjectId,
        //   },
        //   body: {
        //     deleted: true,
        //     domainId: record.domainId,
        //   },
        // };

        // this.Publish({
        //   topics: ["RECIPE_UPDATE"],
        //   body: JSON.stringify(kafka_message),
        // });
        this.GetIngredients();
        // this.isRecipeModalOpen(false);
        // this.GetMissionOperations(this.pid);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetTest = (id, pId) => {
    GET_SDMA(
      `https://sdms.odysseus-online.rid-intrasoft.eu/api/projects/86b8b6bd42ce110000000900/entities?type=ner:chemical`
    )
      .then((data) => {})
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
      });
  };
}

export default MainStore;
