import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Table,
  Space,
  Modal,
  Collapse,
  Drawer,
  Divider,
  Tabs,
  Select,
} from "antd";
import { useMobxStores } from "../../stores/stores";
import TransactionsETDRealTimeList from "./TransactionsETDRealTimeList";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";
import ETDMapRealTimeNew from "./ETDMapRealTimeNew";
import MapHeatNew from "./MapHeatNew";
import { UnorderedListOutlined } from "@ant-design/icons";
import Icon from "@mdi/react";
import { mdiMapMarkerAlertOutline, mdiThermometer } from "@mdi/js";

import {} from "@mdi/js";

const { TabPane } = Tabs;
var _ = require("lodash");
const { Option } = Select;
const { Panel } = Collapse;

const TransactionsETDRealTime = (props) => {
  const [tabContent, setTabContent] = useState("1");
  const { mainStore, transactionsStore } = useMobxStores();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isItemsModalOpen, setItemsIsModalOpen] = useState(false);
  const [itemsData, setItemsData] = useState([]);
  const [transactionData, setTransactionsData] = useState([]);

  const [coords, setCoords] = useState([]);
  const [recipes_options, setRecipes_options] = useState(
    transactionsStore.recipesForETDReal
  );
  const [selectedRecipe, setSelectedRecipe] = useState("");

  const newContent = (key) => {
    setTabContent(key);
  };
  useEffect(() => {
    transactionsStore.setSelectedRecipeForETDReal("All");
    transactionsStore.GetRecipesForETDReal();
  }, []);
  useEffect(() => {
    setRecipes_options(transactionsStore.recipesForETDReal);
  }, [transactionsStore.recipesForETDReal]);

  const changeRecipe = (recipe) => {
    transactionsStore.setSelectedRecipeForETDReal(recipe.value);
  };

  let user = window.localStorage.getItem("user");
  user = JSON.parse(user);

  return (
    <div>
      {/* <MapHeat></MapHeat> */}
      <Row justify="center" align="start">
        {/* <Divider orientation="center">Recipes </Divider> */}
        <span style={{ marginTop: "4px" }}> Select Recipes:</span>
        {recipes_options.length > 0 && (
          <Select
            placeholder={"recipes_options"}
            defaultValue={transactionsStore.recipesForETDReal[0].text}
            width={"auto"}
            style={{ width: "auto", marginBottom: "12px", marginLeft: "8px" }}
            popupMatchSelectWidth={false}
            onChange={(e, s) => {
              console.log(e, s);
              changeRecipe(s);
            }}
          >
            {recipes_options.map((item, index) => {
              return (
                <Option key={index} value={item.value}>
                  {item.text}
                </Option>
              );
            })}
          </Select>
        )}
      </Row>
      <Row justify="center" align="center">
        {transactionsStore.transactionsETDRealTime != {} && (
          <Col span={24} md={24} lg={24} xs={24} sm={24} xxl={24}>
            <Row>
              <Col span={4}>
                {/* <Divider orientation="center">View </Divider> */}
              </Col>
              <Col span={16} lg={16} xs={16} sm={16}>
                {/* <Divider orientation="center">View </Divider> */}
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center" /* Horizontal centering */,
                    alignItems: "center" /* Vertical centering */,
                    // height: 100vh;            /* Full viewport height (or set a specific height) */
                    // border: 1px solid black;
                  }}
                >
                  {" "}
                  <Tabs
                    style={{ width: "300px" }}
                    defaultActiveKey="1"
                    onChange={newContent}
                    // tabPosition="left"
                  >
                    <TabPane
                      tab={
                        <span>
                          <Icon path={mdiMapMarkerAlertOutline} size={1} />
                          {/* <FileTextOutlined
                    style={{ width: "22px", height: "22px" }}
                  /> */}
                          Regular map
                        </span>
                      }
                      key="1"
                    ></TabPane>

                    <TabPane
                      tab={
                        <span>
                          <Icon path={mdiThermometer} size={1} />
                          {/* <Icon path={mdiTranslate} size={1} /> */}
                          Heatmap
                        </span>
                      }
                      key="2"
                    ></TabPane>
                  </Tabs>
                </Row>
              </Col>
              <Col span={4}></Col>
            </Row>
            <Row
              style={{
                display: "flex",
                justifyContent: "center" /* Horizontal centering */,
                alignItems: "center" /* Vertical centering */,
                // height: 100vh;            /* Full viewport height (or set a specific height) */
                // border: 1px solid black;
              }}
            >
              {" "}
              {tabContent === "1" && (
                <div>
                  <ETDMapRealTimeNew />{" "}
                </div>
              )}
              {tabContent === "2" && <MapHeatNew></MapHeatNew>}
            </Row>

            <div>
              <div
                style={{
                  marginTop: "20px",
                  marginBottom: "-14px",
                  display: "flex",
                  justifyContent: "center" /* Horizontal centering */,
                  alignItems: "center" /* Vertical centering */,
                  // height: 100vh;            /* Full viewport height (or set a specific height) */
                  // border: 1px solid black;
                }}
              >
                {" "}
                Transactions List
              </div>
              <TransactionsETDRealTimeList></TransactionsETDRealTimeList>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default observer(TransactionsETDRealTime);
