import React, { useTransition, useState, useEffect, useRef } from "react";
import { Col, Row, Card, Tabs, Divider, Button, Drawer } from "antd";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";
import TransactionsSTDSearch from "../../components/Transactions/TransactionsSTDSearch";
import TransactionsSTDSDMA from "../../components/Transactions/TransactionsSTDSDMA";
import SearchFormETD from "../../components/Transactions/SearchFormETD";
import TransactionsETDRealTime from "../../components/Transactions/TransactionsETDRealTime";
import TransactionsETDRealTimeTwo from "../../components/Transactions/TransactionsETDRealTimeTwo";
import TransactionsAID from "../../components/Transactions/TransactionsAID";
import Recipes from "../../components/Transactions/Recipes";
import TransactionMap from "../../components/Transactions/TransactionMap";
import { mdiHandshakeOutline, mdiWeb, mdiTruck, mdiEarth } from "@mdi/js";
import Icon from "@mdi/react";
import { UnorderedListOutlined } from "@ant-design/icons";
import { useParams, useOutletContext } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { PrinterOutlined } from "@ant-design/icons";

const TransactionPage = (props) => {
  const { mainStore } = useMobxStores();
  const [isPending, startTransition] = useTransition();
  const [oneTrasaction, setOneTransaction] = useState("");
  const [tab, setTab] = useState("1");
  const { parentRef } = useOutletContext();

  const [showmap, setShowMap] = useState(false);
  const [alertsCoords, setAlertsCoords] = useState("");
  const [coords, setCoords] = useState([[42], [22]]);
  const [transactionsTitle, setTransactionsTitle] = useState("STD search");
  const [targets, setTargets] = useState([
    [32.01, 24.02],
    [33.06, 23.05],
    [31, 25],
    [34, 27],
    [39, 37],
  ]);
  const componentRef = useRef();
  let params = useParams();
  const handlePrint = useReactToPrint({
    content: () => {
      // Create a wrapper element combining both parent and child content
      const wrapper = document.createElement("div");

      // Append the parent's Row (parentRef) to the wrapper
      if (parentRef.current) {
        wrapper.appendChild(parentRef.current.cloneNode(true));
      }

      // Append the child content (childRef) to the wrapper
      if (componentRef.current) {
        wrapper.appendChild(componentRef.current.cloneNode(true));
      }

      return wrapper;
    },
    documentTitle: "Transactions Report", // Optional: you can give a title to the document
    pageStyle: `@page {
      size: landscape; /* Force landscape orientation */
    }`,
    // componentRef.current,
  });

  const OnMap = (record) => {
    console.log("fd");
    setShowMap(true);
    setCoords([[record.latitude], [record.longitude]]);
  };

  const showOneTransaction = (data) => {
    console.log(data);
    setOneTransaction(data);
  };

  useEffect(() => {
    console.log("opopopop");
    console.log(mainStore);
    console.log(mainStore.coords);
    if (mainStore.coords) {
      selectTab("3");
    }

    // selectTab("3");
    // setTimeout(() => {}, 1000);
  }, []);

  const selectTab = (key) => {
    setTab(key); // Update the tab key
    switch (key) {
      case "1":
        setTransactionsTitle("STD search");
        break;
      case "2":
        setTransactionsTitle("Existing Suspicious Transactions");
        break;
      case "3":
        setTransactionsTitle("ETD Search");
        break;
      case "4":
        setTransactionsTitle("ETD Transactions Real-Time");
        break;
      case "5":
        setTransactionsTitle("Recipes & Ingredients");
        break;
      case "6":
        setTransactionsTitle("AID");
        break;
      default:
        setTransactionsTitle("");
        break;
    }
  };

  const items = [
    {
      key: "1",
      label: "STD Search",
      children: (
        <TransactionsSTDSearch
          onClick={(data) => {
            showOneTransaction(data);
          }}
          ids={{ projectId: params.pk }}
        />
      ),
    },
    {
      key: "2",
      label: "Existing Suspicious Transactions",
      children: (
        <TransactionsSTDSDMA
          onClick={(data) => {
            showOneTransaction(data);
          }}
          ids={{ projectId: params.pk }}
        />
      ),
    },
    {
      key: "3",
      label: "ETD Search",
      children: (
        <SearchFormETD
          onClick={(data) => {
            showOneTransaction(data);
          }}
          ids={{ projectId: params.pk }}
        />
      ),
    },

    {
      key: "4",
      label: "ETD Transactions Real-Time",
      children: <TransactionsETDRealTimeTwo />,
    },
    {
      key: "6",
      label: "AID",
      children: <TransactionsAID />,
    },
    {
      key: "5",
      label: "Recipes & Ingredients",
      children: <Recipes />,
    },
    // {
    //   key: "7",
    //   label: "ETD Transactions Real-Time2",
    //   children: <TransactionsETDRealTimeTwo />,
    // },
  ];

  return (
    <Row
      justify="center"
      align="center"
      style={{ width: "100%", marginTop: "24px", height: "94%" }}
    >
      <Col
        style={{ height: "95%" }}
        span={23}
        md={23}
        lg={23}
        xs={23}
        sm={23}
        xxl={23}
      >
        <div ref={componentRef}>
          <Card
            style={{
              width: "100%",
              marginTop: "24px",
              // marginBottom: "-40px",
              height: "auto",
              // height: "70vh",
              // minHeight: "700px",
              // maxHeight: "1400px",
            }}
          >
            <Row justify="end">
              <span>
                <Button
                  type="default" // Set the button type to "default" to get a white button
                  onClick={handlePrint} // Handle the click event
                  size="medium"
                  style={{ backgroundColor: "#354289", color: "white" }} // Set the background color to white and font color to blue
                >
                  <div align="center">
                    <span>
                      <PrinterOutlined />
                    </span>
                  </div>
                </Button>
              </span>
            </Row>
            {!oneTrasaction && (
              <div>
                <div
                  className="showOnPrint"
                  style={{
                    display: "none",
                    justifyContent: "center" /* Centers horizontally */,
                    alignItems: "center" /* Centers vertically */,

                    width: "100%",
                    height: "40px",
                  }}
                >
                  {" "}
                  {transactionsTitle}
                </div>{" "}
                <Tabs
                  id="transactionsTab"
                  defaultActiveKey="1"
                  items={items}
                  onChange={selectTab}
                  activeKey={tab}
                  animated={isPending}
                />
              </div>
            )}
            {oneTrasaction && (
              <div style={{ width: "70vw", minHeight: "300px", height: "95%" }}>
                <Row>
                  <span style={{ marginLeft: "4px" }}>
                    <div>
                      {" "}
                      {oneTrasaction.suspicious === 1 && (
                        <div
                          style={{
                            backgroundColor: "red",
                            width: "120px",
                            width: "20px",
                            height: "20px",
                            color: "white",
                          }}
                        >
                          Suspicious
                        </div>
                      )}
                      {oneTrasaction.suspicious === 0 && (
                        <div
                          style={{
                            backgroundColor: "green",
                            width: "120px",
                            height: "20px",
                            color: "white",
                          }}
                        >
                          Not Suspicious
                        </div>
                      )}
                    </div>
                  </span>

                  <span>
                    <div
                      style={{
                        marginLeft: "20px",
                        height: "24px",
                        width: "140px",
                      }}
                    >
                      <>
                        {oneTrasaction.transactionType &&
                          oneTrasaction.transactionType === "Online" && (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span>
                                <div style={{ width: "26px" }}>
                                  <Icon path={mdiWeb}></Icon>
                                </div>
                              </span>
                              <span style={{ marginLeft: "5px" }}>
                                Done online
                              </span>
                            </div>
                          )}
                        {oneTrasaction.transactionType &&
                          oneTrasaction.transactionType === "Distributor" && (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span>
                                <div style={{ width: "26px" }}>
                                  <Icon path={mdiTruck}></Icon>
                                </div>
                              </span>
                              <span style={{ marginLeft: "5px" }}>
                                Done by Distributor
                              </span>
                            </div>
                          )}
                        {oneTrasaction.transactionType &&
                          oneTrasaction.transactionType === "In Person" && (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span>
                                <div style={{ width: "20px" }}>
                                  <Icon path={mdiHandshakeOutline}></Icon>
                                </div>
                              </span>
                              <span style={{ marginLeft: "5px" }}>
                                Done in person
                              </span>
                            </div>
                          )}
                      </>
                    </div>
                  </span>

                  <span
                    style={{
                      marginLeft: "20px",
                      width: "100px",
                    }}
                  >
                    <div>{oneTrasaction.country} </div>
                    {/* <div>{oneTrasaction.latitude} </div>
                          <div>{oneTrasaction.longitude} </div> */}
                  </span>
                  <span>
                    <Button
                      type="default" // Set the button type to "default" to get a white button
                      onClick={() => OnMap(oneTrasaction)} // Handle the click event
                      size="small"
                      style={{
                        backgroundColor: "#354289",
                        color: "white",
                        marginLeft: "6px",
                        height: "26px",
                      }} // Set the background color to white and font color to blue
                    >
                      <Row align="center">
                        <span>
                          {/* <RollbackOutlined
                          style={{ fontSize: "20px", marginRight: "4px" }}
                        /> */}
                          <Icon
                            style={{ width: "18px" }}
                            path={mdiEarth}
                          ></Icon>{" "}
                        </span>
                        <span
                          style={{
                            marginLeft: "2px",
                          }}
                        >
                          Map
                        </span>{" "}
                      </Row>
                    </Button>
                  </span>

                  <span
                    style={{
                      marginLeft: "20px",
                      width: "160px",
                    }}
                  >
                    {oneTrasaction.timedate}
                  </span>

                  <span>
                    <span style={{ marginLeft: "20px", fontWeight: "bold" }}>
                      {" "}
                      Id :
                    </span>
                    <span> {oneTrasaction.id}</span>
                  </span>
                  <span>
                    <span style={{ marginLeft: "20px", fontWeight: "bold" }}>
                      {" "}
                      Hash :
                    </span>
                    <span> {oneTrasaction.hash}</span>
                  </span>

                  <span>
                    <Button
                      type="default" // Set the button type to "default" to get a white button
                      onClick={() => {
                        showOneTransaction("");
                      }} // Handle the click event
                      size="medium"
                      style={{
                        backgroundColor: "#354289",
                        color: "white",
                        marginLeft: "6px",
                      }} // Set the background color to white and font color to blue
                    >
                      <Row align="center">
                        <span>
                          <UnorderedListOutlined
                            style={{ fontSize: "20px", marginRight: "4px" }}
                          />
                        </span>
                        <span>View transactions</span>{" "}
                      </Row>
                    </Button>
                  </span>
                </Row>
                <Row style={{ marginTop: "8px" }}>
                  <span>
                    <div>
                      <span style={{ fontWeight: "bold" }}>Buyer :</span>
                      <span>{oneTrasaction.buyer}</span>

                      <span style={{ marginLeft: "4px", fontWeight: "bold" }}>
                        {" "}
                        Licence :
                      </span>
                      <span>{oneTrasaction.buyerlicence}</span>

                      <span style={{ marginLeft: "4px", fontWeight: "bold" }}>
                        Card no. :
                      </span>
                      <span>{oneTrasaction.cardNumber}</span>
                    </div>
                  </span>

                  <span style={{ marginLeft: "54px" }}>
                    <span style={{ fontWeight: "bold" }}>Seller :</span>
                    <span>{oneTrasaction.seller}</span>
                  </span>
                </Row>
                <Divider></Divider>
                <div>Items</div>
                <div
                  className="transactionsHeight"
                  style={{
                    border: "1px black solid",
                    overflowY: "auto",
                    height: "70px",
                    width: "100%",
                  }}
                >
                  {oneTrasaction.items.map((item, index) => {
                    return (
                      <div
                        style={{
                          // borderTop: "2px #B0BEC5 solid",
                          // borderRadius: "6px",
                          marginBottom: "1px",
                          marginTop: "1px",
                        }}
                        key={index}
                      >
                        <Row>
                          <Col
                            style={{
                              marginLeft: "12px",
                            }}
                            span={5}
                          >
                            <div>
                              <span style={{ fontWeight: "bold" }}>
                                Classification Violation:{" "}
                              </span>
                              <span>
                                {item.classificationViolation ? "Yes" : "No"}
                              </span>
                            </div>
                            <div>
                              <span style={{ fontWeight: "bold" }}>
                                {" "}
                                Quantity Violation:
                              </span>
                              <span>
                                {item.quantityViolation ? "Yes" : "No"}
                              </span>
                            </div>
                            <div>
                              <span style={{ fontWeight: "bold" }}>
                                {" "}
                                Regulation Violation:{" "}
                              </span>
                              <span>
                                {item.regulationViolation ? "Yes" : "No"}
                              </span>
                            </div>
                          </Col>
                          <Divider
                            style={{ height: "60px" }}
                            type="vertical"
                          ></Divider>
                          <Col span={5}>
                            <div> {item.name}</div>
                            {item.precursorName && (
                              <div> ({item.precursorName})</div>
                            )}
                          </Col>
                          <Divider
                            style={{ height: "60px" }}
                            type="vertical"
                          ></Divider>
                          <Col span={4}>
                            <div>
                              <span style={{ fontWeight: "bold" }}>Price:</span>
                              <span>{item.price}</span>
                            </div>
                            <div>
                              <span style={{ fontWeight: "bold" }}>
                                Quantity:
                              </span>
                              <span>{item.quantity}</span>
                            </div>

                            <div>
                              <span style={{ fontWeight: "bold" }}>
                                Total Quantity:
                              </span>
                              <span>
                                {Number(item.totalquantity).toFixed(4)}
                              </span>
                            </div>
                          </Col>
                          <Divider
                            style={{ height: "60px" }}
                            type="vertical"
                          ></Divider>
                          <Col span={3}>
                            <div>
                              <span style={{ fontWeight: "bold" }}>
                                Unit size:
                              </span>
                              <span>{item.unitsize}</span>
                            </div>
                          </Col>
                          <Divider
                            style={{ height: "60px" }}
                            type="vertical"
                          ></Divider>
                          <Col span={4}>
                            <div>
                              <span style={{ fontWeight: "bold" }}>
                                Concentration:
                              </span>
                              <span>{item.concentration}</span>
                            </div>
                            <div>
                              <span style={{ fontWeight: "bold" }}>
                                Component of:
                              </span>
                              <span>{item.iscomponentof}</span>
                            </div>
                          </Col>
                        </Row>
                        <Divider
                          style={{
                            marginTop: "-2px",
                            marginBottom: "-2px",
                            "background-color": "#90A4AE",
                          }}
                        ></Divider>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </Card>
          {transactionsTitle === "AID" && <div class="empytHeightAID "></div>}
        </div>
      </Col>
      <Col span={1} md={1} lg={1} xs={1} sm={1} xxl={1}>
        <Button
          onClick={() => {
            mainStore.startReal();
          }}
        >
          Start
        </Button>
        <Button
          onClick={() => {
            mainStore.stopReal();
          }}
        >
          Stop
        </Button>
        <Button
          onClick={() => {
            mainStore.stepReal();
          }}
        >
          step
        </Button>
        <Button>reset</Button>
      </Col>
      <Drawer
        title="Transaction on Map"
        placement="right"
        size={"large"}
        onClose={() => setShowMap(false)}
        open={showmap}
        // open={true}
        headerStyle={{ textAlign: "center" }}
      >
        <TransactionMap
          key={coords[0][0]}
          coords={coords}

          // targets={targets}
        />
      </Drawer>
    </Row>
  );
};

export default observer(TransactionPage);
