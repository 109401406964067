import { makeAutoObservable, runInAction } from "mobx";
import {
  POST,
  PUBLISH,
  GET_SDMA,
  POST_SDMA,
  DELETE_SDMA,
  PUT_SDMA,
  GET_SDMA_IMAGE,
  POST_Test,
  GET_TAS_CONFIG,
  POST_TAS_CONFIG,
  DELETE_SDMA_MANY,
  GET_SDMA_MANY,
  POST_SDMA_MANY,
  GET_LIVE_ATDL,
} from "../apis/apis";
import { constants } from "../consts/Variables";
import { v4 as uuidv4 } from "uuid";
import { ControlSaveTiles } from "leaflet.offline";
import axios from "axios";
import { saveAs } from "file-saver";
class MainStore {
  constructor({ errStore }) {
    makeAutoObservable(this);
    this.errStore = errStore;
  }

  sessions = [];
  clusterButtonIsDisabled = false;

  supplyChain = [
    // [2.01, 3.02, "29/05"],
    // [33.06, 23.05, "30/05"],
    // [31, 25],
    // [34, 27],
  ];
  exportData = {};
  errorATDL = false;
  importData = {};
  configData = {};
  showGroups = false;
  trigger = false;
  blueGroup = [];
  orangeGroup = [];
  redGroup = [];
  purpleGroup = [];
  limeGroup = [];
  greenGroup = [];
  cyanGroup = [];
  yellowGroup = [];

  waterSensorH202 = [];
  waterSensorNH3 = [];
  waterSensorY = [];

  lastOperationClusterClicked = "";

  downloadOperationData = (pId, id) => {
    GET_SDMA(
      `/api/projects/${pId}/artefacts?type=chemical:detected&type=UGV:Status&type=UGV:status&type=tas:result:air&type=tas:result:water&type=chemical:detected&type=WaterSensor:Data`
    )
      // /entities/${id}
      .then((data) => {
        console.log(data);

        let newData = [];

        for (let i = 0; i < data.data.length; i++) {
          console.log("@3");
          let obj = "";

          if (
            data.data[i].tags &&
            data.data[i].tags.length > 0 &&
            (data.data[i].tags.includes("operationId:" + id) ||
              data.data[i].tags.includes("t:operationId:" + id))
          ) {
            obj = data.data[i];
          }

          // if (
          //   data.data[i].raw &&
          //   JSON.parse(data.data[i].raw).operationId &&
          //   JSON.parse(data.data[i].raw).operationId === id
          // ) {
          //   obj = data.data[i];
          // }
          if (data.data[i].raw) {
            console.log(data.data[i].raw);
            try {
              const parsedRaw = JSON.parse(data.data[i].raw);
              if (parsedRaw.operationId === id) {
                obj = data.data[i];
              }
            } catch (error) {
              console.error(
                "Failed to parse JSON:",
                error,
                "Raw data:",
                data.data[i].raw
              );
            }
          }

          if (
            data.data[i].attributes &&
            data.data[i].attributes.operationId &&
            data.data[i].attributes.operationId[0] === id
          ) {
            obj = data.data[i];
          }
          if (obj) {
            newData.push(obj);
          }
        }
        console.log(newData);
        data.data = newData;
        // console.log(newData);

        const json = JSON.stringify(data, null, 2); // Convert data to JSON string
        const blob = new Blob([json], { type: "application/json" }); // Create a Blob with JSON MIME type
        saveAs(blob, "downloadData.json");
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  setwaterSensorH202 = (value) => {
    console.log(value);
    this.waterSensorH202.push(value);
  };
  setwaterSensorNH3 = (value) => {
    console.log(value);
    this.waterSensorNH3.push(value);
  };

  setwaterSensorY = (value) => {
    console.log(value);
    this.waterSensorY.push(value);
  };

  gas = null;

  setGas = (value) => {
    this.gas = value;
  };
  SessionsProjectId = "";
  addressPoints = [];
  currentOperationId = "";
  coords = "";
  analyzedCharacters = [];
  storedMissions = null;
  alertsOn = false;
  selectedKey = "1";
  dateFormat = "YYYY/MM/DD";
  status = "success";
  audit_items = [];
  audit_item = null;
  transaction = null;
  transactions = [];
  webdata = [];
  field_operation = null;
  changeLogItem = [];
  artefacts = [];
  projects = [];
  artefact = null;
  entities = [];
  entity = null;
  links = [];
  link = null;
  accessLogItem = [];
  currentKey = window.location.pathname;
  date = new Date();
  session = this.addMinutes(
    this.date,
    process.env.NODE_ENV === "development" ? 480 : 60
  );
  session_id = localStorage.getItem("session_id") || "";
  isKafkaConnected = "failed";
  transcation_coords = ["23.123456", "23.123456"];
  textTranslation = [];
  pageSize = 100000000;
  waterSensorData = [];
  ugvstatus = [];
  classification_module = [];
  clustering_module = [];
  social_network_analysis = [];
  searchedData = [];
  isSearching = false;
  battery = "";
  alerts = [];
  alertsTAS = [];
  x = [];
  acetone = { name: "Acetone", data: [], color: "#31438e" };
  toluene = { name: "Toluene", data: [], color: "#66BB6A" };
  x3 = [];

  y = [];
  sensor_air_data = "";
  sensor_water_data = "";
  ugv_real_time_data = {
    rgbStream:
      "https://ris00-200101aa.robotnik.cloud:8090/stream/front/index.m3u8",
    thermalStream:
      "https://ris00-200101aa.robotnik.cloud:8090/stream/thermal/index.m3u8",
  };

  ugv_real_time_data_full = "";
  uav_real_time_data = {
    // rgbStream: "https://192.168.71.10/stream/front/index.m3u8",
    // thermalStream: "https://192.168.71.10/stream/thermal/index.m3u8",
    rgbStream: "http://192.168.71.10/hls/main1/playlist.m3u8",
    thermalStream: "http://192.168.71.10/hls/main1/playlist.m3u8",
  };
  uav_real_time_data_full = "";
  UV_data = [];
  temp = { body: null, images: [] };
  TAS = "";
  TASWater = "";
  higher_TAS = "";
  higher_TASWater = "";
  higher_TASNtdl = "";
  TASNtdl = "";
  TAS_markers = [];
  wp_connection = false;
  pieData = [
    {
      color: "#F44336",
      name: "Substance",
      value: 0,
    },
    {
      color: "#BDB76B",
      name: "Date",
      value: 0,
    },
    {
      color: "#FAEBD7",
      name: "Glassware",
      value: 0,
    },
    {
      color: "#7FFF00",
      name: "Location",
      value: 0,
    },
    {
      color: "#D2691E",
      name: "Group",
      value: 0,
    },
    {
      color: "#FF7F50",
      name: "Item",
      value: 0,
    },
    {
      color: "#40C4FF",
      name: "Person",
      value: 0,
    },
    {
      color: "#00FFFF",
      name: "Quantity",
      value: 0,
    },
    {
      color: "#00008B",
      name: "Status",
      value: 0,
    },
    {
      color: "#E040FB",
      name: "Art",
      value: 0,
    },
  ];
  analyse = false;
  ugvSelected = false;
  uavSelected = false;
  analyse_entities = [];

  mapping = [
    { type2: "ner:chemical", type1: "hasSubstance", color: "#F44336" },
    { type2: "ner:da+te", type1: "hasDate", color: "#BDB76B" },
    { type2: "ner:glassware", type1: "hasGlassware", color: "#FAEBD7" },
    { type2: "ner:location", type1: "hasLocation", color: "#7FFF00" },
    { type2: "ner:group", type1: "hasGroup", color: "#D2691E" },

    { type2: "ner:item", type1: "hasItem", color: "#FF7F50" },
    // { type2: "ner:person", type1: "hasPerson", color: "#DC143C" },
    { type2: "ner:person", type1: "hasPerson", color: "#40C4FF" },

    { type2: "ner:quantity", type1: "hasQuantity", color: "#00FFFF" },
    { type2: "ner:status", type1: "hasStatus", color: "#00008B" },
    // { type2: "ner:art", type1: "hasArt", color: "#FF8C00" },
    { type2: "ner:art", type1: "hasArt", color: "#E040FB" },
  ];
  // mapping = [
  //
  //    //   { type2: "ner:quantity", type1: "hasQuantity", color: "#00FFFF" },
  //   { type2: "ner:status", type1: "Status", color: "#00008B" },
  //   { type2: "ner:art", type1: "Art", color: "#FF8C00" },
  // ];

  transactionsETD = [];
  isETDSearching = false;
  ETDCoords = null;
  transactionsETDRealTime = [];
  ETDCoordsRealTime = null;
  isMissionModalOpen = false;
  isOperationModalOpen = false;
  clusterDomainId = "";
  projectIdForClustering = "";
  projectIdForSN = "";
  snDomainId = "";
  AlertsTASProjectId = "";
  AlertsProjectId = "";

  goToKeycloak = () => {
    console.log(constants);
    window.open(constants.AUTH_URL + "/admin", "_blank");
  };

  setIsOperationModalOpen = (value) => {
    this.isOperationModalOpen = value;
  };

  setIsMissionModalOpen = (value) => {
    this.isMissionModalOpen = value;
  };
  startReal = () => {
    POST_Test("play");
  };
  stepReal = () => {
    POST_Test("step");
  };
  stopReal = () => {
    POST_Test("stop");
  };
  // https://etd-api.odysseus-online.rid-intrasoft.eu/start
  // https://etd-api.odysseus-online.rid-intrasoft.eu/stop
  // https://etd-api.odysseus-online.rid-intrasoft.eu/step
  // https://etd-api.odysseus-online.rid-intrasoft.eu/reset

  setETDCoords = (value) => {
    console.log("ETDSearchTwo");

    let temp = [];

    Object.entries(value).map((item) => {
      // console.log(item);
      if (item[1] != {}) {
        Object.entries(item[1]).map((item) => {
          console.log(item);
          if (item[1]) {
            temp.push({
              coords: { lat: item[1].centroid[0], lon: item[1].centroid[1] },
              properties: item[1].properties,
              id: item[0],
              recipe: item[1].properties.recipe,
              transactions: item[1].transactions,
            });
          }
        });
      }
    });
    console.log("temp");
    console.log(temp);
    // this.transactionsETD = []

    // const uniqueRecipes = new Set(); // To store unique recipes

    // // Iterate through the array of objects
    // temp.forEach((transaction) => {
    //   if (transaction.recipe) {
    //     uniqueRecipes.add(transaction.recipe.trim()); // Add recipe after trimming spaces
    //   }
    // });

    // let result = Array.from(uniqueRecipes).map((recipe) => {
    //   return {
    //     name: recipe,
    //     value: this.transactionsETD[recipe], // Assuming mainStore.transactionsETD exists
    //   };
    // });

    // function getUniqueRecipes(transactions) {

    // Convert the uniqueRecipes Set into an array of objects with the required structure

    //   return result;
    // }
    // console.log(result);
    // let recipes = result;
    // console.log(recipes);
    // this.transactionsETD = temp;
    this.ETDCoords = temp;
  };

  setETDCoordsRealTime = (value) => {
    let temp = [];

    Object.entries(value).map((item) => {
      // console.log(item);
      if (item[1] != {}) {
        Object.entries(item[1]).map((item) => {
          if (item[1]) {
            temp.push({
              coords: { lat: item[1].centroid[0], lon: item[1].centroid[1] },
              properties: item[1].properties,
              id: item[0],
              recipe: item[1].properties.recipe,
            });
          }
        });
      }
    });
    this.ETDCoordsRealTime = temp;
  };

  setIsETDSearching = (value) => {
    this.isETDSearching = value;
  };

  setTransactionsETD = (value) => {
    this.transactionsETD = value;
    console.log(this.transactionsETD);
  };

  setTransactionsETDRealTime = (value) => {
    console.log(value);
    this.transactionsETDRealTime = value;
  };

  setStoredMissions = (value) => {
    this.storedMissions = value;
  };

  setAlertsOn = (value) => {
    this.alertsOn = value;
  };
  setAlertsTASOn = (value) => {
    this.alertsTASOn = value;
  };

  setAnalyseEntities = (value) => {
    this.analyse_entities = value;
  };

  setAnalyse = (value) => {
    this.analyse = value;
  };

  analysisPercent = 0;

  setAnalysisPercent = (value) => {
    this.analysisPercent = value;
  };

  setPieData = (value) => {
    this.setAnalysisPercent(0);
    this.pieData = [];
    let metaDataTemp = {};
    for (let j = 0; j < this.mapping.length; j++) {
      let value = this.analyse_entities.filter(
        (item) => item.type == this.mapping[j].type2
      ).length;
      metaDataTemp = {
        color: this.mapping[j].color,
        name: this.mapping[j].type1,
        value: value,
        // value: 2,
      };
      this.pieData.push(metaDataTemp);
      // this.setAnalysisPercent(((j + 1) / this.mapping.length) * 100);
    }
  };

  SetWSConnection = (value) => {
    this.wp_connection = value;
  };

  setTASMarkers = (value) => {
    this.TAS_markers = value;
  };

  getTasConfig = () => {
    return GET_TAS_CONFIG(`config`)
      .then((data) => {
        console.log(data);
        let response = data;
        // this.errStore.setMessage("SDMA connected");
        this.errStore.setInfo(true);
        this.configData = {
          AirLow: response.tas_thresholds_air[0],
          AirMedium: response.tas_thresholds_air[1],
          AirHigh: response.tas_thresholds_air[2],
          WaterLow: response.tas_thresholds_water[0],
          WaterMedium: response.tas_thresholds_water[1],
          WaterHigh: response.tas_thresholds_water[2],
        };
      })
      .catch((error) => {
        this.errStore.setError(true);
        // this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  setNewTasConfig = (newData) => {
    return POST_TAS_CONFIG(`config`, newData)
      .then((data) => {
        console.log(data);
        // this.errStore.setMessage("SDMA connected");
        this.errStore.setInfo(true);
        this.getTasConfig();
      })
      .catch((error) => {
        this.errStore.setError(true);
        // this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  setTas = (pid, id, message, userType) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pid}/artefacts?id=${id}`)
      .then((data) => {
        console.log("tas data: ", data);
        this.errStore.setMessage("");
        if (data.data != []) {
          if (data.data[0].type === "tas:result:air") {
            this.TAS = JSON.parse(data.data[0].raw);
            // this.TAS_markers.push({
            //   latitude: JSON.parse(data.data[0].raw).latitude,
            //   longitude: JSON.parse(data.data[0].raw).longitude,
            // });
            console.log("data.data[0].raw).threatLevel");
            console.log(JSON.parse(data.data[0].raw).threatLevel);
            console.log(userType);
            if (JSON.parse(data.data[0].raw).threatLevel === "High") {
              if (
                userType.includes("mission_commander") ||
                userType.includes("field_operator")
              ) {
                console.log("tasMessage1");
                // mobxStores.mainStore.addNotification(message.body);
                let fields = {};
                let newUuid = uuidv4();
                let messageString = JSON.stringify(message);
                fields.name = "alert:" + newUuid;
                fields.attributes = { message: [messageString] };
                fields.type = "TAS_alert";
                this.addAlertTAS(fields);
              }
            }

            this.errStore.setLoading(false);
            if (this.higher_TAS === "") {
              this.higher_TAS = JSON.parse(data.data[0].raw);
            }
            if (this.higher_TAS !== "") {
              console.log("New: ", JSON.parse(data.data[0].raw).confidence);
              console.log("Old: ", this.higher_TAS.confidence);
              if (
                this.higher_TAS.confidence <
                JSON.parse(data.data[0].raw).confidence
              ) {
                this.higher_TAS = JSON.parse(data.data[0].raw);
                console.log("true");
              }
            }
          }
          if (data.data[0].type === "tas:result:water") {
            this.TASWater = JSON.parse(data.data[0].raw);
            this.errStore.setLoading(false);
            if (this.higher_TASWater === "") {
              this.higher_TASWater = JSON.parse(data.data[0].raw);
            }
            if (this.higher_TASWater !== "") {
              if (
                this.higher_TASWater.confidence <
                JSON.parse(data.data[0].raw).confidence
              ) {
                this.higher_TASWater = JSON.parse(data.data[0].raw);
              }
            }
          }
          if (data.data[0].type === "tas:result:ntdl") {
            this.TASNtdl = JSON.parse(data.data[0].raw);
            this.errStore.setLoading(false);
            if (this.higher_TASNtdl === "") {
              this.higher_TASNtdl = JSON.parse(data.data[0].raw);
            }
            if (this.higher_TASNtdl !== "") {
              if (
                this.higher_TASNtdl.confidence <
                JSON.parse(data.data[0].raw).confidence
              ) {
                this.higher_TASNtdl = JSON.parse(data.data[0].raw);
              }
            }
          }
        } else {
          this.errStore.setInfo(true);
          this.errStore.setMessage("TAS NOT found");
        }
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("TAS NOT found");
        console.log("catch: ", error);
      });
  };

  setAirDataNew = () => {
    if (this.acetone.data.length > 0 && this.toluene.data.length > 0) {
      this.x3 = [this.acetone, this.toluene];
    }
    if (this.acetone.data.length > 0 && this.toluene.data.length === 0) {
      this.x3 = [{ name: "craze", data: this.acetone.data }];
    }
    if (this.acetone.data.length === 0 && this.toluene.data.length > 0) {
      this.x3 = [{ name: "craze", data: this.toluene.data }];
    }
    if (this.acetone.data.length === 0 && this.toluene.data.length === 0) {
      this.x3 = [];
    }
  };
  SetUV_data = (value) => {
    this.UV_data = value;
  };

  setTemp = (value) => {
    this.temp = value;
  };

  setUGVRealTimeData = (value) => {
    this.ugv_real_time_data = value;
  };

  setUGVRealTimeDataFull = (value) => {
    console.log(value);
    this.ugv_real_time_data_full = value;
  };

  setUAVRealTimeData = (value) => {
    this.uav_real_time_data = value;
  };

  setUAVRealTimeDataFull = (value) => {
    console.log(value);
    this.uav_real_time_data_full = value;
  };

  setWaterSensorData = (value) => {
    console.log(value);
    this.sensor_water_data = value;
  };

  setAirSensorData = (value) => {
    this.sensor_air_data = value;
  };

  setx = (peaks) => {
    const colorsCategories = ["#f57c00", "#31438e", "#66BB6A", "#E53935"];
    console.log(peaks);
    let newPeaks = peaks;
    for (let i = 0; i < newPeaks.length; i++) {
      newPeaks[i].color = colorsCategories[i];
    }
    this.x = newPeaks;
  };
  sety = (value) => {
    this.y = value;
  };
  setAlerts = (value) => {
    this.alerts = value;
  };
  setAlertsTAS = (value) => {
    this.alertsTAS = value;
  };

  setSessionId = (value) => {
    this.session_id = value;
  };

  setIsSearching = (value) => {
    this.isSearching = value;
  };

  setSearchedData = (value) => {
    let newData = value.transactions;

    this.searchedData = value.transactions;
  };

  setSupplyChain = (transactions) => {
    if (transactions.length > 0) {
      let targets = Object.values(transactions).map((transaction) => {
        return [transaction.latitude, transaction.longitude, transaction.date];
      });
      this.supplyChain = targets.sort(
        (a, b) => new Date(a[2]).getTime() - new Date(b[2]).getTime()
      );
    } else {
      this.supplyChain = [];
    }
  };

  setBattery = (value) => {
    this.battery = value;
  };

  setSelectedKey = (value) => {
    this.selectedKey = value;
  };

  deleteAlert = (id) => {
    this.errStore.setLoading(true);
    return DELETE_SDMA(`/api/projects/${this.AlertsProjectId}/entities/${id}`)
      .then((data) => {
        console.log(data);
        // this.setIsOperationModalOpen(false);
        this.errStore.setInfo(true);

        this.GetAlerts();
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  deleteAlertTAS = (id) => {
    this.errStore.setLoading(true);
    return DELETE_SDMA(
      `/api/projects/${this.AlertsTASProjectId}/entities/${id}`
    )
      .then((data) => {
        console.log(data);
        // this.setIsOperationModalOpen(false);
        this.errStore.setInfo(true);

        this.GetAlertsTAS();
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  deleteSession = (id) => {
    console.log(id);
    this.errStore.setLoading(true);
    return DELETE_SDMA(`/api/projects/${this.SessionsProjectId}/entities/${id}`)
      .then((data) => {
        console.log(data);
        // this.setIsOperationModalOpen(false);
        this.errStore.setInfo(true);

        this.GetSessions();
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  deleteAllAlerts = () => {
    this.errStore.setLoading(true);

    let urls = this.alerts.map((alert) => {
      return `/api/projects/${this.AlertsProjectId}/entities/${alert.id}`;
    });

    return DELETE_SDMA_MANY(urls)
      .then((data) => {
        console.log(data);
        // this.setIsOperationModalOpen(false);
        this.errStore.setInfo(true);

        this.GetAlerts();
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  deleteAllAlertsTAS = () => {
    this.errStore.setLoading(true);

    let urls = this.alertsTAS.map((alert) => {
      return `/api/projects/${this.AlertsTASProjectId}/entities/${alert.id}`;
    });

    return DELETE_SDMA_MANY(urls)
      .then((data) => {
        console.log(data);
        // this.setIsOperationModalOpen(false);
        this.errStore.setInfo(true);

        this.GetAlerts();
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  clearAlerts = (key) => {
    this.alerts = [];
  };
  clearAlertsTAS = (key) => {
    this.alertsTAS = [];
  };
  addAlert = (value) => {
    this.alerts.push(value);
  };
  addAlertTAS = (value) => {
    this.alertsTAS.push(value);
  };

  GetAlerts = (pid) => {
    this.errStore.setLoading(true);

    GET_SDMA(
      `/api/projects/${this.AlertsProjectId}/entities?type=suspicious_transaction`
    )
      .then((data) => {
        this.errStore.setLoading(false);
        console.log("suspicious transaction");
        console.log(data.data);
        let alerts = data.data.map((alert) => {
          return {
            id: alert.id,
            message: JSON.parse(alert.attributes.message[0]),
          };
        });
        this.alerts = alerts;
        console.log(alerts);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  GetSessions = (pid) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${this.SessionsProjectId}/entities?type=session`)
      .then((data) => {
        this.errStore.setLoading(false);
        console.log(data.data);
        let sessions = data.data
          // .splice(0, data.data.length - 1)
          .map((item) => {
            if (item.attributes.mission_id && item.attributes.mission_id[0]) {
              return {
                mission_id: item.attributes.mission_id[0],
                id: item.id,
                session_id: item.attributes.session_id[0],
                operation_id: item.attributes.operation_id[0],
              };
            } else {
              return {
                mission_id: "unknown",
                id: item.id,
                session_id: item.attributes.session_id[0],
                operation_id: item.attributes.operation_id[0],
              };
            }
          });

        this.sessions = sessions;

        console.log(sessions);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  GetAlertsTAS = (pid) => {
    console.log("rerrr");
    this.errStore.setLoading(true);

    GET_SDMA(`/api/projects/${this.AlertsTASProjectId}/entities?type=TAS_alert`)
      .then((data) => {
        this.errStore.setLoading(false);
        console.log(data.data);
        let alertsTAS = data.data.map((alert) => {
          return {
            id: alert.id,
            message: JSON.parse(alert.attributes.message[0]),
          };
        });
        // this.alertsTAS = alertsTAS;
        console.log(alertsTAS);
        let ProjectIds = alertsTAS.map((alert) => {
          return alert.message.header.projectId;
        });
        console.log(ProjectIds);
        let uniqueProjectIds = [...new Set(ProjectIds)].filter(
          (value) => value !== undefined
        );
        console.log(uniqueProjectIds);
        // let uniqueProjectIdsNew = uniqueProjectIds
        let url_missions = uniqueProjectIds.map((uniqueProjectId) => {
          return `/api/projects/${uniqueProjectId}`;
        });
        let url_operations = uniqueProjectIds.map((uniqueProjectId) => {
          return `/api/projects/${uniqueProjectId}/entities?type=field`;
        });
        // let newAlerts = [...alertsTAS];

        GET_SDMA_MANY(url_missions)
          .then((dataMany) => {
            console.log("analyse-39");
            console.log(dataMany);
            for (let i = 0; i < dataMany.length; i++) {
              for (let y = 0; y < alertsTAS.length; y++) {
                if (
                  alertsTAS[y].message.header.projectId ===
                  dataMany[i].data.data.id
                ) {
                  alertsTAS[y].name = dataMany[i].data.data.name;
                }
              }
            }
            console.log(alertsTAS);
            GET_SDMA_MANY(url_operations)
              .then((dataManyOperations) => {
                console.log(dataManyOperations);
                for (let i = 0; i < dataManyOperations.length; i++) {
                  console.log(dataManyOperations[i]);
                  console.log(dataManyOperations[i].data.data);
                  for (
                    let x = 0;
                    x < dataManyOperations[i].data.data.length;
                    x++
                  ) {
                    for (let y = 0; y < alertsTAS.length; y++) {
                      if (
                        alertsTAS[y].message.body.operationId ===
                        dataManyOperations[i].data.data[x].attributes
                          .operationId[0]
                      ) {
                        alertsTAS[y].operationName =
                          dataManyOperations[i].data.data[x].name;
                      }
                    }
                  }
                }
                console.log(alertsTAS);
                this.alertsTAS = alertsTAS;
              })
              .catch((error) => {
                this.errStore.setError(true);
                this.errStore.setMessage("SDMA ERROR");
                console.log(error);
              });

            // this.alertTAS = alertsTAS;
          })
          .catch((error) => {
            this.errStore.setError(true);
            this.errStore.setMessage("SDMA ERROR");
            console.log(error);
          });
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  addAlert = (values) => {
    this.errStore.setLoading(true);
    return POST_SDMA(`/api/projects/${this.AlertsProjectId}/entities`, values)
      .then((data) => {
        console.log(data);
        // this.setIsOperationModalOpen(false);
        // this.errStore.setInfo(true);
        // this.errStore.setMessage("Operation added to mission!");

        this.GetAlerts();
        // this.isRecipeModalOpen(false);
        // this.GetMissionOperations(this.pid);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  addAlertTAS = (values) => {
    this.errStore.setLoading(true);
    return POST_SDMA(
      `/api/projects/${this.AlertsTASProjectId}/entities`,
      values
    )
      .then((data) => {
        console.log(data);
        // this.setIsOperationModalOpen(false);
        // this.errStore.setInfo(true);
        // this.errStore.setMessage("Operation added to mission!");

        this.GetAlertsTAS();
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  addSession = (values) => {
    this.errStore.setLoading(true);
    return POST_SDMA(`/api/projects/${this.SessionsProjectId}/entities`, values)
      .then((data) => {
        console.log("TTt");
        console.log(data);
        this.GetSessions();
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // handle deleting objects
  isItemDeleting = false;

  setIsItemDeleting = (value) => {
    this.isItemDeleting = value;
  };

  // drones variables
  drones = [];
  isCreateDroneModalOpen = false;
  isDroneCreating = false;
  droneCreated = false;
  isDroneEditing = false;
  EditingDrone = null;
  drone_id = null;

  setEditingDrone = (value) => {
    this.EditingDrone = value;
  };

  setIsDroneEditing = (value) => {
    this.isDroneEditing = value;
  };

  setIsCreateDroneModalOpen = (value) => {
    this.isCreateDroneModalOpen = value;
  };
  setIsDroneCreating = (value) => {
    this.isDroneCreating = value;
  };
  setDroneCreated = (value) => {
    this.droneCreated = value;
  };

  DroneFields = [
    {
      name: ["id"],
      value: "",
    },
    {
      name: ["name"],
      value: "",
    },
    {
      name: ["status"],
      value: "",
    },
  ];

  ResetDroneFields = () => {
    this.DroneFields = [
      {
        name: ["id"],
        value: "",
      },
      {
        name: ["name"],
        value: "",
      },
      {
        name: ["status"],
        value: "",
      },
    ];
  };

  setDroneFields = (record) => {
    this.DroneFields = [
      {
        name: ["id"],
        value: record.id,
      },
      {
        name: ["name"],
        value: record.name,
      },
      {
        name: ["title"],
        value: record.title,
      },
    ];
  };

  setIsKafkaConnected = (value) => {
    this.isKafkaConnected = value;
  };

  addMinutes(date, minutes) {
    date.setMinutes(date.getMinutes() + minutes);

    return date;
  }

  resetSession = () => {
    this.date = new Date();
    this.session = this.addMinutes(this.date, 60);
  };

  // Api Calls SDMA

  // test SDMA
  TestSDMA = () => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects`)
      .then((data) => {
        this.errStore.setMessage("SDMA connected");
        this.errStore.setInfo(true);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // ETD

  GetETDRealTime = (pId, id) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/artefacts?domainId=${id}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.setTransactionsETDRealTime(JSON.parse(data.data[0].raw));
        this.setETDCoordsRealTime(JSON.parse(data.data[0].raw));
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // drones
  DeleteDrone = (id) => {
    this.setIsItemDeleting(true);
    DELETE_SDMA(`api/projects/86b8b6bd42ce110000000900/entities/${id}`)
      .then((data) => {
        this.setIsItemDeleting(false);
        this.errStore.setMessage("Drone Deleted");
        this.errStore.setInfo(true);
        for (var i = 0; i < this.drones.length; i++) {
          if (this.drones[i].id == id) {
            this.drones.splice(i, 1);
            break;
          }
        }
      })
      .catch((error) => {
        this.setIsItemDeleting(false);
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  session_data = [];

  Session = (body) => {
    this.session_data.push(body);
  };
  CreateMission = (values) => {
    this.errStore.setLoading(true);
    POST_SDMA(`/api/projects`, values)
      .then((data) => {
        console.log(data);
        this.AlertsProjectId = data.data.id;
        // return data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  CreateMissionTAS = (values) => {
    this.errStore.setLoading(true);
    POST_SDMA(`/api/projects`, values)
      .then((data) => {
        console.log(data);
        this.AlertsTASProjectId = data.data.id;
        // return data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  CreateMissionSessions = (values) => {
    this.errStore.setLoading(true);
    POST_SDMA(`/api/projects`, values)
      .then((data) => {
        console.log(data);
        this.SessionsProjectId = data.data.id;
        // return data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  GetProjectIdOfAlerts = () => {
    this.errStore.setLoading(true);
    return GET_SDMA(`/api/projects`)
      .then((data) => {
        this.errStore.setLoading(false);
        console.log(data);
        let projects = data.data;
        let foundMission = projects.find((project) => {
          return project.name === "AlertsDataBase";
        });
        console.log(foundMission);

        if (!foundMission) {
          this.CreateMission({
            name: "AlertsDataBase",
            description: "AlertsDataBase",
            status: "Enabled",
          });
        } else {
          this.AlertsProjectId = foundMission.id;
          // console.log(this.UsersProjectId);
          // this.GetAllUsers();
        }
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  GetProjectIdOfAlertsTAS = () => {
    this.errStore.setLoading(true);
    return GET_SDMA(`/api/projects`)
      .then((data) => {
        this.errStore.setLoading(false);
        console.log(data);
        let projects = data.data;
        let foundMission = projects.find((project) => {
          return project.name === "AlertsTASDataBase";
        });
        console.log(foundMission);

        if (!foundMission) {
          this.CreateMissionTAS({
            name: "AlertsTASDataBase",
            description: "AlertsTASDataBase",
            status: "Enabled",
          });
        } else {
          this.AlertsTASProjectId = foundMission.id;
          // console.log(this.UsersProjectId);
          // this.GetAllUsers();
        }
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  GetProjectIdOfSessions = () => {
    this.errStore.setLoading(true);
    return GET_SDMA(`/api/projects`)
      .then((data) => {
        this.errStore.setLoading(false);
        console.log(data);
        let projects = data.data;
        let foundMission = projects.find((project) => {
          return project.name === "SessionsDataBase";
        });

        if (!foundMission) {
          this.CreateMissionSessions({
            name: "SessionsDataBase",
            description: "SessionsDataBase",
            status: "Enabled",
          });
        } else {
          this.SessionsProjectId = foundMission.id;
          // console.log(this.UsersProjectId);
          // this.GetAllUsers();
        }
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  CreateDrone = (values) => {
    this.setIsDroneCreating(true);
    POST_SDMA(`/api/projects/86b8b6bd42ce110000000900/entities`, values)
      .then((data) => {
        this.setIsDroneCreating(false);
        this.setIsCreateDroneModalOpen(false);
        this.drones.push(data.data);
        this.errStore.setMessage("Drone Created");
        this.errStore.setInfo(true);
        this.setIsDroneEditing(false);
      })
      .catch((error) => {
        this.setIsDroneCreating(false);
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA Error");
        console.log(error);
      });
  };

  UpdateDrone = (values) => {
    this.setIsDroneEditing(true);
    PUT_SDMA(
      `/api/projects/86b8b6bd42ce110000000900/entities/${values.id}`,
      values
    )
      .then((data) => {
        this.setIsCreateDroneModalOpen(false);
        this.errStore.setMessage("Drone Updated");
        this.errStore.setInfo(true);
        this.setIsDroneEditing(false);
      })
      .catch((error) => {
        this.setIsDroneCreating(false);
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA Error");
        console.log(error);
      });
  };

  // transactions
  CreateTransactions = (values) => {
    POST_SDMA(`/api/projects/86b8b6bd42ce110000000900/entities`, values)
      .then((data) => {
        this.errStore.setMessage("Transactions data collection started");
        this.errStore.setInfo(true);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA Error");

        console.log(error);
      });
  };

  StartTransactions = (values) => {
    PUT_SDMA(
      `/api/projects/86b8b6bd42ce110000000900/entities/641ad1d10911fcb4f7c613f4`,
      values
    )
      .then((data) => {
        this.errStore.setMessage("Transactions data collection started");
        this.errStore.setInfo(true);
        this.transaction = data.data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA Error");
        console.log(error);
      });
  };

  StopTransactions = (values) => {
    PUT_SDMA(
      `/api/projects/86b8b6bd42ce110000000900/entities/641ad1d10911fcb4f7c613f4`,
      values
    )
      .then((data) => {
        this.errStore.setMessage("Transactions data collection stopped");
        this.errStore.setInfo(true);
        this.transaction = data.data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA Error");
        console.log(error);
      });
  };

  // web data collection

  CreateWebDataCollection = (values) => {
    POST_SDMA(`/api/projects/86b8b6bd42ce110000000900/entities`, values)
      .then((data) => {
        this.errStore.setMessage("Web data collection started");
        this.errStore.setInfo(true);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA Error");
        console.log(error);
      });
  };

  GetWebDataCollection = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(
      `/api/projects/86b8b6bd42ce110000000900/endpoints?pageSize=${this.pageSize}`
    )
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.webdata = data.data.filter((item) => item.type == "Web");
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  StartWebDataCollection = (values) => {
    POST_SDMA(`/api/projects/${values.projectId}/endpoints`, values)
      //   POST_SDMA(`/api/projects/86b8b6bd42ce110000000900/endpoints`, values)
      //   POST_SDMA(`/api/projects/${values.projectId}/endpoints`, values)
      .then((data) => {
        console.log(data);
        this.errStore.setMessage("Web data collection started");
        this.errStore.setInfo(true);
        this.webdata.push(data.data);
        this.webdata.reverse();
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA Error");
        console.log(error);
      });
  };

  StopWebDataCollection = (id) => {
    DELETE_SDMA(`/api/projects/86b8b6bd42ce110000000900/endpoints/${id}`)
      .then((data) => {
        this.errStore.setMessage("Web data collection stopped");
        this.errStore.setInfo(true);
        for (var i = 0; i < this.webdata.length; i++) {
          if (this.webdata[i].id == id) {
            this.webdata.splice(i, 1);
            break;
          }
        }
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA Error");
        console.log(error);
      });
  };

  StartOperation = (values) => {
    PUT_SDMA(
      `/api/projects/86b8b6bd42ce110000000900/entities/641aff330911fcb4f7c69362`,
      values
    )
      .then((data) => {
        this.errStore.setMessage("Operation started");
        this.errStore.setInfo(true);
        this.field_operation = data.data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA Error");
        console.log(error);
      });
  };

  StopOperation = (values) => {
    PUT_SDMA(
      `/api/projects/86b8b6bd42ce110000000900/entities/641aff330911fcb4f7c69362`,
      values
    )
      .then((data) => {
        this.errStore.setMessage("Operation stopped");
        this.errStore.setInfo(true);
        this.field_operation = data.data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA Error");
        console.log(error);
      });
  };

  // get project
  GetProject = () => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects`)
      .then((data) => {
        this.errStore.setMessage("");
        this.projects = data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // Artefacts
  GetArtefacts = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/artefacts?pageSize=${this.pageSize}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.artefacts = data.data;
        this.transaction = data.data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  GetArtefactsNew = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/artefacts?pageSize=1000`)
      .then((data) => {
        console.log(data);
        // this.errStore.setMessage("");
        // this.artefacts = data.data;
        // this.transaction = data.data;
        // this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetUgvstatus = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/artefacts?type=UGV:Status`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.ugvstatus = data.data.filter((item) => item.type == "UGV:Status");
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  visual_images = [];

  setVisualImages = (value) => {
    this.visual_images = value;
  };

  GetVisualImages = (id, media) => {
    console.log(id, media);
    this.errStore.setLoading(true);
    this.setVisualImages([]);
    // let newImages = [];
    Object.entries(media).map((item) => {
      GET_SDMA_IMAGE(`${constants.SDMA_API.slice(0, -1)}${item[1].raw.url}`)
        .then((data) => {
          var reader = new window.FileReader();
          reader.readAsDataURL(data);
          reader.onload = () => {
            this.errStore.setMessage("");
            this.errStore.setLoading(false);
            this.visual_images.push(reader.result);
          };
        })
        .catch((error) => {
          this.errStore.setError(true);
          this.errStore.setMessage("SDMA ERROR");
          console.log(error);
        });
    });
    // this.setVisualImages(newImages);
  };

  GetRealTimeATDLData = () => {
    this.addressPoints = [];
    console.log("iijiji");

    let fakeData = [
      [54.5566444, -5.94393921, 0.11100059754935386],

      [54.558197, -5.94527674, 0.1532424623676042],

      [54.5574188, -5.94661474, 0.15887469598375176],

      [54.5566444, -5.94527674, 0.17512024786934155],

      [54.5574188, -5.9426012, 0.5028322796044664],

      [54.558197, -5.94393921, 0.518145808605139],

      [54.558197, -5.9426012, 0.5308896210912567],

      [54.5574188, -5.94527674, 1.1214394681322337],

      [54.5574188, -5.94393921, 1.5414862561680944],
    ];

    let fakeDataBuchurest = [
      // old data
      // [44.3455315, 26.1871967, 0.07349225249972123],
      // [44.3455315, 26.1846828, 0.07596376676479141],
      // [44.3464279, 26.1846828, 0.07627056206113514],
      // [44.3482208, 26.1871967, 0.10787064217328034],
      // [44.3500175, 26.1897106, 0.12828691716012422],
      // [44.3455315, 26.1859398, 0.15044415504825265],
      // [44.3473244, 26.1859398, 0.15172739418171055],
      // [44.3464279, 26.1859398, 0.1877976388316093],
      // [44.3464279, 26.1871967, 0.22098943361879006],
      // [44.3473244, 26.1884537, 0.2669650200962005],
      // [44.3473244, 26.1871967, 0.41302431252050825],
      // [44.3491173, 26.1909676, 0.44507867837216236],
      // [44.3482208, 26.1897106, 0.5384875929629138],
      // [44.3482208, 26.1884537, 0.560518819913982],
      // [44.3491173, 26.1897106, 1.1387773231875238],

      // new data

      [44.7244682, 26.1697254, 0.09871289365269119],

      [44.7253685, 26.1722546, 0.10930758531563073],

      [44.7253685, 26.1659298, 0.15066107965651013],

      [44.726265, 26.1684589, 0.20066893806805752],

      [44.7244682, 26.1722546, 0.2343801100069064],

      [44.726265, 26.1659298, 0.360256575467594],

      [44.7253685, 26.1671944, 0.4156580651068647],

      [44.726265, 26.1671944, 0.4281326003952579],

      [44.7253685, 26.17099, 0.5463443199856531],

      [44.7244682, 26.17099, 0.6489608024582645],

      [44.7253685, 26.1684589, 0.7734614740773506],

      [44.7253685, 26.1697254, 0.9774729021511304],
    ];
    this.addressPoints = fakeDataBuchurest;

    // return GET_ATDL_Data(`analysis?preProcess=true`)
    //   .then((data) => {
    //     console.log(data);
    //     let response = data;
    //     this.errStore.setInfo(true);
    //     this.atdl_Data = response;
    //   })
    //   .catch((error) => {
    //     this.errStore.setError(true);
    //     // this.errStore.setMessage("SDMA ERROR");
    //     this.atdlErrorMessage = true;
    //     console.log(error);
    //   });
  };
  setErrorATDL = (value) => {
    this.errorATDL = value;
  };
  GetHistoricalATDLData = () => {
    this.addressPoints = [];
    // this.errorATDL = false; // Reset error state before the request starts
    // this.setErrorATDL(true);
    console.time("Request Time");
    this.addressPoints = [];

    GET_LIVE_ATDL()
      .then((response) => {
        console.timeEnd("Request Time");
        console.log(response);
        this.addressPoints = response;
      })
      .catch((error) => {
        console.timeEnd("Request Time");
        this.errorATDL = true;
        // this.setErrorATDL(true);
        console.log(error);
      });
    this.addressPoints = [];
    // return GET_LIVE_ATDL()
    //   .then((response) => {
    //     console.log(response);
    //     this.addressPoints = response;
    //     this.setErrorATDL(true);
    //   })
    //   .catch((error) => {

    //     console.log(error);
    //   });

    // this.addressPoints = fakeDataBuchurest;

    // return GET_ATDL_Data(`analysis?preProcess=true`)
    //   .then((data) => {
    //     console.log(data);
    //     let response = data;
    //     this.errStore.setInfo(true);
    //     this.atdl_Data = response;
    //   })
    //   .catch((error) => {
    //     this.errStore.setError(true);
    //     // this.errStore.setMessage("SDMA ERROR");
    //     this.atdlErrorMessage = true;
    //     console.log(error);
    //   });
  };

  GetErrorData = () => {
    // console.log("lplpl");
    // const nothing = () => {
    //   return true;
    // };
    // return nothing;
  };

  GetVisualImagesNew = (id, media) => {
    console.log(id, media);
    this.errStore.setLoading(true);
    this.setVisualImages([]);
    Object.entries(media).map((item) => {
      GET_SDMA_IMAGE(`${constants.SDMA_API.slice(0, -1)}${item[1].raw.url}`)
        .then((data) => {
          var reader = new window.FileReader();
          reader.readAsDataURL(data);
          reader.onload = () => {
            this.errStore.setMessage("");
            this.errStore.setLoading(false);
            this.visual_images.push(reader.result);
          };
        })
        .catch((error) => {
          this.errStore.setError(true);
          this.errStore.setMessage("SDMA ERROR");
          console.log(error);
        });
    });
  };

  GetVU = (pId, pageSize) => {
    this.errStore.setLoading(true);
    this.SetUV_data([]);
    GET_SDMA(
      `/api/projects/${pId}/artefacts?type=certh:vu:images&pageSize=${pageSize}`
    )
      .then((data) => {
        data.data.map((item) => {
          GET_SDMA(`/api/projects/${pId}/artefacts/${item.id}`)
            .then((data) => {
              this.errStore.setMessage("");
              this.errStore.setLoading(false);
              this.UV_data.push(data.data);
            })
            .catch((error) => {
              this.errStore.setError(true);
              this.errStore.setMessage("SDMA ERROR");
              console.log(error);
            });
        });
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  //   "UGV:status:"
  // "tas:result:air"
  // "tas:result:water"
  // "chemical:detected"
  // "WaterSensor:Data"

  // artefacts?pageSize=${this.pageSize}`
  //           `api/projects/${this.projectIdForSN}/artefacts?domainId=${this.snDomainId}`
  GetWaterSensorDataNew = (pId) => {
    this.errStore.setLoading(true);

    // 66179504d814970001a990cc
    GET_SDMA(
      `/api/projects/86b8b6bd42ce110000000900/artefacts?type=tas:result:water`
    )
      .then((data) => {
        // console.log(data);
        // var temp = data.data.filter(
        //   (item) => JSON.parse(item.raw.WaterSensor) == "WaterSensor"
        // );
        console.log(data);
        // this.errStore.setMessage("");
        // this.errStore.setLoading(false);
        // this.waterSensorData = data.data;
      })
      .catch((error) => {
        // this.errStore.setError(true);
        // this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetWaterSensorData = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/artefacts?type=WaterSensor:Data`)
      .then((data) => {
        // console.log(data);
        // var temp = data.data.filter(
        //   (item) => JSON.parse(item.raw.WaterSensor) == "WaterSensor"
        // );
        // console.log(temp);
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.waterSensorData = data.data;
      })
      .catch((error) => {
        // this.errStore.setError(true);
        // this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetWaterSensorDataTest = (pId) => {
    this.errStore.setLoading(true);
    console.log(pId);
    GET_SDMA(`/api/projects/${pId}/artefacts?type=chemical:detected`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.waterSensorData = data.data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
      });
  };

  setTextTranslation = (value) => {
    this.textTranslation = value;
  };

  nonHME = [];
  HME = [];
  non = [];

  GetAllPagesFromWebCrawl = (pId, oId) => {
    console.log(oId);
    this.errStore.setLoading(true);
    this.setVisualImages([]);

    GET_SDMA(`/api/projects/${pId}/artefacts?tags=t:operationId:${oId}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        console.log(data.data);
        let newdata = data.data;
        for (let i = 0; i < newdata.length; i++) {
          if (
            this.classification_module.filter(
              (link) => link.sourceDomainId == newdata[i].domainId
            )[0]
          ) {
            var temp = this.classification_module
              .filter((link) => link.sourceDomainId == newdata[i].domainId)[0]
              .targetDomainId.split(":")[2];
            if (temp) {
              newdata[i].classification = temp;
            } else {
              newdata[i].classification = "Unclassified";
            }

            //     if (temp == "non-HME") {
            //       this.nonHME.push(item);
            //     } else if (temp == "HME") {
            //       this.HME.push(item);
            //     } else {
            //       this.non.push(item);
            //     }
          } else {
            newdata[i].classification = "Unclassified";
          }
        }
        console.log(newdata);
        this.setTextTranslation(newdata);
        // this.GetLinksSourceDomainId(pId, newdata[0].domainId);
        // this.GetVisualImagesNew(newdata[0].id, newdata[0].media);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetTransactions = (pId) => {
    this.errStore.setLoading(true);
    // GET_SDMA(`/api/projects/${pId}/artefacts?type=STD_2:Classification`)
    GET_SDMA(
      `/api/projects/86b8b6bd42ce110000000900/artefacts?type=STD_2:Classification`
    )
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        console.log(data.data);
        this.transactions = data.data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // Artefacts
  GetClusteringFromArtefacts = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(
      `/api/projects/${"641da605f611310001fbe67d"}/artefacts?type=ma:clustering&pageSize=${10}`
    )
      .then((data) => {
        this.errStore.setMessage("");
        this.clustering_module = data.data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  GetClusteringFromArtefactsNew = (x) => {
    this.errStore.setLoading(true);

    GET_SDMA(
      `/api/projects/${this.projectIdForClustering}/artefacts?type=ma:clustering&domainId=${x}`
    )
      .then((data) => {
        this.errStore.setMessage("");
        console.log(data.data);
        this.clustering_module = data.data;
        this.setClusterButtonIsDisabled(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  setClusteringModuleEmpty = () => {
    this.clustering_module = [];
  };
  setClusterButtonIsDisabled = (value) => {
    this.clusterButtonIsDisabled = value;
  };
  setLastOperationClusterClicked = (value) => {
    this.lastOperationClusterClicked = value;
  };

  //social network analysis
  GetSocialNetworkAnalysis = (pId) => {
    console.log("SNA2");
    this.errStore.setLoading(true);
    GET_SDMA(
      // `/api/projects/6423f44ccf6a810001866fc5/artefacts?pageSize=${this.pageSize}`

      `api/projects/86b8b6bd42ce110000000900/artefacts?domainId=ma:sna:1712049342730 `
    )
      .then((data) => {
        this.errStore.setMessage("");
        this.social_network_analysis = data.data.filter(
          (item) => item.type == "ma:sna"
        );
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetSocialNetworkAnalysisNew = (pId, taskId) => {
    if (taskId === this.currentOperationId) {
      console.log("SNA3");
      console.log(pId);
      this.trigger = false;
      this.errStore.setLoading(true);
      if (this.snDomainId) {
        GET_SDMA(
          // `/api/projects/6423f44ccf6a810001866fc5/artefacts?pageSize=${this.pageSize}`
          `api/projects/${this.projectIdForSN}/artefacts?domainId=${this.snDomainId}`

          //new social network
          // `api/projects/86b8b6bd42ce110000000900/artefacts?domainId=ma:sna:1712049056454`
          //   `api/projects/86b8b6bd42ce110000000900/artefacts?domainId=ma:sna:1712049342730`
          // `api/projects/86b8b6bd42ce110000000900/artefacts?domainId=ma:sna:1707817667584`
        )
          .then((data) => {
            this.errStore.setMessage("");
            this.trigger = true;
            this.social_network_analysis = data.data.filter(
              (item) => item.type == "ma:sna"
            );
            this.MessageTaskId = taskId;
          })
          .catch((error) => {
            this.errStore.setError(true);
            this.errStore.setMessage("SDMA ERROR");
            console.log(error);
          });
      }
    }
  };

  GetArtefactByID = (id, pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/artefacts/${id}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.artefact = data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  CreateArtefacts = (values, pId) => {
    POST_SDMA(`/api/projects/${pId}/artefacts`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.changeLogItem.push(data);
        this.errStore.handlerCussess("ChangeLogItem Created");
      })
      .catch(this.errStore.handlerErrors);
  };

  uploadArtefacts = (values, pId) => {
    console.log(values, pId);
    let newValues = values.map((value) => {
      let newValue = value;
      if (newValue.raw && typeof newValue.raw !== "string") {
        newValue.raw = JSON.stringify(newValue.raw);
      }
      console.log(newValue);
      return newValue;
    });
    let newArray = newValues.map((obj) => ({
      url: `/api/projects/${pId}/artefacts`,
      value: obj,
    }));

    POST_SDMA_MANY(newArray)
      .then((data) => {
        this.errStore.setMessage("");
        this.changeLogItem.push(data);
        this.errStore.handlerCussess("ChangeLogItem Created");
      })
      .catch(this.errStore.handlerErrors);
  };

  GetArtefactByDomainId = (pId, domaiId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/artefacts?domainId=${domaiId}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        console.log(data);
        this.GetVisualImages(data.data[0].id, data.data[0].media);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // Links
  GetLinksSourceDomainId = (pId, sourceDomainId) => {
    console.log(sourceDomainId);
    this.errStore.setLoading(true);
    GET_SDMA(
      `/api/projects/${pId}/links?sourceDomainId=${sourceDomainId}&type=hasVisualAnalysis`
      // "/api/projects/86b8b6bd42ce110000000900/links?sourceDomainId=web:page:20240319T123955Z:565ddc4c2b2a5d681991dc313f34bb70ebc1c00a&type=hasVisualAnalysis"
      // `/api/projects/${pId}/links?sourceDomainId=${sourceDomainId}&type=hasImage`

      // 86b8b6bd42ce110000000900
    )
      .then((data) => {
        console.log(data);
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        if (data.data) {
          this.GetArtefactByDomainId(pId, data.data[0].targetDomainId);
        }
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // Links
  GetLinks = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/links?pageSize=${this.pageSize}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.links = data;
        this.errStore.setLoading(false);
        this.classification_module = data.data.filter(
          (item) => item.type == "classifiedAs"
        );
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // Links
  GetclassificationModule = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/links?type=classifiedAs`)
      .then((data) => {
        this.errStore.setMessage("");
        this.links = data;
        this.errStore.setLoading(false);
        this.classification_module = data.data;
        console.log(data.data);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetLinkByID = (id, pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/links/${id}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.link = data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  CreateLinks = (values, pId) => {
    POST_SDMA(`/api/projects/${pId}/links`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.links.push(data);
        this.errStore.handlerCussess("ChangeLogItem Created");
      })
      .catch(this.errStore.handlerErrors);
  };

  // Entities
  GetEntities = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/entities?pageSize=${this.pageSize}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.entities = data;
        this.errStore.setLoading(false);
        this.transaction = data.data.filter(
          (item) => item.type == "transactions"
        )[0];
        this.drones = data.data.filter((item) => item.type == "drone");
        this.field_operation = data.data.filter(
          (item) => item.type == "field_operation"
        )[0];
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // get drones
  GetDrones = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/entities?type=drone`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.drones = data.data;
      })
      .catch((error) => {
        console.log(error);
        this.errStore.setError(true);
        this.errStore.setMessage("Drones were not loaded");
      });
  };

  GetEntityByID = (id, pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/entities?tags=t:operationId:${id}`)
      // /entities/${id}
      .then((data) => {
        console.log(data);
        this.errStore.setMessage("");
        this.entity = data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  CreateEntities = (values, pId) => {
    POST_SDMA(`/api/projects/${pId}/entities`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.entities.push(data);
        this.errStore.handlerCussess("ChangeLogItem Created");
      })
      .catch(this.errStore.handlerErrors);
  };
  // audit Items

  GetAuditItems = () => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/audit?pageSize=${this.pageSize}`)
      .then((data) => {
        this.errStore.setMessage("");
        // this.audit_items = data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetAuditItemsById = (id) => {
    GET_SDMA(`/api/auditItems/${id}`)
      .then((data) => {
        this.audit_item = data;
      })
      .catch(this.errStore.handlerErrors);
  };

  // Change Log Items

  CreateChangeLogItem = (values) => {
    POST_SDMA(`/api/changeLogItem`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.changeLogItem.push(data);
        this.errStore.handlerCussess("ChangeLogItem Created");
      })
      .catch(this.errStore.handlerErrors);
  };

  // Access Log Items

  CreateAccessLogItem = (values) => {
    POST_SDMA(`/api/accessLogItem`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.accessLogItem.push(data);
        this.errStore.handlerCussess("AccessLogItem Created");
      })
      .catch(this.errStore.handlerErrors);
  };

  // Change Log Items

  PostKafka = (values) => {
    POST(`/test`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setInfo(true);
        this.errStore.handlerCussess("Message Published");
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("Something went wrong");
      });
  };

  // Publish to kafka

  Publish = (values) => {
    PUBLISH(`/kafka/publish`, values)
      .then((data) => {
        // this.errStore.setMessage(data);
        // this.errStore.setInfo(true);
      })
      .catch(this.errStore.handlerErrors);
  };

  isAnalysing = false;

  setIsAnalyzing = (value) => {
    this.isAnalysing = value;
  };

  isAnalysingFinished = false;

  setIsAnalyzingFinished = (value) => {
    this.isAnalysingFinished = value;
  };

  setAnalyseModal = (value) => {
    this.analyseModal = value;
  };

  textWords = [];

  setTextWords = (value) => {
    this.textWords = value;
  };

  characters = [];

  setCharachters = (value) => {
    if (value[0] === "e" && value[1] === "n" && value[2] === ":") {
      this.characters = value.slice(3);
    } else {
      this.characters = value;
    }

    console.log(this.characters);
  };

  GetAnalyse = async (pId, sourceDomainId) => {
    console.log("analyse-1");
    console.log(sourceDomainId);

    // this.setAnalyseModal(true);
    this.errStore.setLoading(true);
    this.setIsAnalyzing(true);
    this.setAnalyseEntities([]);
    let temp = [];
    this.analyzedCharacters = [];

    this.pieData[0].value = 0;
    this.pieData[1].value = 0;
    this.pieData[2].value = 0;
    this.pieData[3].value = 0;
    this.pieData[4].value = 0;
    this.pieData[5].value = 0;
    this.pieData[6].value = 0;
    this.pieData[7].value = 0;
    this.pieData[8].value = 0;
    this.pieData[9].value = 0;

    GET_SDMA(
      `/api/projects/${pId}/links?sourceDomainId=${sourceDomainId}&type=hasReference`
    )
      .then((data) => {
        console.log("analyse-2");
        console.log(data);

        data.data.map((item) => {
          console.log("analyse-3");
          console.log(item);
          GET_SDMA(
            `/api/projects/${pId}/entities?domainId=${item.targetDomainId}`
          ).then((dataNew) => {
            console.log("analyse-4");
            console.log(dataNew);
            console.log(dataNew.data[0].domainId);
            let range = [];
            if (
              dataNew.data.length > 0 &&
              dataNew.data[0].attributes &&
              dataNew.data[0].attributes.beginsAt &&
              dataNew.data[0].attributes.beginsAt[0]
            ) {
              range = [
                Number(dataNew.data[0].attributes.beginsAt[0]),
                Number(dataNew.data[0].attributes.endsAt[0]),
              ];

              let fakeData = [
                {
                  sourceDomainId: "ner:ref:dQoE9XUJwVMlMBIC76CDP",
                  targetDomainId: "ed:wiki:Koguryo Wooden Bridge",
                  updated: "2024-07-11T10:12:59Z",
                  created: "2024-07-11T07:07:39Z",
                  pipelineIds: [],
                  projectId: "668f819dbe080a000164d13e",
                  id: "668f84bbde93fc1839b85e82",
                  type: "referencesEntity",
                  attributes: {},
                },
                {
                  sourceDomainId: "ner:ref:dQoE9XUJwVMlMBIC76CDP",
                  targetDomainId: "ner:item:wooden",
                  updated: "2024-07-11T10:12:59Z",
                  created: "2024-07-11T07:07:34Z",
                  pipelineIds: [],
                  projectId: "668f819dbe080a000164d13e",
                  id: "668f84b6de93fc1839b85b3e",
                  type: "referencesEntity",
                  attributes: {},
                },
              ];

              GET_SDMA(
                `/api/projects/${pId}/links?sourceDomainId=${dataNew.data[0].domainId}&type=referencesEntity`
              ).then((link) => {
                console.log("analyse-5");
                console.log(link);
                // link.data = fakeData;
                console.log("link.data");
                // console.log(link.data);
                let category = "";
                let categoryWiki = "";
                if (link.data.length > 0) {
                  let foundNer = link.data.find((entity) => {
                    return entity.targetDomainId.includes("ner:");
                  });
                  if (foundNer) {
                    category = foundNer.targetDomainId.split(":")[1];
                  }
                }
                if (link.data.length > 0) {
                  let foundWiki = link.data.find((entity) => {
                    return entity.targetDomainId.includes("ed:wiki");
                  });
                  if (foundWiki) {
                    categoryWiki = foundWiki.targetDomainId.split(":")[2];
                    GET_SDMA(
                      `/api/projects/${pId}/entities?domainId=${foundWiki.targetDomainId}`
                    ).then((response) => {
                      console.log("analyse-6");
                      console.log(response);
                      let wikiLink = response.data[0].attributes.wikiLink[0];

                      if (category === "substance") {
                        this.pieData[0].value++;
                      }
                      if (category === "date") {
                        this.pieData[1].value++;
                      }
                      if (category === "glassware") {
                        this.pieData[2].value++;
                      }
                      if (category === "location") {
                        this.pieData[3].value++;
                      }

                      if (category === "group") {
                        this.pieData[4].value++;
                      }
                      if (category === "item") {
                        this.pieData[5].value++;
                      }
                      if (category === "person") {
                        this.pieData[6].value++;
                      }
                      if (category === "quantity") {
                        this.pieData[7].value++;
                      }
                      if (category === "status") {
                        this.pieData[8].value++;
                      }
                      if (category === "art") {
                        this.pieData[9].value++;
                      }

                      let dataN = {
                        range: range,
                        category: category,
                        wikiLink: wikiLink,
                      };

                      this.analyzedCharacters.push(dataN);
                    });
                  } else {
                    if (category === "substance") {
                      this.pieData[0].value++;
                    }
                    if (category === "date") {
                      this.pieData[1].value++;
                    }
                    if (category === "glassware") {
                      this.pieData[2].value++;
                    }
                    if (category === "location") {
                      this.pieData[3].value++;
                    }

                    if (category === "group") {
                      this.pieData[4].value++;
                    }
                    if (category === "item") {
                      this.pieData[5].value++;
                    }
                    if (category === "person") {
                      this.pieData[6].value++;
                    }
                    if (category === "quantity") {
                      this.pieData[7].value++;
                    }
                    if (category === "status") {
                      this.pieData[8].value++;
                    }
                    if (category === "art") {
                      this.pieData[9].value++;
                    }

                    let dataN = {
                      range: range,
                      category: category,
                      wikiLink: "",
                    };
                    this.analyzedCharacters.push(dataN);
                  }
                }
              });
              // .then((dataN) => {
              //   dataN.wikiLink = "https://en.wikipedia.org/wiki/Acid";
              //   this.analyzedCharacters.push(dataN);
              // });
            }
          });
        });
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
    this.setIsAnalyzing(false);
    this.setIsAnalyzingFinished(true);
  };
  GetPieAnalysis = async (pId, type) => {
    console.log(type);
    // this.setAnalyseModal(true);
    this.errStore.setLoading(true);
    this.setIsAnalyzing(true);
    this.setAnalyseEntities([]);
    let temp = [];
    GET_SDMA(`/api/projects/${pId}/links?sourceDomainId=${type}`)
      .then((data) => {
        // this.errStore.setMessage("");
        // this.errStore.setLoading(false);
        console.log(data);
        console.log("558");
        data.data.map((item) => {
          if (
            this.mapping.filter((data) =>
              item.targetDomainId.includes(data.type1)
            ).length > 0
          ) {
            let request_type = this.mapping.filter((data) =>
              item.targetDomainId.includes(data.type1)
            )[0].type2;
            console.log(request_type);
            GET_SDMA(
              `/api/projects/${pId}/entities?type=${request_type}&domainId=${item.targetDomainId}`
            )
              .then((data) => {
                // this.errStore.setMessage("");
                // this.errStore.setLoading(false);
                if (data.data != []) {
                  let word = {
                    text: data.data[0].title,
                    type: data.data[0].type,
                    color: this.mapping.filter(
                      (item) => item.type2 == data.data[0].type
                    )[0].color,
                  };
                  temp.push(word);
                  this.analyse_entities.push(word);
                  // this.setAnalyseEntities(temp);
                  this.setPieData();
                }
              })
              .catch((error) => {
                // this.errStore.setError(true);
                // this.errStore.setMessage("SDMA ERROR");
                console.log(error);
              });
          }
        });
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
    this.setIsAnalyzing(false);
    this.setIsAnalyzingFinished(true);
  };

  //     examples   NER and COREF
  //     in projectId= 662a4a3ed814970001a99c3a
  //      and the two domainIds are "web:page:synth:blissfulOtter8" and "web:page:synth:mercifulIcecream3".
  //      For example you can retrieve the links via :
  // https://sdms.odysseus-online.rid-intrasoft.eu/api/projects/662a4a3ed814970001a99c3a/links?sourceDomainId=web:page:synth:blissfulOtter8&type=hasReference
  // https://sdms.odysseus-online.rid-intrasoft.eu/api/projects/662a4a3ed814970001a99c3a/links?sourceDomainId=web:page:synth:blissfulOtter8&type=containsEntity

  GetConfer = async (pId, type) => {
    this.showGroups = false;
    this.blueGroup = [];
    this.orangeGroup = [];
    this.redGroup = [];
    this.purpleGroup = [];
    this.limeGroup = [];
    this.greenGroup = [];
    this.cyanGroup = [];
    this.yellowGroup = [];
    console.log(type);
    // this.setAnalyseModal(true);
    this.errStore.setLoading(true);
    this.setIsAnalyzing(true);
    // this.setAnalyseEntities([]);
    let temp = [];
    // 662a4a3ed814970001a99c3a

    function removeDuplicateArrays(arr) {
      let uniqueArrays = [];
      let seen = new Set();

      for (let subArray of arr) {
        let stringified = JSON.stringify(subArray);
        if (!seen.has(stringified)) {
          uniqueArrays.push(subArray);
          seen.add(stringified);
        }
      }

      return uniqueArrays;
    }

    GET_SDMA(
      `/api/projects/${pId}/links?sourceDomainId=${type}&type=hasReference`
    ).then((data) => {
      console.log(data.data);
    });

    GET_SDMA(
      `/api/projects/${pId}/links?sourceDomainId=${type}&type=containsEntity`
    )
      // GET_SDMA(
      //   `/api/projects/662ea9afd814970001a9a09d/links?sourceDomainId=youtube:post:INf4hLJyDoI`
      // )

      .then((data) => {
        console.log(data.data);

        let rangesU = [];
        data.data.map((item) => {
          //   if (
          //     this.mapping.filter((data) => data.type1 == item.type).length > 0
          //   ) {
          //     let request_type = this.mapping.filter(
          //       (data) => data.type1 == item.type
          //     )[0].type2;
          GET_SDMA(
            `/api/projects/${pId}/entities?type=ner:coref&domainId=${item.targetDomainId}`
          ).then((dataNew) => {
            // let rangesU = [];
            console.log(dataNew);
            console.log(dataNew.data);
            console.log(data.data.length > 0);
            // console.log(data.data[0].name.includes("confer"));

            if (
              dataNew.data &&
              dataNew.data.length === 1 &&
              dataNew.data[0].name
              // &&
              // dataNew.data[0].name.includes("confer")
            ) {
              console.log("ioioio");
              console.log(dataNew.data[0].attributes);
              console.log(dataNew.data[0].attributes);
              let beginArray = dataNew.data[0].attributes.beginsAt.map(
                (item) => {
                  return Number(item);
                }
              );
              beginArray.sort((a, b) => a - b);

              let endArray = dataNew.data[0].attributes.endsAt.map((item) => {
                return Number(item);
              });
              endArray.sort((a, b) => a - b);
              console.log(beginArray);
              let newArray = [];
              for (let y = 0; y < beginArray.length; y++) {
                newArray.push([beginArray[y], endArray[y]]);
                console.log(newArray);
              }

              console.log(newArray);
              rangesU.push(newArray);

              // let singleArrays =
              // this.blueGroup = newArray;
              console.log(rangesU);

              let newRanges = [];
              let aaa = [...rangesU];
              console.log(aaa);
              newRanges = removeDuplicateArrays(aaa);

              if (newRanges.length > 0) {
                this.showGroups = true;
                if (newRanges[0]) {
                  this.blueGroup = newRanges[0];
                  console.log(this.blueGroup);
                }
                if (newRanges[1]) {
                  this.orangeGroup = newRanges[1];
                  console.log(this.orangeGroup);
                }
                if (newRanges[2]) {
                  this.redGroup = newRanges[2];
                  console.log(this.redGroup);
                }
                if (newRanges[3]) {
                  this.purpleGroup = newRanges[3];
                  console.log(this.purpleGroup);
                }
                if (newRanges[4]) {
                  this.limeGroup = newRanges[4];
                }
                if (newRanges[5]) {
                  this.greenGroup = newRanges[5];
                }
                if (newRanges[6]) {
                  this.cyanGroup = newRanges[6];
                }
                if (newRanges[7]) {
                  this.yellowGroup = newRanges[7];
                }
              }
              console.log(newRanges);
            } else {
              this.showGroups = true;
            }
          });
        });
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
    this.setIsAnalyzing(false);
    this.setIsAnalyzingFinished(true);
  };

  GetAnalyseEntities = (type) => {
    this.errStore.setLoading(true);
    GET_SDMA(
      `/api/projects/${"86b8b6bd42ce110000000900"}/entities?sourceDomainId=${type}`
    )
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.setAnalyseEntities(data.data);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
      });
  };

  StoreData = (type, data) => {
    let values = {
      type: "",
      tags: [],
      domainId: "",
      attributes: {
        additionalProp1: [],
        additionalProp2: [],
        additionalProp3: [],
      },
    };
    this.errStore.setLoading(true);
    values.type = type;
    values.tags = [];
    values.domainId = uuidv4();
    values.attributes = {
      additionalProp1: [data],
      additionalProp2: [],
      additionalProp3: [],
    };

    POST_SDMA(`/api/projects/86b8b6bd42ce110000000900/entities`, values)
      .then((data) => {
        this.errStore.setMessage("Data Stored");
        this.errStore.setInfo(true);
        this.GetStoredMissions();
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA Error");
      });
  };

  GetStoredMissions = () => {
    GET_SDMA(`/api/projects/86b8b6bd42ce110000000900/entities?type=mission`)
      .then((data) => {
        console.log(data);
        let temp = [];
        data.data.map((item) => {
          temp.push(JSON.parse(item.attributes.additionalProp1[0]));
        });
        let temp2 = [];
        temp.map((item) => {
          item.map((value) => {
            temp2.push(value);
          });
        });
        this.setStoredMissions(temp2);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
      });
  };

  // missions
  DeleteMission = (id) => {
    this.setIsItemDeleting(true);
    DELETE_SDMA(`api/projects/86b8b6bd42ce110000000900/entities/${id}`)
      .then((data) => {
        this.GetTotalMissions();
      })
      .catch((error) => {
        this.setIsItemDeleting(false);
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  total_missions = [];

  setTotalMission = (value) => {
    this.total_missions = value;
  };
  SetNotificationCoordinates = (coords) => {
    this.coords = coords;
  };

  AddOperationToMission = (values) => {
    values.active = "false";
    this.pickedMission.attributes.additionalProp2.push(JSON.stringify(values));
    console.log(this.pickedMission);
    PUT_SDMA(
      `/api/projects/86b8b6bd42ce110000000900/entities/${this.pickedMission.id}`,
      this.pickedMission
    )
      .then((data) => {
        this.setIsOperationModalOpen(false);
        this.GetTotalMissions();
      })
      .catch((error) => {
        this.setIsDroneCreating(false);
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA Error");
        console.log(error);
      });
  };

  GetTotalMissions = () => {
    GET_SDMA(
      `/api/projects/86b8b6bd42ce110000000900/entities?type=total_mission`
    )
      .then((data) => {
        console.log(data);
        this.setTotalMission(data.data);

        // let temp = [];
        // data.data.map((item) => {
        //   temp.push(JSON.parse(item.attributes.additionalProp1[0]));
        // });
        // console.log(temp)
        // this.setTotalMission(temp);

        // let temp2 = [];
        // temp.map((item) => {
        //   item.map((value) => {
        //     temp2.push(value);
        //   });
        // });
        // this.setTotalMission(temp2);
        // console.log(temp2)
        this.setIsMissionModalOpen(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
      });
  };

  GetTest = (id, pId) => {
    GET_SDMA(
      `https://sdms.odysseus-online.rid-intrasoft.eu/api/projects/86b8b6bd42ce110000000900/entities?type=ner:chemical`
    )
      .then((data) => {})
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
      });
  };
}

export default MainStore;
