import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  Divider,
  DatePicker,
  Space,
  Select,
} from "antd";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";
import moment from "moment";
import TransactionsETD from "./TransactionsETD";

const { Option } = Select;
var _ = require("lodash");

const SearchFormETD = (props) => {
  const { mainStore, transactionsStore } = useMobxStores();

  const [recipes_options, setRecipes_options] = useState([]);

  const [form] = Form.useForm();

  // Set initial value for a specific field
  useEffect(() => {
    if (mainStore.coords) {
      form.setFieldsValue({
        start_date: moment(),
        end_date: moment(),
        lat: mainStore.coords.lat, // Initial value for the "lat" field
        lon: mainStore.coords.lon,
      });
    }
  }, []);
  useEffect(() => {
    if (mainStore.coords) {
      form.setFieldsValue({
        start_date: moment(),
        end_date: moment(),
        lat: mainStore.coords.lat, // Initial value for the "lat" field
        lon: mainStore.coords.lon,
      });
      mainStore.SetNotificationCoordinates("");
    }
  }, [mainStore.coords]);
  // }, [mainStore.coords]);

  useEffect(() => {
    transactionsStore.GetRecipes();
  }, []);

  useEffect(() => {
    console.log(mainStore.transactionsETD);
  }, [mainStore.transactionsETD]);
  useEffect(() => {
    setRecipes_options(transactionsStore.Recipes);
  }, [transactionsStore.Recipes]);

  const onFinish = (values) => {
    mainStore.setIsETDSearching(true);
    let send = {
      header: {
        topicName: "ETD_CLUSTERING_REQUEST",
        missionId: "321321",
        operationId: "321321",
        caseId: "123132",
        sender: "INTRA",
        sentUtc: moment().format("yyyy-MM-dd'T'HH:mm:ss'Z'"),
        source: "Dashboard",
      },
      body: {
        criteria: {
          recipes: [values.recipes] || "",
          dateTime: {
            from: values.start_date.format("DD-MM-YYYY") || "",
            to: values.end_date.format("DD-MM-YYYY") || "",
          },
          geo: {
            lat: values.lat || "",
            long: values.lon || "",
            threshold: values.threshold || "",
          },
        },
      },
    };

    mainStore.Publish({
      topics: ["ETD_CLUSTERING_REQUEST"],
      body: JSON.stringify(send),
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // const recipes_options = [
  //   { name: "tatp", value: "tatp" },
  //   { name: "tnt", value: "tnt" },
  //   { name: "rdx", value: "rdx" },
  //   { name: "flash powder", value: "flash powder" },
  //   { name: "hmtd", value: "hmtd" },
  //   { name: "black powder", value: "black powder" },
  //   { name: "mercury fulminate", value: "mercury fulminate" },
  //   { name: "anfo_hydrazine", value: "anfo_hydrazine" },
  //   { name: "anfo", value: "anfo" },
  // ];

  return (
    <div>
      {/* <Divider>Search Transactions ETD</Divider> */}
      <Row
        justify="center"
        align="center"
        style={{
          display: "flex",
          justifyContent: "center" /* Horizontal centering */,
          alignItems: "center" /* Vertical centering */,
          // height: 100vh;            /* Full viewport height (or set a specific height) */
          // border: 1px solid black;
        }}
      >
        <Col span={22} md={22} lg={22} xs={24} sm={24} xxl={22}>
          <Row
            justify="center"
            align="center"
            style={{
              display: "flex",
              justifyContent: "center" /* Horizontal centering */,
              alignItems: "center" /* Vertical centering */,
              // height: 100vh;            /* Full viewport height (or set a specific height) */
              // border: 1px solid black;
            }}
          >
            <Form
              form={form}
              title="Search ETD"
              name="search-form-etd"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
              style={{
                width: "100%",
              }}
            >
              <Row gutter={[20, 20]}>
                <Col span={3}>
                  <Form.Item
                    style={{
                      width: "100%",
                    }}
                    name="start_date"
                    rules={[
                      {
                        required: true,
                        message: "Please input date!",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{
                        width: "100%",
                      }}
                      placeholder="Start Date"
                      format={"DD/MM/YYYY"}
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item
                    style={{
                      width: "100%",
                    }}
                    name="end_date"
                    rules={[
                      {
                        required: true,
                        message: "Please input date!",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{
                        width: "100%",
                      }}
                      placeholder="End Date"
                      format={"DD/MM/YYYY"}
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item
                    name="lat"
                    rules={[
                      {
                        required: false,
                        message: "Please input lat!",
                      },
                    ]}
                    value={2}
                  >
                    <Input placeholder="Latitude" />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item
                    name="lon"
                    value="3"
                    rules={[
                      {
                        required: false,
                        message: "Please input lon!",
                      },
                    ]}
                  >
                    <Input placeholder="Longitude" />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item
                    name="threshold"
                    rules={[
                      {
                        required: false,
                        message: "Please input threshold!",
                      },
                    ]}
                  >
                    <Input placeholder="Threshold" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="recipes"
                    rules={[
                      {
                        required: false,
                        message: "Please input threshold!",
                      },
                    ]}
                  >
                    <Select
                      style={{
                        width: "100%",
                      }}
                      placeholder={"recipes_options"}
                      width={"auto"}
                      // style={{ width: "auto" }}
                      popupMatchSelectWidth={false}
                    >
                      {recipes_options.map((item, index) => {
                        return <Option key={index} value={item.name}></Option>;
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item
                    wrapperCol={{
                      offset: 8,
                      span: 16,
                    }}
                  >
                    <Button
                      loading={mainStore.isETDSearching}
                      type="primary"
                      htmlType="submit"
                    >
                      Search ETD
                    </Button>
                  </Form.Item>
                </Col>
              </Row>

              <Space direction="horizontal" style={{ width: "100%" }}></Space>
            </Form>{" "}
          </Row>
          <TransactionsETD
            onClick={(data) => {
              props.onClick(data);
            }}
          />
        </Col>
      </Row>
    </div>
  );
};
export default observer(SearchFormETD);
