import React, { useState, useEffect } from "react";
import { Col, Row, Divider, Card } from "antd";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import Chart from "react-apexcharts";
import EmptyData from "../emptydata/EmptyData";
var _ = require("lodash");

const AirSensorPeaks = (props) => {
  const { mainStore } = useMobxStores();
  const [x1, setAcetone] = useState(mainStore.acetone);
  const [x2, setToluene] = useState(mainStore.toluene);
  const [x3, setx3] = useState(mainStore.x3);
  const [seriesReactive, setSeriesReactive] = useState([]);
  const [y, sety] = useState(mainStore.y);
  useEffect(() => {
    setx3(mainStore.x3);
  }, [mainStore.x3]);

  let colorsCategories = ["#f57c00", "#31438e", "#66BB6A", "#E53935"];

  return (
    <Row justify="center" align="center" style={{ marginTop: "2vh" }}>
      <Col span={24} lg={24} xs={24} sm={24}>
        <Divider style={{ marginTop: "-12px", marginBottom: "-2px" }}>
          {" "}
          Air Sensor peaks
        </Divider>
        {y.length != 0 ? (
          <Card>
            <Chart
              options={{
                dataLabels: {
                  enabled: false,
                },
                legend: {
                  position: "left",
                },
                chart: {
                  id: "basic-bar",
                },
                xaxis: {
                  type: "datetime",
                  categories: _.cloneDeep(y),
                  // categories: y,
                  labels: {
                    style: {
                      colors: "#f57c00",
                    },
                    datetimeFormatter: {
                      year: "yyyy",
                      month: "MMM 'yy",
                      day: "dd MMM",
                      hour: "HH:mm",
                    },
                  },
                  title: {
                    text: "Time ",
                  },
                },
                yaxis: {
                  max: 3000,
                  title: {
                    rotate: 0,
                    text: "mV",
                    // offsetX: -60,
                  },
                  categories: ["1", "1"],
                  labels: {
                    style: {
                      colors: "#f57c00",
                    },
                  },
                },
                colors: ["#f57c00", "#314881"],
              }}
              series={
                x3
                //   [
                //   {
                //     name: "Chemical signal (mV) - Acetone",
                //     data: x,
                //   },
                // ]
              }
              type="scatter"
              // type="area"
              style={{
                color: "#f57c00",
                width: "65vw",
                minWidth: "65vw",
                minHeight: "300px",
              }}
              height={300}
              // minWidth={700}
            />
          </Card>
        ) : (
          <EmptyData />
        )}
      </Col>
    </Row>
  );
};

export default observer(AirSensorPeaks);
