import { makeAutoObservable } from "mobx";
import {
  POST,
  PUBLISH,
  GET_SDMA,
  POST_SDMA,
  DELETE_SDMA,
  PUT_SDMA,
  GET_SDMA_IMAGE,
  GET_SDMA_MANY,
} from "../apis/apis";

class MissionsStore {
  constructor({ errStore }) {
    makeAutoObservable(this);
    this.errStore = errStore;
  }
  missionsWithTransactions = [];
  missions = [];
  operationPicked = {
    name: "",
    attributes: { operationId: [] },
    created: "",
  };
  isOperationModalOpen = false;
  isImportOperationModalOpen = false;
  isMissionModalOpen = false;
  pid = null;
  pickedMission = { name: "", id: "", created: "" };
  operationsOfPickedMission = [];
  operationsIdsOfPickedMission = [];

  setOperationsOfPickedMission = (value) => {
    this.operationsOfPickedMission = value;
    this.operationsIdsOfPickedMission = value.map((op) => {
      return op.attributes.operationId[0];
    });
  };

  setPickedMission = (value) => {
    this.pickedMission = value;
  };
  setOperationPicked = (value) => {
    console.log(value);
    this.operationPicked = value;
  };

  setPid = (value) => {
    this.pid = value;
  };

  setIsOperationModalOpen = (value) => {
    this.isOperationModalOpen = value;
  };
  setIsImportOperationModalOpen = (value) => {
    this.isImportOperationModalOpen = value;
  };

  setIsMissionModalOpen = (value) => {
    this.isMissionModalOpen = value;
  };

  setMissions = (value) => {
    this.missions = value.sort(
      (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
    );
  };

  setMissionsWithTransactions = (value) => {
    this.missionsWithTransactions = value.sort(
      (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
    );
    console.log(this.missionsWithTransactions);
  };

  updateMissionContainsTrans = (pId, value) => {
    GET_SDMA(`/api/projects/${pId}`)
      // GET_SDMA(`/api/projects/${pid}/entities`)
      .then((data) => {
        let oldData = data.data;
        oldData.tags = ["containsTransaction"];
        // oldData.attributes.containsTransaction = [value];
        POST_SDMA(`/api/projects/${pId}`, oldData)
          .then((data) => {
            console.log(data);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  CreateMission = (values) => {
    this.errStore.setLoading(true);
    POST_SDMA(`/api/projects`, values)
      .then((data) => {
        console.log(data);
        this.GetMissions();
        this.setIsMissionModalOpen(false);
        this.errStore.setInfo(true);
        this.errStore.setMessage("Mission Created!");
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetMissions = () => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects`)
      .then((data) => {
        this.errStore.setLoading(false);
        this.setMissions(data.data);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  GetMissionsWithTransactions = () => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects`)
      .then((data) => {
        this.errStore.setLoading(false);
        let allMissions = data.data;
        let allMissionsids = allMissions.map((mission) => {
          return `/api/projects/${mission.id}/entities?type=transaction analysis`;
        });
        GET_SDMA_MANY(allMissionsids).then((response) => {
          console.log("allMissionsids");
          console.log(response);
          let missionsWithTranasactions = response.filter((transaction) => {
            return transaction.data.data.length > 0;
          });
          let missionsIdsWithTranasactions = missionsWithTranasactions.map(
            (mission) => {
              return mission.data.data[0].projectId;
            }
          );
          console.log(missionsIdsWithTranasactions);
          let filteredMissions = allMissions.filter((mission) => {
            return missionsIdsWithTranasactions.includes(mission.id);
          });
          this.setMissionsWithTransactions(filteredMissions);
        });
        // this.setMissionsWithTransactions(data.data);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetMissionsById = (pid) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pid}`)
      // GET_SDMA(`/api/projects/${pid}/entities`)
      .then((data) => {
        this.errStore.setLoading(false);
        console.log(data.data);
        this.setPickedMission(data.data);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetOperationPicked = (pid, operationId) => {
    this.errStore.setLoading(true);
    //  GET_SDMA(`/api/projects/${pid}/entities`)
    // GET_SDMA(`/api/projects/${pid}/entities?type=web_crawl`)
    GET_SDMA(
      `/api/projects/${pid}/entities?type=field&type=web_crawl&type=transaction analysis`
    )
      // /api/projects/86b8b6bd42ce110000000900/entities?tags=t:operationId:operation_id_test
      .then((data) => {
        this.errStore.setLoading(false);
        console.log("operationId");
        console.log(data);
        console.log(operationId);
        let found = data.data.find((operation) => {
          return operation.attributes.operationId[0] === operationId;
        });
        console.log(found);
        this.setOperationPicked(found);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  FindMissionTransactions = (pid) => {
    this.errStore.setLoading(true);

    return GET_SDMA(`/api/projects/${pid}/entities?type=transaction analysis`)
      .then((data) => {
        this.errStore.setLoading(false);
        // console.log("operationId");
        console.log(data.data);
        let transactions = data.data;

        if (transactions.length > 0) {
          let transactionsSorted = transactions.sort(
            (a, b) =>
              new Date(b.created).getTime() - new Date(a.created).getTime()
          );
          return transactionsSorted[0].attributes.operationId[0];
        } else {
          return transactions[0].attributes.operationId[0];
        }
        // console.log(operationId);
        // let found = data.data.find((operation) => {
        //   return operation.attributes.operationId[0] === operationId;
        // });
        // console.log(found);
        // this.setOperationPicked(found);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetMissionOperations = (pid) => {
    this.errStore.setLoading(true);
    //  GET_SDMA(`/api/projects/${pid}/entities`)
    // GET_SDMA(`/api/projects/${pid}/entities?type=web_crawl`)
    GET_SDMA(
      `/api/projects/${pid}/entities?type=field&type=web_crawl&type=transaction analysis`
      //   `/api/projects/${pid}/entities?tags=t:operationId:dea6defb-d02a-4087-aed1-1791f8231258`
    )
      // /api/projects/86b8b6bd42ce110000000900/entities?tags=t:operationId:operation_id_test
      .then((data) => {
        this.errStore.setLoading(false);
        console.log(data.data);
        this.setOperationsOfPickedMission(data.data);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  DeleteMission = (id) => {
    this.errStore.setLoading(true);
    DELETE_SDMA(`/api/projects/${id}`)
      .then((data) => {
        this.errStore.setLoading(false);
        this.GetMissions();
        this.errStore.setInfo(true);
        this.errStore.setMessage("Mission Deleted!");
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  AddOperationToMission = (values) => {
    this.errStore.setLoading(true);
    return POST_SDMA(`/api/projects/${this.pid}/entities`, values)
      .then((data) => {
        console.log(data);
        this.setIsOperationModalOpen(false);
        this.errStore.setInfo(true);
        this.errStore.setMessage("Operation added to mission!");
        this.GetMissionOperations(this.pid);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // AddOperationToMission = (values) => {
  //   this.errStore.setLoading(true);
  //   return POST_SDMA(`/api/projects/${this.pid}/entities`, values)
  //     .then((data) => {
  //       console.log(data);
  //       this.setIsOperationModalOpen(false);
  //       this.errStore.setInfo(true);
  //       this.errStore.setMessage("Operation added to mission!");
  //       this.GetMissionOperations(this.pid);
  //     })
  //     .catch((error) => {
  //       this.errStore.setError(true);
  //       this.errStore.setMessage("SDMA ERROR");
  //       console.log(error);
  //     });
  // };

  StartWebDataCollection = (values) => {
    POST_SDMA(`/api/projects/${this.pid}/endpoints`, values)
      //   POST_SDMA(`/api/projects/${values.projectId}/endpoints`, values)
      .then((data) => {
        this.errStore.setMessage("Web data collection started");
        this.errStore.setInfo(true);
        this.webdata.push(data.data);
        this.webdata.reverse();
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA Error");
        console.log(error);
      });
  };
}

export default MissionsStore;
