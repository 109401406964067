import React, { useState, useEffect } from "react";
import { Row, Select, Button } from "antd";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";
import AIDMapHeat from "./AIDMapHeat";
import { useParams } from "react-router-dom";

const { Option } = Select;

const TransactionsETDRealTime = (props) => {
  const { mainStore, transactionsStore } = useMobxStores();
  let params = useParams();
  // Initialize state as empty arrays
  const [recipes_options, setRecipes_options] = useState([]);
  const [ingredients_options, setIngredients_options] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState("");
  const [selectedIngredient, setSelectedIngredient] = useState("");

  // Fetch recipes and ingredients on mount
  useEffect(() => {
    transactionsStore.GetRecipes();
    transactionsStore.GetIngredients();
  }, [transactionsStore]);

  // Update options when data changes
  useEffect(() => {
    if (Array.isArray(transactionsStore.Recipes)) {
      const newRecipes = transactionsStore.Recipes.map((recipe) => ({
        name: recipe.name,
        value: transactionsStore.transactionsETDRealTime[recipe.name],
      }));
      newRecipes.unshift({ name: "Select", value: "" });
      setRecipes_options(newRecipes);
    }

    if (Array.isArray(transactionsStore.Ingredients)) {
      const newIngredients = transactionsStore.Ingredients.map(
        (ingredient) => ({
          name: ingredient.name,
          value: transactionsStore.transactionsETDRealTime[ingredient.name],
        })
      );
      newIngredients.unshift({ name: "Select", value: "" });
      setIngredients_options(newIngredients);
    }
  }, [transactionsStore.Recipes, transactionsStore.Ingredients]);

  // Handle recipe change
  const changeRecipe = (recipe) => {
    let selected = recipes_options.find((option) => option.name === recipe);
    setSelectedRecipe(selected);

    // Reset ingredient selection
    setSelectedIngredient("");
  };

  const sendMessage = () => {
    console.log("ijno");
    let recipeName = "";
    let ingredientName = "";
    if (selectedRecipe) {
      recipeName = selectedRecipe.name;
      ingredientName = "";
    }
    if (selectedIngredient) {
      recipeName = "";
      ingredientName = selectedIngredient.name;
    }

    let send = {
      header: {
        topicName: "AID_CLUSTERING_REQUEST",
        missionId: params.pk,
        operationId: params.id,
        caseId: params.pk,
        sender: "INTRA",
        sentUtc: "2023-12-24 08:43:32.955820+00:00",
        source: "Dashboard",
      },
      body: {
        criteria: {
          recipe: [recipeName],
          ingredient: [ingredientName],
          dateTime: { from: "01-01-2017", to: "01-06-2024" },
          geo: { lat: "", long: "", threshold: "" },
        },
      },
    };

    mainStore.Publish({
      topics: ["AID_CLUSTERING_REQUEST"],
      body: JSON.stringify(send),
    });
  };

  // Handle ingredient change
  const changeIngredients = (ingredient) => {
    let selected = ingredients_options.find(
      (option) => option.name === ingredient
    );
    setSelectedIngredient(selected);
    // Reset recipe selection
    setSelectedRecipe("");
  };

  return (
    <div>
      <Row justify="start" align="start">
        <Select
          placeholder="Select Recipe"
          value={selectedRecipe?.name || undefined}
          style={{ width: "auto", marginBottom: "12px", marginLeft: "24px" }}
          onChange={changeRecipe}
        >
          {/* Check if recipes_options is an array before mapping */}
          {Array.isArray(recipes_options) &&
            recipes_options.map((item, index) => (
              <Option key={index} value={item.name}>
                {item.name}
              </Option>
            ))}
        </Select>

        <Select
          placeholder="Select Ingredient"
          value={selectedIngredient?.name || undefined}
          style={{ width: "auto", marginBottom: "12px", marginLeft: "20px" }}
          onChange={changeIngredients}
        >
          {/* Check if ingredients_options is an array before mapping */}
          {Array.isArray(ingredients_options) &&
            ingredients_options.map((item, index) => (
              <Option key={index} value={item.name}>
                {item.name}
              </Option>
            ))}
        </Select>
        <span>
          <Button
            disabled={
              (!selectedRecipe || selectedRecipe.name === "Select") &&
              (!selectedIngredient || selectedIngredient.name === "Select")
            }
            onClick={sendMessage}
            style={{ width: "auto", marginLeft: "20px" }}
          >
            Search
          </Button>
        </span>
      </Row>
      <Row
        style={{
          display: "flex",
          justifyContent: "center" /* Horizontal centering */,
          alignItems: "center" /* Vertical centering */,
          // height: 100vh;            /* Full viewport height (or set a specific height) */
          // border: 1px solid black;
        }}
      >
        <AIDMapHeat />
      </Row>
    </div>
  );
};

export default observer(TransactionsETDRealTime);
