import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";
import axios from "axios";
import { Card, Button } from "antd";
import Icon from "@mdi/react";
import { mdiReceiptTextOutline } from "@mdi/js";
import { constants } from "../../consts/Variables";

const mapStyle = {
  height: "400px",
  width: "900px",
  //  borderRadius: "15px"
};

const customOrange = new L.icon({
  iconUrl: ".../../../orange-pin.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

const customMarker = new L.icon({
  iconUrl: "../../../blue-pin.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

const customRed = new L.icon({
  iconUrl: "../../../red-pin.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

const ETDMap = (props) => {
  const { mainStore } = useMobxStores();
  const [maxBounds, setMaxBounds] = useState(null);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    axios
      .get(
        "https://cdn.rawgit.com/johan/world.geo.json/34c96bba/countries/GRC.geo.json"
      )
      .then((response) => {
        const geoJSON = response.data;
        setMaxBounds(L.geoJSON(geoJSON).getBounds());
      });
    // console.log(mainStore.ETDCoords);
  }, [setMaxBounds, mainStore.ETDCoords]);

  return maxBounds ? (
    <div id="map">
      <MapContainer
        zoom={3}
        minZoom={1}
        scrollWheelZoom={true}
        // maxBounds={maxBounds}
        style={mapStyle}
        center={[52.3555, 1.1743]}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url={
            constants.ONLINE == "true"
              ? "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              : `https://map.romania.odysseus-offline.rid-intrasoft.eu/tile/{z}/{x}/{y}.png`
          }
          // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {mainStore.ETDCoords != null &&
          mainStore.ETDCoords.map((item, index) => {
            return (
              <Marker
                key={index}
                position={[item.coords.lat, item.coords.lon]}
                icon={
                  item.properties.confidence < 0.3
                    ? customMarker
                    : item.properties.confidence <= 0.6
                    ? customOrange
                    : customRed
                }
              >
                <Popup
                  maxWidth="auto"
                  maxHeight="auto"
                  style={{ width: "auto" }}
                >
                  <Card
                    title={`Cluster ${item.id} (${item.recipe})`}
                    style={{
                      width: "auto",
                      minWidth: "auto",
                      textAlign: "left",
                    }}
                    bodyStyle={{
                      width: "auto",
                      textAlign: "left",
                      overflow: "scroll",
                      maxHeight: "60vh",
                      height: "auto",
                    }}
                  >
                    {` Confidence: ${item.properties.confidence}`}
                    <br />
                    <Button
                      type="default"
                      // onClick={() => showSTDItemsModal(item)}
                      // onClick={this.handleClick}
                      onClick={() => props.onShowModalFromMap(item)}
                      size="small"
                      style={{
                        backgroundColor: "#354289",
                        color: "white",
                        display: "flex", // Use flexbox
                        alignItems: "center", // Align items vertically
                        gap: "4px", // Add gap between icon and text
                        paddingLeft: "8px", // Add left padding for icon alignment
                        paddingRight: "8px", // Add right padding for icon alignment
                        height: "26px",
                        marginLeft: "-2px",
                      }}
                    >
                      <Icon path={mdiReceiptTextOutline} size={1} />
                      <div style={{ marginLeft: "-2px" }}>Details</div>
                    </Button>
                    {/* <a href={item.properties.detailsLink}>Details</a> */}
                  </Card>
                </Popup>
              </Marker>
            );
          })}
      </MapContainer>
    </div>
  ) : (
    <></>
  );
};

export default observer(ETDMap);
