import React from "react";
import { Col, Row, Divider, Tag, Button } from "antd";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";

const Profile = (props) => {
  let user = window.localStorage.getItem("user");
  user = JSON.parse(user);

  const { usersStore, mainStore } = useMobxStores();
  const handleButtonClick = () => {
    mainStore.goToKeycloak();
    // window.open("https://www.in.gr", "_blank");
  };

  return (
    <Row justify="center" align="center" className="full-viewport">
      <Col span={8} lg={8} xs={24} sm={24}>
        <Divider>User Profile</Divider>
        <p>
          <b>Username:</b> {user.username}
        </p>
        <p>
          <b>ID:</b> {user.id}
        </p>
        <p>
          <b>Email Verified:</b>{" "}
          {user.emailVerified ? (
            <Tag color="green">Yes</Tag>
          ) : (
            <Tag color="red">no</Tag>
          )}
        </p>
        {usersStore.currentUser &&
          usersStore.currentUser.type &&
          usersStore.currentUser.type.includes("platform_administrator") && (
            <p>
              <Button onClick={handleButtonClick}>
                {" "}
                Go to Keycloak Admin Console{" "}
              </Button>
            </p>
          )}
      </Col>
    </Row>
  );
};

export default observer(Profile);
