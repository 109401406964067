import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";
import axios from "axios";
import _ from "lodash";
import EmptyData from "../emptydata/EmptyData";
import { constants } from "../../consts/Variables";

const mapStyle = { minHeight: "400px", width: "auto", borderRadius: "15px" };

const customMarkerAir = new L.icon({
  iconUrl: "../../../../../orange-pin.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

const customMarkerWater = new L.icon({
  iconUrl: "../../../../../blue-pin.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

const customMarkerNtdl = new L.icon({
  iconUrl: "../../../../../red-pin.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

const TASMap = (props) => {
  const { mainStore, fieldHistoryStore } = useMobxStores();
  const [maxBounds, setMaxBounds] = useState(null);

  useEffect(() => {
    axios
      .get(
        "https://cdn.rawgit.com/johan/world.geo.json/34c96bba/countries/GRC.geo.json"
      )
      .then((response) => {
        const geoJSON = response.data;
        setMaxBounds(L.geoJSON(geoJSON).getBounds());
      });
  }, [setMaxBounds, fieldHistoryStore.chosenSessionDataTasAir]);

  console.log(
    "water",
    // [mainStore.TASWater.latitude, mainStore.TASWater.longitude],
    "air:",
    fieldHistoryStore.chosenSessionDataTasAir
  );

  return maxBounds ? (
    <div>
      <div>
        {" "}
        <span>
          {" "}
          <img width="26" src="../../../../../orange-pin.png" />
        </span>
        <span> = Air Sensor Marker</span>
        <span>
          {" "}
          <img width="26" src="../../../../../blue-pin.png" />
        </span>
        <span> = Water Sensor Marker</span>
      </div>
      <div id="map">
        {fieldHistoryStore.chosenSessionDataTasAir.length > 0 ||
        fieldHistoryStore.chosenSessionDataTasWater.length > 0 ? (
          <>
            {fieldHistoryStore.chosenSessionDataTasAir.length === 0 && (
              <MapContainer
                zoom={10}
                minZoom={10}
                scrollWheelZoom={true}
                style={mapStyle}
                center={
                  fieldHistoryStore.chosenSessionDataTasWaterCenter
                  //   [

                  //   fieldHistoryStore.chosenSessionDataTasAir[0].attributes.lat[0],
                  //   fieldHistoryStore.chosenSessionDataTasAir[0].attributes.long[0],
                  // ]
                }
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {/* {mainStore.TAS != "" &&
          mainStore.TAS.latitude != null &&
          mainStore.TAS.longitude != null && (
            <Marker
              position={
                mainStore.TAS != "" && [
                  mainStore.TAS.latitude,
                  mainStore.TAS.longitude,
                ]
              }
              icon={customMarkerWater}
            >
              <Tooltip title="air"></Tooltip>
            </Marker>
          )} */}
                {fieldHistoryStore.chosenSessionDataTasAir.map((artefact) => {
                  return (
                    <Marker
                      position={[
                        artefact.attributes.lat[0],
                        artefact.attributes.long[0],
                      ]}
                      icon={customMarkerAir}
                    >
                      {" "}
                      <Popup
                        maxWidth="auto"
                        maxHeight="auto"
                        style={{ width: "auto" }}
                      >
                        {/* <Tooltip> */}
                        <div>
                          <div>
                            <span>
                              {" "}
                              <b>threat: </b>
                            </span>
                            <span> {JSON.parse(artefact.raw).threat}</span>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <b>latitude: </b>
                            </span>
                            <span>{artefact.attributes.lat[0]}</span>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <b>longitude </b>
                            </span>

                            <span>{artefact.attributes.long[0]}</span>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <b>time:</b>{" "}
                            </span>
                            <span>{artefact.created}</span>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <b> altitude:</b>
                            </span>
                            <span>{artefact.attributes.alt[0]}</span>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <b>threat level:</b>{" "}
                            </span>
                            <span>{artefact.attributes.threatLevel[0]}</span>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <b>confidence:</b>{" "}
                            </span>
                            <span>{artefact.attributes.confidence[0]}</span>
                          </div>
                        </div>
                        {/* </Tooltip> */}
                      </Popup>
                    </Marker>
                  );
                })}
                {fieldHistoryStore.chosenSessionDataTasWater.map((artefact) => {
                  return (
                    <Marker
                      position={[
                        artefact.attributes.lat[0],
                        artefact.attributes.long[0],
                      ]}
                      icon={customMarkerWater}
                    >
                      {" "}
                      <Popup
                        maxWidth="auto"
                        maxHeight="auto"
                        style={{ width: "auto" }}
                      >
                        {/* <Tooltip> */}
                        <div>
                          <div>
                            <span>
                              {" "}
                              <b>threat: </b>
                            </span>
                            <span> {JSON.parse(artefact.raw).threat}</span>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <b>latitude: </b>
                            </span>
                            <span>{artefact.attributes.lat[0]}</span>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <b>longitude </b>
                            </span>

                            <span>{artefact.attributes.long[0]}</span>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <b>time:</b>{" "}
                            </span>
                            <span>{artefact.created}</span>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <b> altitude:</b>
                            </span>
                            <span>{artefact.attributes.alt[0]}</span>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <b>threat level:</b>{" "}
                            </span>
                            <span>{artefact.attributes.threatLevel[0]}</span>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <b>confidence:</b>{" "}
                            </span>
                            <span>{artefact.attributes.confidence[0]}</span>
                          </div>
                        </div>
                        {/* </Tooltip> */}
                      </Popup>
                    </Marker>
                  );
                })}
              </MapContainer>
            )}
            {fieldHistoryStore.chosenSessionDataTasAir.length > 0 && (
              <MapContainer
                zoom={10}
                minZoom={10}
                scrollWheelZoom={true}
                style={mapStyle}
                center={
                  fieldHistoryStore.chosenSessionDataTasAirCenter
                  //   [

                  //   fieldHistoryStore.chosenSessionDataTasAir[0].attributes.lat[0],
                  //   fieldHistoryStore.chosenSessionDataTasAir[0].attributes.long[0],
                  // ]
                }
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  url={
                    constants.ONLINE == "true"
                      ? "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      : `https://map.romania.odysseus-offline.rid-intrasoft.eu/tile/{z}/{x}/{y}.png`
                  }
                />
                {/* {mainStore.TAS != "" &&
          mainStore.TAS.latitude != null &&
          mainStore.TAS.longitude != null && (
            <Marker
              position={
                mainStore.TAS != "" && [
                  mainStore.TAS.latitude,
                  mainStore.TAS.longitude,
                ]
              }
              icon={customMarkerWater}
            >
              <Tooltip title="air"></Tooltip>
            </Marker>
          )} */}
                {fieldHistoryStore.chosenSessionDataTasAir.map((artefact) => {
                  return (
                    <Marker
                      position={[
                        artefact.attributes.lat[0],
                        artefact.attributes.long[0],
                      ]}
                      icon={customMarkerAir}
                    >
                      {" "}
                      <Popup
                        maxWidth="auto"
                        maxHeight="auto"
                        style={{ width: "auto" }}
                      >
                        {/* <Tooltip> */}
                        <div>
                          <div>
                            <span>
                              {" "}
                              <b>threat: </b>
                            </span>
                            <span> {JSON.parse(artefact.raw).threat}</span>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <b>latitude: </b>
                            </span>
                            <span>{artefact.attributes.lat[0]}</span>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <b>longitude </b>
                            </span>

                            <span>{artefact.attributes.long[0]}</span>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <b>time:</b>{" "}
                            </span>
                            <span>{artefact.created}</span>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <b> altitude:</b>
                            </span>
                            <span>{artefact.attributes.alt[0]}</span>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <b>threat level:</b>{" "}
                            </span>
                            <span>{artefact.attributes.threatLevel[0]}</span>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <b>confidence:</b>{" "}
                            </span>
                            <span>{artefact.attributes.confidence[0]}</span>
                          </div>
                        </div>
                        {/* </Tooltip> */}
                      </Popup>
                    </Marker>
                  );
                })}
                {fieldHistoryStore.chosenSessionDataTasWater.map((artefact) => {
                  return (
                    <Marker
                      position={[
                        artefact.attributes.lat[0],
                        artefact.attributes.long[0],
                      ]}
                      icon={customMarkerWater}
                    >
                      {" "}
                      <Popup
                        maxWidth="auto"
                        maxHeight="auto"
                        style={{ width: "auto" }}
                      >
                        {/* <Tooltip> */}
                        <div>
                          <div>
                            <span>
                              {" "}
                              <b>threat: </b>
                            </span>
                            <span> {JSON.parse(artefact.raw).threat}</span>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <b>latitude: </b>
                            </span>
                            <span>{artefact.attributes.lat[0]}</span>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <b>longitude </b>
                            </span>

                            <span>{artefact.attributes.long[0]}</span>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <b>time:</b>{" "}
                            </span>
                            <span>{artefact.created}</span>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <b> altitude:</b>
                            </span>
                            <span>{artefact.attributes.alt[0]}</span>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <b>threat level:</b>{" "}
                            </span>
                            <span>{artefact.attributes.threatLevel[0]}</span>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <b>confidence:</b>{" "}
                            </span>
                            <span>{artefact.attributes.confidence[0]}</span>
                          </div>
                        </div>
                        {/* </Tooltip> */}
                      </Popup>
                    </Marker>
                  );
                })}
              </MapContainer>
            )}
          </>
        ) : (
          <EmptyData />
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default observer(TASMap);
