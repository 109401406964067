import React, { useState, useEffect, useRef } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { getStores, StoreProvider } from "./stores/stores";
import NotFound from "./pages/404";
import { observer } from "mobx-react";
import Messages from "./components/messages/Messages";
import Login from "./pages/Login";
import { constants } from "./consts/Variables";
import BackToTop from "./components/BackToTop/BackToTop";
import {
  ConfigProvider,
  Button,
  Modal,
  Divider,
  Row,
  Upload,
  message,
  Form,
} from "antd";
import Admin from "./components/admin/Admin";
import VisulaAnalysisPage from "./components/visual-analysis/VisulaAnalysisPage";
import Drone from "./components/drone/Drone";
// import Dashboard from "./pages/Dashboard";
import MainHeader from "./components/header/MainHeader";
import Profile from "./components/profile/Profile";
import Missions from "./pages/Missions";
import Empty from "./pages/Empty";
import Users from "./pages/Users";
import Mission from "./pages/Mission";
import Icon from "@mdi/react";
import { v4 as uuidv4 } from "uuid";
// import { useNavigate } from "react-router-dom";

import Transactions from "./outlets/mission/Transactions";
import WebCrawl from "./outlets/mission/WebCrawl";
import FieldOperator from "./outlets/mission/FieldOperator";
import FieldOperatorHistory from "./outlets/mission/FieldOperatorHistory";

import { mdiArrowRightThick, mdiLandFields } from "@mdi/js";

// import FieldOperatorOnline from "./components/field_operator/FieldOperatorOnline";
var _ = require("lodash");

function objectToArrayWithNestedValues(obj) {
  return Object.keys(obj).map((key) => {
    return {
      name: key, // The key of the object becomes the 'name' property
      value: obj[key], // The value associated with the key becomes the 'value' property
    };
  });
}

const App = (props) => {
  const mobxStores = getStores();
  const token = localStorage.getItem("od_token");
  const [socket, setSocket] = useState(null);
  const [isSTDModalOpen, setIsSTDModalOpen] = useState(false);
  const [openModalRecipes, setOpenModalRecipes] = useState(false);
  const [missions, setMissions] = useState([]);
  const [missionsWithTransactions, setMissionsWithTransactions] = useState([]);
  const [importData, setImportData] = useState({});
  const [fileUploaded, setFileUploaded] = useState(false);
  const [ETDAlertcoords, setETDAlertCoords] = useState("");

  // const navigate = useNavigate();
  const socketRef = useRef(null);

  const [import_recipes_form] = Form.useForm();
  const handleOk = () => {
    setOpenModalRecipes(false);
    mobxStores.transactionsStore.uploadRecipesANDIngredients(importData);
    // import_recipes_form.resetFields();
  };

  const cancelClick = () => {
    setOpenModalRecipes(false);
  };

  useEffect(() => {
    setTimeout(() => {
      let message = {
        header: {
          topicName: "SUSPICIOUS_TRANSACTION_DETECTED",
          sender: "SEERC_STD",
          sentUtc: "2024-01-04 10:18:28.787250+00:00",
          source: "STD_NOTIFY",
          caseId: "66e99f1888f719000104edc1",
          // /transactions/6791bc77-1474-45a4-a359-d3020ca86b5a
        },
        body: {
          hash: "11222111222",
          id: "111222111222",
          buyer: "1111222233334444",
          seller: "1",
          items: [
            {
              name: "hexamine",
              quantity: "1.0",
              price: "1000",
              unitsize: "10.0",
              concentration: "0.99",
              totalquantity: "9.9",
              quantityViolation: false,
              regulationViolation: null,
              classificationViolation: false,
              iscomponentof: ["rdx", "hmtd"],
            },
            {
              name: "nitric acid",
              quantity: "1.0",
              price: "1000",
              unitsize: "10.0",
              concentration: "0.99",
              totalquantity: "9.9",
              quantityViolation: false,
              regulationViolation: "(Regulation 2019/1148 Article 5(3))",
              classificationViolation: false,
              iscomponentof: ["tnt", "rdx", "mercury fulminate"],
            },
          ],
          country: "Bulgaria",
          timedate: "2019-04-12 06:41:25",
          cardNumber: "1111222233334444",
          transactionType: "online",
          buyerlicence: "1111222233334444",
          latitude: "42.425843",
          longitude: "25.643487",
          suspicious: "7",
          confidence: 0.67,
          report: "4bdeAbDEcfbd7DAFdFcDcEbdBCe55d.html",
        },
      };
      let messageTAS = {
        header: {
          topicName: "TOP_THREAT_ASSESSMENT_DONE",
          sender: "SEERC_STD",
          msgId: "13143",
          sentUtc: "2024-01-04 10:18:28.787250+00:00",
          source: "STD_NOTIFY",
          projectId: "66d821481947980001270132",
        },
        body: {
          hash: "ffffffffffffffffffffff",
          operationId: "ebf003f4-2c01-44ab-ae1d-d77f70fa8f2f",
          TASDataStored: [
            {
              mimeType: "application/json",
              domainId: "tas:result:ccdf81d8-f564-4641-8846-3f365a30b841",
              artefactId: "66d8413c05574e49d8691a57",
            },
          ],
        },
      };
      let ETDmessage = {
        header: {
          topicName: "ETD_GET_HEATMAP",
          sender: "SEERC_ETD",
          sentUtc: "2024-09-11 16:45:53.907701+00:00",
          source: "ETD",
          caseId: "86b8b6bd42ce110000000900",
          missionId: "86b8b6bd42ce110000000901",
          operationId: "86b8b6bd42ce110000000902",
        },
        body: {
          heatmap_coordinates: [
            [51.35084, -1.99421, 0.7149372494898543],
            [51.78967, 1.15597, 0.7375662087127572],
          ],
        },
      };
      let AIDmessage = {
        header: {
          topicName: "AID_GET_HEATMAP",
          sender: "SEERC_ETD",
          sentUtc: "2024-09-11 16:45:53.907701+00:00",
          source: "ETD",
          caseId: "86b8b6bd42ce110000000900",
          missionId: "86b8b6bd42ce110000000901",
          operationId: "86b8b6bd42ce110000000902",
        },
        body: {
          heatmap_coordinates: [
            [51.35011, -1.99121, 0.7149372494898543],
            [51.78117, 1.11597, 0.7375662087127572],
          ],
        },
      };
      let ETDmessageTwo = {
        header: {
          topicName: "ETD_GET_HEATMAP",
          sender: "SEERC_ETD",
          sentUtc: "2024-09-11 16:45:53.907701+00:00",
          source: "ETD",
          caseId: "86b8b6bd42ce110000000900",
          missionId: "86b8b6bd42ce110000000901",
          operationId: "86b8b6bd42ce110000000902",
        },
        body: {
          heatmap_coordinates: [
            [51.35011, -1.99121, 0.7149372494898543],
            [51.78117, 1.11597, 0.7375662087127572],
          ],
        },
      };
      let messageNewETD = {
        header: {
          topicName: "ETD_GET_HEATMAP",
          sender: "SEERC_ETD",
          sentUtc: "2024-02-05 11:44:28.243140+00:00",
          source: "ETD",
          caseId: "FILLINCASEID",
          missionId: "FILLINMISSIONID",
          operationId: "FILLINOPERATIONID",
        },
        body: {
          ANFO: [
            [51.32084, -1.99421, 0.7749372494898543],
            [51.35084, -1.99421, 0.7149372494898543],
          ],
          "Black powder": [
            [51.82084, -1.99421, 0.7749372494898543],
            [51.15084, -1.99421, 0.7149372494898543],
          ],
        },
      };
      // handleWSMessages(messageTAS);
      // handleWSMessages(message);
      // handleWSMessages(messageNewETD);
      // handleWSMessages(AIDmessage);
      mobxStores.mainStore.GetAlerts();
      mobxStores.mainStore.GetAlertsTAS();
      mobxStores.mainStore.GetSessions();
      // mobxStores.mainStore.GetWaterSensorDataNew();

      let artefactToBeSentOld = {
        header: {
          domainId: "WaterSensor:Data:{4e13c8fdb381426790c9d233065bc2c2}",
          rawType: "string",
          title: "New Data Point",
          raw: "null",
          source: "WaterSensor",
          published: "2024-01-17T13:21:27.7822Z",
          type: "chemical:detected",
          name: "Chemical Detection mirsuhdiuehskkie3c21e0fae",
        },
        attributes: {
          deviceError: 0,
          measured_properties: [
            "case_temperature",
            "cell_temperature",
            "gas_concentration",
          ],
          gas_concentration: [
            { units: "ppm", gas: "NH3", data: 2.638188217720772 },
          ],
          cell_temperature: { units: "K", data: 55.86021154992442 },
          deviceSerialNumber: "MirSenseWaterSensor",
          location_type: "GeoProperty",
          deviceStatus: 2,
          sessionId: "1111-1111-1111-1111",
          operationId: "1231-1111-1111-1111",
          case_temperature: { units: "K", data: 26.63539556688433 },
          gps_latitude: 39.55165,
          gps_longitude: -0.4679029881954193,
          gps_altitude: 0.0,
        },
      };
      let artefactToBeSent = {
        Type: "chemical:detected",
        name: "Chemical Detection 053b88c60ed14bec9168e6c3a88eea55",
        domainId: "chemical:detected:053b88c60ed14bec9168e6c3a88eea55",
        published: "2023-11-24T15:11:59z",
        raw: JSON.stringify({
          eventID: "053b88c60ed14bec9168e6c3a88eea55",
          timeUTC: "2023-11-24T15:11:59z",
          sessionId: "3333-3333-3333-3333",
          operationId: "2222-2222-2222-2222",
          title: "chemical detected",
          detectorStatus: "OK",
          units: "mV",
          lat: 37.8708333333,
          lon: 23.9069444444,
          alt: 50.0,
          peaks: [
            {
              value: 2200,
              compounds: ["Acetone"],
            },
          ],
        }),
        rawType: "application/json",
        title: "Chemical Detection",
        source: "T4I Chemical Detection",
        projectId: "668d4cfa6920ec000117b2ae",
      };

      let event = {};
      let dataFake = {
        header: {
          topicName: "ETD_SEARCH_NOTIFICATION",
        },
        body: {
          lat: 10,
          long: 10,
        },
      };

      event.data = JSON.stringify(dataFake);
      let messageSearch = JSON.parse(event.data);
      // console.log(messageSearch);

      // handleWSMessages(messageSearch);

      // let missionId = "7574dcea-1ed9-4d54-a024-c50710798706";
      // let missionId = "668d4cfa6920ec000117b2ae";
      // mobxStores.mainStore.UploadDataToMission(artefactToBeSent, missionId);

      // mobxStores.mainStore.GetArtefactsNew(missionId);
    }, 10000);
    setTimeout(() => {
      let messageTowSuspicious = {
        header: {
          topicName: "SUSPICIOUS_TRANSACTION_DETECTED",
          sender: "SEERC_STD",
          sentUtc: "2024-01-04 10:18:28.787250+00:00",
          source: "STD_NOTIFY",
          caseId: "66e99f1888f719000104edc1",
          // /transactions/6791bc77-1474-45a4-a359-d3020ca86b5a
        },
        body: {
          hash: "11222111222",
          id: "111222111222",
          buyer: "1111222233334444",
          seller: "1",
          items: [
            {
              name: "hexamine",
              quantity: "1.0",
              price: "1000",
              unitsize: "10.0",
              concentration: "0.99",
              totalquantity: "9.9",
              quantityViolation: false,
              regulationViolation: null,
              classificationViolation: false,
              iscomponentof: ["rdx", "hmtd"],
            },
            {
              name: "nitric acid",
              quantity: "1.0",
              price: "1000",
              unitsize: "10.0",
              concentration: "0.99",
              totalquantity: "9.9",
              quantityViolation: false,
              regulationViolation: "(Regulation 2019/1148 Article 5(3))",
              classificationViolation: false,
              iscomponentof: ["tnt", "rdx", "mercury fulminate"],
            },
          ],
          country: "Bulgaria",
          timedate: "2019-04-12 06:41:25",
          cardNumber: "1111222233334444",
          transactionType: "online",
          buyerlicence: "1111222233334444",
          latitude: "12.425843",
          longitude: "14.643487",
          suspicious: "7",
          confidence: 0.67,
          report: "4bdeAbDEcfbd7DAFdFcDcEbdBCe55d.html",
        },
      };
      let ETDmessageTwo = {
        header: {
          topicName: "ETD_GET_HEATMAP",
          sender: "SEERC_ETD",
          sentUtc: "2024-02-05 11:44:28.243140+00:00",
          source: "ETD",
          caseId: "FILLINCASEID",
          missionId: "FILLINMISSIONID",
          operationId: "FILLINOPERATIONID",
        },
        body: {
          ANFO: [
            [54.32084, -1.99421, 0.7749372494898543],
            [52.35084, -1.99421, 0.7149372494898543],
          ],
          "Black powder": [
            [53.82084, -1.99421, 0.7749372494898543],
            [49.15084, -1.99421, 0.7149372494898543],
          ],
        },
      };
      // handleWSMessages(messageTowSuspicious);
      // handleWSMessages(message);
      // handleWSMessages(ETDmessageTwo);
      // mobxStores.mainStore.GetAlerts();
      // mobxStores.mainStore.GetAlertsTAS();
      // mobxStores.mainStore.GetSessions();
      // mobxStores.mainStore.GetWaterSensorDataNew();
      // let missionId = "7574dcea-1ed9-4d54-a024-c50710798706";
      // let missionId = "668d4cfa6920ec000117b2ae";
      // mobxStores.mainStore.UploadDataToMission(artefactToBeSent, missionId);
      // mobxStores.mainStore.GetArtefactsNew(missionId);
    }, 20000);
    // setInterval(() => {
    //   mobxStores.mainStore.GetAlerts();
    //   mobxStores.mainStore.GetAlertsTAS();
    //   mobxStores.mainStore.GetSessions();
    // }, 5000);
  }, []);

  useEffect(() => {
    setMissions(mobxStores.missionsStore.missions);
  }, [mobxStores.missionsStore.missions]);

  useEffect(() => {
    setMissionsWithTransactions(
      mobxStores.missionsStore.missionsWithTransactions
    );
  }, [mobxStores.missionsStore.missionsWithTransactions]);

  // useEffect(() => {
  //   console.log(mobxStores.transactionsStore.openModalRecipes);
  //   setOpenModalRecipes(mobxStores.transactionsStore.openModalRecipes);
  // }, [mobxStores.transactionsStore.openModalRecipes]);

  const groupByCategory = (array) => {
    return array.reduce((result, item) => {
      const { category, value } = item;
      const existingCategory = result.find((group) => group.name === category);

      if (existingCategory) {
        existingCategory.data.push(value);
      } else {
        result.push({ name: category, data: [value] });
      }

      return result;
    }, []);
  };

  // const handleCancel = () => {
  //   // missionsStore.setIsImportOperationModalOpen(false);
  //   import_recipes_form.resetFields();
  // };

  const updateMessage = () => {
    setOpenModalRecipes(true);
  };

  const navigateToTransactions = (mission) => {
    console.log(mission);
    return mobxStores.missionsStore
      .FindMissionTransactions(mission.id)
      .then((operationId) => {
        console.log(operationId);

        window.location.pathname = `mission/${mission.id}/transactions/${operationId}`;
        // mobxStores.mainStore.SetNotificationCoordinates(ETDAlertcoords);
        // navigate(`/mission/${mission.id}/transactions/${operationId}`);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleFileChange = (info) => {
    if (info.file.status !== "uploading") {
      const file = info.file.originFileObj; // Access the actual file object
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            let jsonNew = JSON.parse(e.target.result);
            console.log(jsonNew);
            setImportData(jsonNew);
            setFileUploaded(true);
            // let fake = {
            //   id: "string",
            //   type: "WaterSensor:Data",
            //   name: "string2",
            //   attributes: {
            //     additionalProp1: ["string"],
            //     additionalProp2: ["string"],
            //     additionalProp3: ["string"],
            //   },
            //   domainId: "sdfi",
            // };
            // missionsStore.createFieldOperationImported(jsonNew, params.pk);
            // fieldHistoryStore.UploadDataToMission(fake, params.pk); // Assuming 'importData' is the observable in 'mainStore'
            //     message.success(`File imported successfully`);
          } catch (error) {
            setFileUploaded(false);
            message.error(`Failed to parse JSON: ${error.message}`);
          }
        };
        reader.readAsText(file); // Read the file as text
      } else {
        setFileUploaded(false);
        message.error(`No file selected`);
      }
    }
  };

  const handleCancel = () => {
    setIsSTDModalOpen(false);
  };

  const topics = [
    "TRANSACTION_DATA_COLLECTION",
    "SUSPICIOUS_TRANSACTION_DETECTED",
    "UGV_event_msg",
    "FIELD_OPERATION_SESSION_ID",
    "Water_event_msg",
    "dashboard_data",
    "TOP_ADAPTED_DOVER_DONE",
    "TRANSACTION_SEARCH",
    "SUSPICIOUS_TRANSACTION_DETECTION",
    "MA_SNA_REQUEST",
    "MA_SNA_DONE",
    "MA_CLUSTERING_REQUEST",
    "MA_CLUSTERING_DONE",
    "TOP_THREAT_ASSESSMENT_DONE",
    "STD_SEARCH_RESULTS",
    "ETD_CLUSTERING_RESULTS",
    "ETD_CLUSTERS_UPDATE",
    "ETD_NOTIFICATION",
    "ETD_SEARCH_NOTIFICATION",
    "ETD_SUPPLY_CHAIN_RESULTS",
    "AID_GET_HEATMAP",
    "ETD_GET_HEATMAP",
  ];

  const thin_api_init = {
    token: token,
    topics: topics,
  };

  const handleWSMessages = (message) => {
    if (message.status == "FAILED") {
      props.onLogout();
    }

    if (message.header) {
      if (message.header.topicName === "AID_GET_HEATMAP") {
        console.log("AID_GET_HEATMAP");
        console.log(message);
        mobxStores.transactionsStore.addAID_HeatMap_realtime(message.body);
      }

      if (message.header.topicName === "ETD_GET_HEATMAP") {
        mobxStores.transactionsStore.addETD_HeatMap_realtime(message.body);
        // mobxStores.errorStore.setMessage(`ETD_CLUSTERS_UPDATE`);
        // mobxStores.errorStore.setInfo(true);
      }

      if (message.header.topicName === "ETD_CLUSTERS_UPDATE") {
        console.log("ETD_CLUSTERS_UPDATE: ", message);
        mobxStores.transactionsStore.GetETDRealTime(
          message.header.caseId,
          message.body.domainId
        );
        // mobxStores.errorStore.setMessage(`ETD_CLUSTERS_UPDATE`);
        // mobxStores.errorStore.setInfo(true);
      }

      if (message.header.topicName === "ETD_NOTIFICATION") {
        // console.log('ETD_NOTIFICATION: ',message)
        mobxStores.errorStore.setMessage(
          `${message.body.title} - ${message.body.text}`
        );
        mobxStores.errorStore.setInfo(true);
      }
      if (message.header.topicName === "ETD_SUPPLY_CHAIN_RESULTS") {
        console.log("ETD_SUPPLY_CHAIN_RESULTS: ", message);
        mobxStores.mainStore.setSupplyChain(message.body.transactions);

        // mobxStores.errorStore.setMessage(
        //   `${message.body.title} - ${message.body.text}`
        // );
        mobxStores.errorStore.setInfo(true);
      }

      if (message.header.topicName === "ETD_SEARCH_NOTIFICATION") {
        mobxStores.missionsStore.GetMissionsWithTransactions();
        // mobxStores.mainStore.SetNotificationCoordinates(message.body);
        // setETDAlertCoords(message.body);
        // console.log('ETD_NOTIFICATION: ',message)

        mobxStores.errorStore.setMessage(
          <div>
            {" "}
            <Button
              onClick={() => {
                // console.log("fdf");
                setIsSTDModalOpen(true);
              }}
            >
              search nearby
            </Button>
          </div>
        );
        mobxStores.errorStore.setInfo(true);
      }
      if (message.header.topicName === "ETD_NOTIFICATION") {
        mobxStores.missionsStore.GetMissionsWithTransactions();
        // mobxStores.mainStore.SetNotificationCoordinates(message.body);
        // setETDAlertCoords(message.body);
        // console.log('ETD_NOTIFICATION: ',message)

        mobxStores.errorStore.setMessage(
          <div>
            {" "}
            <Button
              onClick={() => {
                // console.log("fdf");
                setIsSTDModalOpen(true);
              }}
            >
              search nearby
            </Button>
          </div>
        );
        mobxStores.errorStore.setInfo(true);
      }

      if (message.header.topicName === "TOP_THREAT_ASSESSMENT_DONE") {
        mobxStores.mainStore.setTas(
          message.header.projectId,
          // message.header.caseId,
          message.body.TASDataStored[0].artefactId,
          message,
          mobxStores.usersStore.currentUser.type
        );
        // mobxStores.errorStore.setMessage("New message from TAS");
        // mobxStores.errorStore.setInfo(true);
      }

      if (message.header.topicName === "ETD_CLUSTERING_RESULTS") {
        console.log("ETDSearch");
        console.log(message);
        // console.log("ETD: ", _.cloneDeep(message.body));
        // mobxStores.errorStore.setMessage("New message from ETD");
        // mobxStores.errorStore.setInfo(true);
        mobxStores.mainStore.setIsETDSearching(false);
        console.log(message.body);
        console.log(objectToArrayWithNestedValues(message.body));
        mobxStores.mainStore.setTransactionsETD(
          objectToArrayWithNestedValues(message.body)
        );

        mobxStores.mainStore.setETDCoords(message.body);
      }

      if (
        message.header.topicName === "/to_kafka/UGV_event_msg" ||
        message.header.topicName === "to_kafka/UGV_event_msg" ||
        message.header.topicName === "UGV_event_msg"
      ) {
        console.log(message);
        mobxStores.mainStore.setUGVRealTimeData(message.body);
        mobxStores.mainStore.setUGVRealTimeDataFull(message);
        mobxStores.errorStore.setMessage("New UGV_event_msg");
        mobxStores.errorStore.setInfo(true);
        mobxStores.mainStore.Session(message.body);
      }

      if (
        message.header.topicName === "/to_kafka/UAV_event_msg" ||
        message.header.topicName === "to_kafka/UAV_event_msg" ||
        message.header.topicName === "UAV_event_msg"
      ) {
        console.log(message);
        mobxStores.mainStore.setUAVRealTimeData(message.body);
        mobxStores.mainStore.setUAVRealTimeDataFull(message);
        mobxStores.errorStore.setMessage("New UAV_event_msg");
        mobxStores.errorStore.setInfo(true);
        mobxStores.mainStore.Session(message.body);
      }

      if (
        message.header.topicName === "/to_kafka/Water_event_msg" ||
        message.header.topicName === "to_kafka/Water_event_msg" ||
        message.header.topicName === "Water_event_msg" ||
        message.header.source === "WaterSensor"
      ) {
        // console.log("water sensor data: ", message);
        mobxStores.mainStore.setWaterSensorData(message);
        mobxStores.mainStore.setwaterSensorY(message.header.published);
        let h2o2Data = message.attributes.gas_concentration.find(
          (gas) => gas.gas === "H2O2"
        );
        let nh3Data = message.attributes.gas_concentration.find(
          (gas) => gas.gas === "NH3"
        );

        // {
        //   "header": {
        //     "domainId": "WaterSensor:Data:{4e13c8fdb381426790c9d233065bc2c0}",
        //     "rawType": "string",
        //     "title": "New Data Point",
        //     "raw": "null",
        //     "source": "WaterSensor",
        //     "published": "2024-01-17T13:21:27.7822Z",
        //     "type": "chemical:detected",
        //     "name": "Chemical Detection mirsuhdiuehskkie3c21e0fae"
        //   },
        //   "attributes": {
        //     "deviceError": 0,
        //     "measured_properties": [
        //       "case_temperature",
        //       "cell_temperature",
        //       "gas_concentration"
        //     ],
        //     "gas_concentration": [
        //       { "units": "ppm", "gas": "NH3", "data": 2.638188217720772 }
        //     ],
        //     "cell_temperature": { "units": "K", "data": 55.86021154992442 },
        //     "deviceSerialNumber": "MirSenseWaterSensor",
        //     "location_type": "GeoProperty",
        //     "deviceStatus": 2,
        //     "sessionId": "1111-1111-1111-1111",
        //     "case_temperature": { "units": "K", "data": 26.63539556688433 },
        //     "gps_latitude": 39.55165,
        //     "gps_longitude": -0.4679029881954193,
        //     "gps_altitude": 0.0
        //   }
        // }

        if (h2o2Data) {
          console.log(h2o2Data);
          mobxStores.mainStore.setwaterSensorH202(h2o2Data.data);
        } else {
          mobxStores.mainStore.setwaterSensorH202("");
        }

        if (nh3Data) {
          console.log(nh3Data);
          mobxStores.mainStore.setwaterSensorNH3(nh3Data.data);
        } else {
          mobxStores.mainStore.setwaterSensorNH3("");
        }

        mobxStores.mainStore.setGas(message.attributes.gas_concentration);

        mobxStores.errorStore.setMessage("New water sensor data");
        mobxStores.errorStore.setInfo(true);
        mobxStores.mainStore.Session(message.body);
        // mobxStores.mainStore.setWaterSensorData(message.attributes);
        // console.log("message");
        // console.log(message);
      }

      if (message.header.topicName === "STD_SEARCH_RESULTS") {
        console.log("searched data: ", message);
        mobxStores.mainStore.setSearchedData(message.body);
        mobxStores.mainStore.setIsSearching(false);
      }

      if (message.header.topicName === "MA_SNA_DONE") {
        mobxStores.errorStore.setMessage("New alert MA_SNA_DONE");
        mobxStores.errorStore.setInfo(true);
        console.log("MA_SNA_DONE");
        console.log(message);
        console.log(message.body.dataUpdated[0].domainId);
        mobxStores.mainStore.snDomainId = message.body.dataUpdated[0].domainId;
        // mobxStores.mainStore.GetClusteringFromArtefactsNew(
        //   message.body.dataUpdated[0].domainId
        // );

        mobxStores.mainStore.GetSocialNetworkAnalysisNew(
          message.body.dataUpdated[0].domainId,
          message.body.dataUpdated[0].taskId
        );
      }

      if (message.header.topicName === "MA_CLUSTERING_DONE") {
        // let clusterDomainId = mobxStores.mainStore.clusterDomainId;
        mobxStores.errorStore.setMessage("New alert MA_CLUSTERING_DONE");
        mobxStores.errorStore.setInfo(true);
        console.log(message.body);
        console.log(message.body.dataUpdated[0].domainId);
        if (
          message.body.dataUpdated[0].taskId ===
          mobxStores.mainStore.lastOperationClusterClicked
        ) {
          mobxStores.mainStore.clusterDomainId =
            message.body.dataUpdated[0].domainId;
          mobxStores.mainStore.GetClusteringFromArtefactsNew(
            message.body.dataUpdated[0].domainId
          );
        }

        // mobxStores.mainStore.GetSocialNetworkAnalysisNew(
        //   message.body.dataUpdated[0].domainId
        // );
      }

      if (
        message.header.topicName === "SUSPICIOUS_TRANSACTION_DETECTION" ||
        message.header.topicName === "SUSPICIOUS_TRANSACTION_DETECTED"
      ) {
        console.log("detected");
        console.log(message.body);
        console.log(mobxStores.usersStore.currentUser.type);
        if (
          mobxStores.usersStore.currentUser.type.includes(
            "mission_commander"
          ) ||
          mobxStores.usersStore.currentUser.type.includes("transaction_analyst")
        ) {
          console.log("allMissionsids");
          // mobxStores.missionsStore.GetMissionsWithTransactions();
          // mobxStores.mainStore.SetNotificationCoordinates({
          //   lat: Number(message.body.latitude),
          //   lon: Number(message.body.longitude),
          // });
          console.log(1);
          // mobxStores.mainStore.addNotification(message.body);
          let fields = {};
          let newUuid = uuidv4();
          let messageString = JSON.stringify(message.body);
          fields.name = "alert:" + newUuid;
          fields.attributes = { message: [messageString] };
          fields.type = "suspicious_transaction";
          mobxStores.mainStore.addAlert(fields);
        }

        // mobxStores.errorStore.setMessage("New alert STD");
        // mobxStores.errorStore.setInfo(true);
      }

      if (message.header.topicName === "TOP_ADAPTED_DOVER_DONE") {
        mobxStores.errorStore.setMessage("New air sensor");
        mobxStores.errorStore.setInfo(true);
        console.log(message.body);
        // let x = mobxStores.mainStore.x;
        // let y = mobxStores.mainStore.y;
        let acetone = mobxStores.mainStore.acetone;

        let toluene = mobxStores.mainStore.toluene;
        let y = mobxStores.mainStore.y;

        if (message.body.peaks) {
          let foundAcetone = "";
          let foundToluene = "";
          for (let i = 0; i < message.body.peaks.length; i++) {
            for (let y = 0; y < message.body.peaks[i].compounds.length; y++) {
              console.log(message.body.peaks[i].compounds);
              if (message.body.peaks[i].compounds[y] === "Acetone") {
                foundAcetone = message.body.peaks[i];
              }
              if (message.body.peaks[i].compounds[y] === "Toluene") {
                foundToluene = message.body.peaks[i];
              }
            }
          }
          if (foundAcetone && foundToluene) {
            acetone.data.push(foundAcetone.value);
            toluene.data.push(foundToluene.value);
          }
          if (foundAcetone && !foundToluene) {
            acetone.data.push(foundAcetone.value);
            toluene.data.push("");
          }
          if (!foundAcetone && foundToluene) {
            acetone.data.push("");
            toluene.data.push(foundToluene.value);
          }
          if (!foundAcetone && !foundToluene) {
            acetone.data.push("");
            toluene.data.push("");
          }
          y.push(message.body.timeUTC);
          mobxStores.mainStore.setAirDataNew();
        }
        mobxStores.mainStore.setAirSensorData(message.body);
        mobxStores.mainStore.Session(message.body);
      }
    }
  };

  const connectWebSocket = () => {
    if (
      !socketRef.current ||
      socketRef.current.readyState === WebSocket.CLOSED
    ) {
      const wsConnection = new WebSocket(
        `${constants.THIN_API_WS}/kafka/listen`
      );

      wsConnection.addEventListener("open", (event) => {
        wsConnection.send(JSON.stringify(thin_api_init));
        setSocket(wsConnection);
      });

      wsConnection.addEventListener("message", (event) => {
        // Handle incoming messages
        mobxStores.mainStore.setIsKafkaConnected("true");

        function isValidJson(str) {
          try {
            JSON.parse(str);
            return true;
          } catch (e) {
            return false;
          }
        }

        if (isValidJson(event.data)) {
          let message = JSON.parse(event.data);
          handleWSMessages(message);
        } else {
          console.log("The provided string is not valid JSON.");
        }
      });

      wsConnection.addEventListener("close", () => {
        // console.log("WebSocket connection closed");
        socketRef.current = null;
        setTimeout(connectWebSocket, 2000);
      });

      wsConnection.addEventListener("error", (error) => {
        // console.error("WebSocket error:", error);
      });

      if (socketRef.current) {
        socketRef.current.close();
      }

      socketRef.current = wsConnection;
    } else {
      console.log("ws is already open");
    }
  };

  const onAddRecipesANDIngridients = (fields) => {
    console.log(importData.data);
  };

  useEffect(() => {
    connectWebSocket();

    // Cleanup function
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
        socketRef.current = null;
      }
    };
  }, [socketRef]);

  useEffect(() => {
    if (token == null) {
      props.onLogin();
    }
    mobxStores.transactionsStore.GetProjectIdOfTransactions();
    if (localStorage.getItem("currentUser")) {
      mobxStores.usersStore.currentUser = JSON.parse(
        localStorage.getItem("currentUser")
      );
    }
    const handleBeforeUnload = (event) => {
      localStorage.setItem(
        "currentUser",
        JSON.stringify(mobxStores.usersStore.currentUser)
      );
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    mobxStores.userStore.setToken(props.token);
    mobxStores.mainStore.GetWaterSensorData("86b8b6bd42ce110000000900");
    mobxStores.mainStore.GetUgvstatus("86b8b6bd42ce110000000900");
  });

  return (
    <BrowserRouter>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#31438e",
            fontSize: 14,
            lineWidth: 2,
            colorText: "black",
          },
        }}
      >
        <StoreProvider value={mobxStores}>
          <div
            style={{
              backgroundColor: "#ECEFF1",
              minWidth: "1400px",
              height: "auto",
            }}
          >
            <MainHeader
              token={token}
              onLogout={props.onLogout}
              onLogin={props.onLogin}
              onUpdateToken={props.onUpdateToken}
              user={props.user}
              style={{ width: "100vw" }}
            />

            {/* <MainMenuV2
            token={token}
            onLogout={props.onLogout}
            onLogin={props.onLogin}
            onUpdateToken={props.onUpdateToken}
            // user={localStorage.getItem("od_token_decoded")}
          /> */}
            <div>
              <Modal
                style={{
                  width: "540px",
                }}
                title="Import Recipes"
                open={openModalRecipes}
                onOk={handleOk}
                onCancel={cancelClick}
                // footer={null}
              >
                {/* <div>Import Data</div> */}
                <div>
                  <Form
                    name="import_recipes_form"
                    onFinish={onAddRecipesANDIngridients}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout="vertical"
                    size="large"
                    form={import_recipes_form}
                  >
                    <Form.Item
                      label="Recipes"
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: "Please import recipes",
                          type: "string",
                        },
                      ]}
                    >
                      <Upload
                        name="file"
                        // beforeUpload={() => false} // Prevent automatic upload
                        onChange={handleFileChange}
                      >
                        <Button>Import Recipes</Button>
                      </Upload>
                    </Form.Item>
                  </Form>
                </div>
              </Modal>
              <Modal
                style={{
                  width: "540px",
                }}
                title="Choose Mission to Report this transaction"
                open={isSTDModalOpen}
                // onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
              >
                <div>Missions</div>

                {missionsWithTransactions.length > 0 && (
                  <div
                    style={{
                      border: "1px black solid",
                      overflowY: "auto",
                      height: "600px",
                      width: "500px",
                    }}
                  >
                    {missionsWithTransactions.map((mission) => {
                      return (
                        <div style={{ width: "460px", marginTop: "4px" }}>
                          <Row>
                            {" "}
                            <span style={{ width: "80%" }}>
                              <div> name: {mission.name}</div>
                              <div style={{ marginTop: "8px" }}>
                                {" "}
                                Description: {mission.description}
                              </div>
                            </span>
                            <span style={{ width: "20%" }}>
                              {" "}
                              <Button
                                onClick={() => {
                                  navigateToTransactions(mission);
                                }}
                              >
                                <Icon path={mdiArrowRightThick} size={1} />
                              </Button>
                            </span>
                          </Row>

                          <Divider
                          // style={{ marginTop: "-4px", marginBottom: "-4px" }}
                          ></Divider>
                        </div>
                      );
                    })}
                  </div>
                )}
              </Modal>
              <Routes>
                <Route
                  path="/home"
                  element={<Empty />}
                  token={props.token}
                  onLogin={props.onLogin}
                />
                <Route
                  path="/missions"
                  element={<Missions updateParentMessage={updateMessage} />}
                />
                <Route path="/users" element={<Users />} />
                {/* <Route
                  path="/"
                  element={
                    <Dashboard token={props.token} onLogin={props.onLogin} />
                  }
                /> */}
                <Route path="/mission/:pk" element={<Mission />}>
                  <Route
                    path="/mission/:pk/operation/:id"
                    element={<Drone />}
                  />
                  <Route
                    path="/mission/:pk/web_crawl/:id"
                    element={<WebCrawl />}
                  />
                  <Route
                    path="/mission/:pk/transactions/:id"
                    element={<Transactions />}
                  />
                  {/* <Route
                    path="/mission/:pk/field_operator/:id/session/realtime"
                    element={<FieldOperator />}
                  /> */}
                  <Route
                    path="/mission/:pk/field_operator/:id/session/:sessionId"
                    element={<FieldOperator />}
                  />
                </Route>
                <Route path="/admin" element={<Admin />} />
                <Route
                  path="/text-translation-and-analysis"
                  element={<WebCrawl />}
                />
                <Route
                  path="/visual-analysis"
                  element={<VisulaAnalysisPage />}
                />
                <Route path="/drones" element={<Drone />} />

                <Route path="/field-operator" element={<FieldOperator />} />
                {/* <Route
                path="/field-operator-online"
                element={<FieldOperatorOnline />}
              /> */}

                <Route path="/transactions" element={<Transactions />} />
                <Route
                  path="/profile"
                  element={
                    <Profile token={props.token} onLogin={props.onLogin} />
                  }
                />
                <Route
                  path="/login"
                  element={
                    <Login token={props.token} onLogin={props.onLogin} />
                  }
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>

            <Messages />
            <BackToTop />
          </div>
        </StoreProvider>
      </ConfigProvider>
    </BrowserRouter>
  );
};

export default observer(App);
