import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";
import axios from "axios";
import { Card } from "antd";
import { constants } from "../../consts/Variables";

const mapStyle = {
  height: "500px",
  width: "500px",
  borderRadius: "15px",
  marginLeft: "40px",
};

const customOrange = new L.icon({
  iconUrl: "../../../orange-pin.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

const customMarker = new L.icon({
  iconUrl: "../../../blue-pin.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

const customRed = new L.icon({
  iconUrl: "../../../red-pin.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

const ETDMapRealMap = (props) => {
  const { mainStore, transactionsStore } = useMobxStores();
  const [maxBounds, setMaxBounds] = useState(null);

  const [transactionData, setTransactionsData] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://cdn.rawgit.com/johan/world.geo.json/34c96bba/countries/GRC.geo.json"
      )
      .then((response) => {
        const geoJSON = response.data;
        setMaxBounds(L.geoJSON(geoJSON).getBounds());
      });
  }, [setMaxBounds, transactionsStore.ETDCoordsRealTime]);

  return maxBounds ? (
    <div id="map">
      <MapContainer
        zoom={3}
        minZoom={1}
        scrollWheelZoom={true}
        // maxBounds={maxBounds}
        style={mapStyle}
        center={[52.3555, 1.1743]}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url={
            constants.ONLINE == "true"
              ? "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              : `https://map.romania.odysseus-offline.rid-intrasoft.eu/tile/{z}/{x}/{y}.png`
          }
          // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {transactionsStore.ETDCoordsRealTime != null &&
          transactionsStore.ETDCoordsRealTime.filter((marker) => {
            if (props.selectedRecipe.name === "ALL") {
              return marker;
            } else {
              return marker.properties.recipe === props.selectedRecipe.name;
            }
          }).map((item, index) => {
            return (
              <Marker
                key={index}
                position={[item.coords.lat, item.coords.lon]}
                icon={
                  item.properties.confidence < 0.3
                    ? customMarker
                    : item.properties.confidence <= 0.6
                    ? customOrange
                    : customRed
                }
              >
                <Popup
                  maxWidth="auto"
                  maxHeight="auto"
                  style={{ width: "auto" }}
                >
                  <Card
                    title={`Cluster ${item.id} (${item.recipe})`}
                    style={{
                      width: "50vw",
                      minWidth: "50vw",
                      textAlign: "left",
                    }}
                    bodyStyle={{
                      width: "50vw",
                      textAlign: "left",
                      overflow: "scroll",
                      maxHeight: "50vh",
                      height: "auto",
                    }}
                  >
                    {` Confidence: ${item.properties.confidence}`}
                    <br />
                    {/* <a href={item.properties.detailsLink}>Details</a> */}
                    <>
                      {item.properties.precursors != 0 &&
                        item.properties.precursors.text}
                    </>
                  </Card>
                </Popup>
              </Marker>
            );
          })}
      </MapContainer>
    </div>
  ) : (
    <></>
  );
};

export default observer(ETDMapRealMap);
