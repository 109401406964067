import React, { useEffect, useState, useSyncExternalStore } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  Divider,
  message,
  Upload,
} from "antd";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import { v4 as uuidv4 } from "uuid";

const { Option } = Select;

const CreateOperation = (props) => {
  // const { missionsStore } = useMobxStores();
  const { mainStore, usersStore, missionsStore } = useMobxStores();
  const [operation_type, setOperationType] = useState("field");
  const [social_media, setSocialMedia] = useState(null);
  const [importData, setImportData] = useState({});
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const [web_analysts, set_web_analysts] = useState([]);
  const [webAnalystsAssignees, setWebAnalystsAssignees] = useState([]);

  const [allUsers, setAllUsers] = useState([]);
  const [transaction_analysts, set_transaction_analysts] = useState([]);
  const [transactionAnalystsAssignees, setTransactionAnalystsAssignees] =
    useState([]);
  const [field_operators, set_field_operators] = useState([]);
  const [fieldOperatorsAssignees, setFieldOperatorsAssignees] = useState([]);

  useEffect(() => {
    usersStore.GetAllUsers();

    // usersStore.FieldOperators();
  }, []);

  useEffect(() => {
    set_web_analysts(
      usersStore.allUsers
        .filter((user) => {
          return user.attributes.webanalyst[0] === "true";
        })
        .map((user) => {
          return { value: user.name, label: user.name };
        })
    );
    set_transaction_analysts(
      usersStore.allUsers
        .filter((user) => {
          return user.attributes.transaction_analyst[0] === "true";
        })
        .map((user) => {
          return { value: user.name, label: user.name };
        })
    );
    set_field_operators(
      usersStore.allUsers
        .filter((user) => {
          return user.attributes.field_operator[0] === "true";
        })
        .map((user) => {
          return { value: user.name, label: user.name };
        })
    );
  }, [usersStore.allUsers]);

  const [create_operation_form] = Form.useForm();

  const handleFileChange = (info) => {
    if (info.file.status !== "uploading") {
      const file = info.file.originFileObj; // Access the actual file object
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            let jsonNew = JSON.parse(e.target.result);
            console.log(jsonNew);
            setImportData(jsonNew);
          } catch (error) {
            message.error(`Failed to parse JSON: ${error.message}`);
          }
        };
        reader.readAsText(file); // Read the file as text
      } else {
        message.error(`No file selected`);
      }
    }
  };

  const onCreateOperation = (fields) => {
    let newUuid = "";
    let result = "";

    if (importData.data && importData.data[0]) {
      if (importData.data[0].tags && importData.data[0].tags.length > 0) {
        let operationString = importData.data[0].tags.find((str) => {
          return str.includes("operationId:");
        });
        newUuid = operationString;
        let parts = newUuid.split("operationId:");
        result = parts[1];
      }

      if (importData.data[0].raw) {
        console.log(importData.data[0].raw);
        try {
          const parsedRaw = JSON.parse(importData.data[0].raw);

          if (parsedRaw.operationId) {
            result = parsedRaw.operationId;
          }
        } catch (error) {
          console.error("Failed to parse JSON:", error);
        }
      }

      if (
        importData.data[0].attributes &&
        importData.data[0].attributes.operationId &&
        importData.data[0].attributes.operationId[0]
      ) {
        result = importData.data[0].attributes.operationId[0];
      }
    }

    console.log(missionsStore);
    if (missionsStore.operationsIdsOfPickedMission.includes(result)) {
      console.log("hfi");
      missionsStore.setIsImportOperationModalOpen(false);
      setErrorModalOpen(true);
    } else {
      let newfields = fields;
      newfields.type = "field";

      newfields.attributes = {
        operationId: [result],
        assignee: fieldOperatorsAssignees,

        location: [fields.location],
      };
      // }

      return missionsStore.AddOperationToMission(newfields).then(() => {
        console.log("ImportData");
        mainStore.uploadArtefacts(importData.data, missionsStore.pid);
        missionsStore.setIsImportOperationModalOpen(false);
      });
    }

    // return
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleOk = () => {
    missionsStore.setIsImportOperationModalOpen(false);
    create_operation_form.resetFields();
  };

  const handleErrorModalOk = () => {
    setErrorModalOpen(false);
  };
  const handleErrorModalCancel = () => {
    setErrorModalOpen(false);
  };

  const handleCancel = () => {
    missionsStore.setIsImportOperationModalOpen(false);
    create_operation_form.resetFields();
  };

  const types = [
    {
      label: "web crawl",
      value: "web_crawl",
    },
    {
      label: "field",
      value: "field",
    },
    {
      label: "transaction analysis",
      value: "transaction analysis",
    },
  ];
  const levels = [
    {
      label: "1 level",
      value: 1,
    },
    {
      label: "2 levels",
      value: 2,
    },
    // {
    //   label: "3",
    //   value: 3,
    // },
  ];
  const handleWebAnalystsAssignees = (value) => {
    setWebAnalystsAssignees(value);
  };
  const handleTransactionAnalystsAssignees = (value) => {
    setTransactionAnalystsAssignees(value);
  };
  const handleFieldOperatorsAssignees = (value) => {
    setFieldOperatorsAssignees(value);
  };

  const media = [
    {
      label: "Surface & Dark Web",
      value: "dark_web",
    },
    {
      label: "Reddit",
      value: "RedditSubredditScrape",
    },
    {
      label: "Youtube",
      value: "YoutubeVideoScrape",
    },
    {
      label: "Tik tok",
      value: "TikTokScrape",
    },
  ];

  return (
    <div>
      <Modal
        title="Import Field Operation to this mission"
        open={errorModalOpen}
        onOk={handleErrorModalOk}
        onCancel={handleErrorModalCancel}
        width={"80%"}
        style={{ textAlign: "center", height: "100%" }}
        footer={null}
      >
        Importing data failed. The operation id has been used in this mission.
      </Modal>
      <Modal
        title="Import Field Operation to this mission"
        open={missionsStore.isImportOperationModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"80%"}
        style={{ textAlign: "center", height: "100%" }}
        footer={null}
      >
        <Form
          initialValues={{
            remember: true,
            redditSearchType: "relevance", // Set default value for redditSearchType
            youtubeSearchType: "relevance", // Set default value for youtubeSearchType
            tiktokSearchType: "top", // Set default value for tiktokSearchType
          }}
          name="create_operation_form"
          // initialValues={{
          //   remember: true,
          // }}
          onFinish={onCreateOperation}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          size="large"
          form={create_operation_form}
        >
          {missionsStore.pickedMission.description &&
          missionsStore.pickedMission.description.includes("?creator_name=") &&
          usersStore.currentUser.name ===
            missionsStore.pickedMission.description.split(
              "?creator_name="
            )[1] ? (
            <div>
              <Row gutter={[20, 20]}>
                <Col span={6}>
                  {" "}
                  <Form.Item
                    label="Operation Title"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input title",
                        type: "string",
                      },
                    ]}
                  >
                    <Input placeholder="Please input operation title!" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Operation Description"
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: "Please input Description",
                        type: "string",
                      },
                    ]}
                  >
                    <Input placeholder="Please input operation description!" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Upload
                    name="file"
                    // beforeUpload={() => false} // Prevent automatic upload
                    onChange={handleFileChange}
                  >
                    <Button>Import Data</Button>
                  </Upload>
                  {/* <Form.Item
                    label="Type of operation"
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: "Please input operation type!",
                      },
                    ]}
                  >
                   
                  </Form.Item> */}
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Operation Location"
                    name="location"
                    rules={[
                      {
                        required: true,
                        message: "Please input location",
                        type: "string",
                      },
                    ]}
                  >
                    <Input placeholder="Please input operation location!" />
                  </Form.Item>
                </Col>
              </Row>
              {operation_type === "field" && (
                <div>
                  <Form.Item
                    style={{ marginTop: "-20px" }}
                    label="fiel operation assignees"
                    name="field_operator"
                    rules={[
                      {
                        required: true,
                        message: "Please select field operator!",
                      },
                    ]}
                  >
                    <Select
                      mode="tags"
                      style={{
                        width: "100%",
                      }}
                      onChange={handleFieldOperatorsAssignees}
                      tokenSeparators={[","]}
                      options={field_operators}
                    >
                      {field_operators.map((item, index) => {
                        return (
                          <Option key={index} value={item.value}>
                            {item.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              )}

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 24,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Create
                </Button>
              </Form.Item>
            </div>
          ) : (
            <div> Only the creator of the mission can import operations</div>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default observer(CreateOperation);
