import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";
import { Card, Row, Divider } from "antd";
import { constants } from "../../consts/Variables";

const customMarker = new L.icon({
  iconUrl: "../../../blue-pin.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

const NotificationMap = (props) => {
  const { mainStore } = useMobxStores();

  const [map, setMap] = useState(null);

  const itemsName = (items) => {
    let newArray = items.map((item) => {
      return item.name;
    });
  };

  return (
    <div id="map">
      {props.lon !== undefined && props.lat !== undefined && (
        <MapContainer
          center={[props.lat, props.lon]}
          zoom={6}
          map={map}
          scrollWheelZoom={false}
          style={{ height: "350px" }}
          whenCreated={setMap}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            url={
              constants.ONLINE == "true"
                ? "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                : `https://map.romania.odysseus-offline.rid-intrasoft.eu/tile/{z}/{x}/{y}.png`
            }
          />

          <Marker position={[props.lat, props.lon]} icon={customMarker}>
            <Popup
              style={{
                width: "auto",
                minWidth: "700px",
                height: "200px",
                textAlign: "left",
                // zIndex: 200,
              }}
            >
              <Card
                style={{
                  width: "auto",
                  minWidth: "400px",
                  height: "300px",
                  textAlign: "left",
                  // zIndex: 200,
                }}
                bodyStyle={{
                  // width: "auto",
                  textAlign: "left",
                  overflow: "scroll",
                  // maxHeight: "60vh",
                  // height: "auto",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#E0E0E0",
                    color: "black",
                  }}
                >
                  Transaction items
                </div>
                <div
                  style={{
                    height: "60px",
                    overflowY: "auto",
                    border: "#B0BEC5 solid 1px",
                    height: "200px",
                  }}
                >
                  {props.message.items.map((item) => {
                    return (
                      <Row>
                        <div
                          style={{
                            height: "20px",
                            width: "400px",
                            backgroundColor: "#283593",
                            color: "white",
                          }}
                        >
                          {" "}
                          <span> {item.name}</span>
                        </div>
                        {item.quantityViolation && (
                          <div style={{ height: "20px", width: "400px" }}>
                            Quantity Violation
                            <span style={{ marginLeft: "4px" }}>
                              {item.quantityViolation}
                            </span>
                          </div>
                        )}
                        {!item.quantityViolation && (
                          <div style={{ height: "20px", width: "400px" }}>
                            No Quantity Violation
                          </div>
                        )}
                        {item.regulationViolation && (
                          <div style={{ height: "20px", width: "400px" }}>
                            Regulation Violation
                            <span> {item.regulationViolation}</span>
                          </div>
                        )}
                        {!item.regulationViolation && (
                          <div style={{ height: "20px", width: "400px" }}>
                            No Regulation Violation
                          </div>
                        )}
                        {item.totalquantity && (
                          <div style={{ height: "20px", width: "400px" }}>
                            Total quantity:
                            {Number(item.totalquantity).toFixed(4)}
                          </div>
                        )}

                        {item.concentration && (
                          <div style={{ height: "20px", width: "400px" }}>
                            Concentration:
                            {Number(item.concentration).toFixed(4)}
                          </div>
                        )}

                        <Divider
                          style={{
                            marginTop: "2px",
                            marginBottom: "2px",
                            backgroundColor: "black",
                          }}
                        ></Divider>
                      </Row>
                    );
                  })}
                </div>
                {/* <p>{props.message.country}</p> */}
                {/* <p>{itemsName(props.message.items)}</p> */}
              </Card>
            </Popup>
          </Marker>
        </MapContainer>
      )}
    </div>
  );
};

export default observer(NotificationMap);
