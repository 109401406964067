import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet.heat";
import "leaflet/dist/leaflet.css";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react-lite";
import { constants } from "../../consts/Variables";
const mapStyle = {
  height: "400px",
  width: "900px",
  // borderRadius: "15px",
  marginLeft: "40px",
};

const HeatmapLayer = ({ points }) => {
  const map = useMap();

  useEffect(() => {
    const heatLayer = L.heatLayer(points, {
      radius: 25,
      blur: 15,
      maxZoom: 6,
    }).addTo(map);

    return () => {
      map.removeLayer(heatLayer);
    };
  }, [map, points]);

  return null;
};

const Heatmap = observer(() => {
  const { transactionsStore } = useMobxStores();
  // const [points, setPoints] = useState(transactionsStore.ETD_HeatMap_realtime);
  const [points, setPoints] = useState(
    transactionsStore.SelectedRecipeETD_HeatMap_realtime
  );

  useEffect(() => {
    // Update the points from the store every 5 seconds
    const interval = setInterval(() => {
      // setPoints([...transactionsStore.ETD_HeatMap_realtime]); // Update heatmap points
      setPoints(transactionsStore.SelectedRecipeETD_HeatMap_realtime); // Update heatmap points
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <MapContainer
      // className="mapSize"
      center={[44.439663, 26.096306, 0.7149372494898543]} // Customize this to your desired center
      zoom={6}
      style={mapStyle}
    >
      <TileLayer
        // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        url={
          constants.ONLINE == "true"
            ? "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            : `https://map.romania.odysseus-offline.rid-intrasoft.eu/tile/{z}/{x}/{y}.png`
        }
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      <HeatmapLayer points={points} />
    </MapContainer>
  );
});

export default Heatmap;
