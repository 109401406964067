import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Table,
  Space,
  Modal,
  Collapse,
  Drawer,
  Divider,
  Tabs,
  Select,
} from "antd";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";
import ETDMapRealTime from "./ETDMapRealTime";
import MapHeat from "./MapHeat";
import { UnorderedListOutlined } from "@ant-design/icons";
import Icon from "@mdi/react";
import { mdiMapMarkerAlertOutline, mdiThermometer } from "@mdi/js";
import {} from "@mdi/js";

const { TabPane } = Tabs;
var _ = require("lodash");
const { Option } = Select;
const { Panel } = Collapse;

const TransactionsETDRealTime = (props) => {
  const [tabContent, setTabContent] = useState("1");
  const { mainStore, transactionsStore } = useMobxStores();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isItemsModalOpen, setItemsIsModalOpen] = useState(false);
  const [itemsData, setItemsData] = useState([]);
  const [transactionData, setTransactionsData] = useState([]);

  const [coords, setCoords] = useState([]);
  const [transactionsListData, setTransactionsListData] = useState(
    transactionsStore.transactionsETDRealTime
  );

  const [filteredData, setFilteredData] = useState(
    transactionsStore.transactionsETDRealTime
  );
  const [shownClusterId, setShownClusterId] = useState("");

  const newContent = (key) => {
    setTabContent(key);
  };

  useEffect(() => {
    setTransactionsListData(transactionsStore.transactionsETDRealTime);
  }, [transactionsStore.transactionsETDRealTime]);

  useEffect(() => {
    console.log("transactionsListData");
    console.log(transactionsListData);

    if (transactionsStore.SelectedRecipeForETDReal === "All") {
      console.log("rereer");
      const allRecipesValue = Object.values(
        transactionsStore.transactionsETDRealTime
      ).reduce((acc, obj) => {
        return Object.assign(acc, obj); // This merges the objects without overwriting existing keys.
      }, {});
      setFilteredData(allRecipesValue);
    } else {
      if (transactionsListData[transactionsStore.SelectedRecipeForETDReal]) {
        console.log("ooooooo");
        setFilteredData(
          transactionsListData[transactionsStore.SelectedRecipeForETDReal]
        );
      } else {
        setFilteredData([]);
      }
    }
    console.log(filteredData);
  }, [transactionsStore.SelectedRecipeForETDReal]);

  const showModal = (record) => {
    // setReportModalData(record);
    console.log(record);
    // setTransactionsData(transactionDataTempt);
    let temp = [];
    setIsModalOpen(true);
    const keysArray = Object.values(record.transactions);
    console.log(keysArray);
    keysArray.map((item) => {
      // console.log(item);
      temp.push(item);
    });
    let transactionD = [];
    Object.entries(record.transactions).map((item) => {
      transactionD.push(item[1]);
    });
    setTransactionsData(keysArray);
    setShownClusterId(record.properties.clusterId);
    // console.log(temp);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showItemsModal = (record) => {
    setItemsData(record.items);
    setItemsIsModalOpen(true);
  };

  const handleItemsModalOk = () => {
    setItemsIsModalOpen(false);
  };
  const handleItemsModalCancel = () => {
    setItemsIsModalOpen(false);
  };
  let user = window.localStorage.getItem("user");
  user = JSON.parse(user);

  const itemColumns = [
    {
      title: "classificationViolation",
      responsive: ["lg", "sm", "md", "xs"],
      key: "classificationViolation",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.classificationViolation ? "true" : "false"}</>
        </p>
      ),
    },
    {
      title: "concentration",
      responsive: ["lg", "sm", "md", "xs"],
      key: "concentration",
      dataIndex: "concentration",
    },
    {
      title: "name",
      responsive: ["lg", "sm", "md", "xs"],
      key: "name",
      dataIndex: "name",
    },
    {
      title: "precursorName",
      responsive: ["lg", "sm", "md", "xs"],
      key: "precursorName",
      dataIndex: "precursorName",
    },
    {
      title: "price",
      responsive: ["lg", "sm", "md", "xs"],
      key: "price",
      dataIndex: "price",
    },
    {
      title: "quantity",
      responsive: ["lg", "sm", "md", "xs"],
      key: "quantity",
      dataIndex: "quantity",
    },
    {
      title: "quantityViolation",
      responsive: ["lg", "sm", "md", "xs"],
      key: "quantityViolation",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.quantityViolation ? "true" : "false"}</>
        </p>
      ),
    },
    {
      title: "regulationViolation",
      responsive: ["lg", "sm", "md", "xs"],
      key: "regulationViolation",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.regulationViolation ? "true" : "false"}</>
        </p>
      ),
    },
    {
      title: "totalquantity",
      responsive: ["lg", "sm", "md", "xs"],
      key: "totalquantity",
      dataIndex: "totalquantity",
    },
    {
      title: "unitsize",
      responsive: ["lg", "sm", "md", "xs"],
      key: "unitsize",
      dataIndex: "unitsize",
    },
    {
      title: "iscomponentof",
      responsive: ["lg", "sm", "md", "xs"],
      key: "iscomponentof",
      render: (record) =>
        record.iscomponentof.map((item) => {
          return <li>{item}</li>;
        }),
    },
  ];

  const transactions = [
    {
      title: "buyer",
      responsive: ["lg", "sm", "md", "xs"],
      key: "buyer",
      dataIndex: "buyer",
    },
    {
      title: "buyerlicence",
      responsive: ["lg", "sm", "md", "xs"],
      key: "buyerlicence",
      dataIndex: "buyerlicence",
    },
    {
      title: "cardNumber",
      responsive: ["lg", "sm", "md", "xs"],
      key: "cardNumber",
      dataIndex: "cardNumber",
    },
    {
      title: "country",
      responsive: ["lg", "sm", "md", "xs"],
      key: "country",
      dataIndex: "country",
    },
    {
      title: "hash",
      responsive: ["lg", "sm", "md", "xs"],
      key: "hash",
      dataIndex: "hash",
    },
    {
      title: "id",
      responsive: ["lg", "sm", "md", "xs"],
      key: "id",
      dataIndex: "id",
    },
    {
      title: "latitude",
      responsive: ["lg", "sm", "md", "xs"],
      key: "latitude",
      dataIndex: "latitude",
    },
    {
      title: "longitude",
      responsive: ["lg", "sm", "md", "xs"],
      key: "longitude",
      dataIndex: "longitude",
    },
    {
      title: "report",
      responsive: ["lg", "sm", "md", "xs"],
      key: "report",
      dataIndex: "report",
    },
    {
      title: "seller",
      responsive: ["lg", "sm", "md", "xs"],
      key: "seller",
      dataIndex: "seller",
    },
    {
      title: "suspicious",
      responsive: ["lg", "sm", "md", "xs"],
      key: "suspicious",
      dataIndex: "suspicious",
    },
    {
      title: "timedate",
      responsive: ["lg", "sm", "md", "xs"],
      key: "timedate",
      dataIndex: "timedate",
    },
    {
      title: "transactionType",
      responsive: ["lg", "sm", "md", "xs"],
      key: "transactionType",
      dataIndex: "transactionType",
    },
    {
      title: "items",
      responsive: ["lg", "sm", "md", "xs"],
      key: "items",
      width: "100%",
      render: (record) => {
        // console.log(record);
        return (
          <Space direction="vertical">
            <Button
              size="small"
              key={record + record}
              type="primary"
              onClick={() => showItemsModal(record)}
              disabled={false}
            >
              Transaction Items
            </Button>
          </Space>
        );
      },
    },
  ];

  const clusterColumns = [
    {
      title: "Cluster Id",
      responsive: ["lg", "sm", "md", "xs"],
      key: "clusterId",
      align: "center",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.properties.clusterId}</>
        </p>
      ),
    },
    {
      title: "confidence",
      responsive: ["lg", "xl"],
      key: "Buyer",
      align: "center",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.properties.confidence}</>
        </p>
      ),
    },
    {
      title: "count",
      responsive: ["lg", "xs"],
      key: "title",
      align: "center",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.properties.count}</>
        </p>
      ),
    },
    {
      title: "description",
      responsive: ["lg", "sm", "md", "xs"],
      key: "title",
      align: "center",

      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <>{record && record.properties.description}</>
        </p>
      ),
    },

    {
      title: "limit",
      responsive: ["lg", "xl"],
      key: "title",
      align: "center",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.properties.limit}</>
        </p>
      ),
    },
    {
      title: "transactions",
      responsive: ["lg", "sm", "md", "xs"],
      key: "action",
      width: "auto",
      align: "center",
      render: (record) => {
        let transactionDataTempt = [];
        Object.entries(record.transactions).map((item) => {
          transactionDataTempt.push(item[1]);
        });
        return (
          <Space direction="vertical">
            <Button
              size="small"
              key={record + record}
              type="primary"
              onClick={() => showModal(record, transactionDataTempt)}
              style={{ width: "100px" }}
              disabled={false}
            >
              transactions
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <Row justify="center" align="center">
        {transactionsStore.transactionsETDRealTime != {} && (
          <Col span={24} md={24} lg={24} xs={24} sm={24} xxl={24}>
            <div>
              <div
                style={{
                  marginTop: "30px",
                  border: "1px black solid",
                  overflowY: "auto",
                  height: "240px",
                  width: "100%",
                }}
              >
                <Drawer
                  title="Transactions"
                  onClose={handleCancel}
                  width={"100%"}
                  open={isModalOpen}
                  zIndex={{ zIndex: 999 }}
                >
                  <Table
                    rowKey={() => uuidv4()}
                    bordered={true}
                    scroll={{ x: "400px" }}
                    dataSource={transactionData}
                    columns={transactions}
                  />
                </Drawer>
                <Modal
                  width={"100%"}
                  open={isItemsModalOpen}
                  onCancel={handleItemsModalCancel}
                  onOk={handleItemsModalOk}
                  title={"Transactions Items"}
                  zIndex={100000}
                >
                  <Table
                    rowKey={() => uuidv4()}
                    bordered={true}
                    scroll={{ x: "400px" }}
                    dataSource={itemsData}
                    columns={itemColumns}
                  />
                </Modal>
                {Object.entries(filteredData)
                  // .slice(0, 2)
                  .map((value) => {
                    return value.map((cluster, index) => (
                      // <div>{cluster.name}</div>

                      <div key={index}>
                        {cluster && cluster.properties && (
                          <div>
                            <Row justify="center">
                              <span style={{ fontWeight: "bold" }}>
                                cluster id:
                              </span>
                              <span>{cluster.properties.clusterId}</span>
                            </Row>
                            <Divider
                              style={{
                                marginTop: "-2px",
                                marginBottom: "-2px",
                              }}
                            ></Divider>
                            <Row justify="space-between">
                              <span>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "20px",
                                  }}
                                >
                                  recipe:
                                </span>
                                <span>{cluster.properties.recipe}</span>
                              </span>
                              <span>
                                <span style={{ fontWeight: "bold" }}>
                                  confidence:
                                </span>
                                <span>
                                  {Number(
                                    cluster.properties.confidence
                                  ).toFixed(2)}
                                </span>
                              </span>

                              <span>
                                <span style={{ fontWeight: "bold" }}>
                                  count:
                                </span>
                                <span>{cluster.properties.count}</span>
                              </span>
                              <span
                                style={{
                                  marginRight: "20px",
                                }}
                              >
                                <Button
                                  type="default" // Set the button type to "default" to get a white button
                                  size="medium"
                                  onClick={() => showModal(cluster)}
                                  style={{
                                    backgroundColor: "#354289",
                                    color: "white",
                                    marginLeft: "6px",
                                  }} // Set the background color to white and font color to blue
                                >
                                  <Row align="center">
                                    <span></span>
                                    <span>View transactions</span>{" "}
                                  </Row>
                                </Button>
                              </span>
                            </Row>
                            <Divider
                              style={{
                                marginTop: "-2px",
                                marginBottom: "-2px",
                              }}
                            ></Divider>
                            <Row justify="start">
                              <span
                                style={{
                                  fontWeight: "bold",
                                  marginLeft: "20px",
                                }}
                              >
                                description:
                              </span>
                              <span>
                                <div>{cluster.properties.description} </div>
                              </span>
                            </Row>
                            <Divider
                              style={{
                                marginTop: "-2px",
                                marginBottom: "-2px",
                              }}
                            ></Divider>
                            <Row justify="start">
                              <span
                                style={{
                                  fontWeight: "bold",
                                  marginLeft: "20px",
                                }}
                              >
                                limit:
                              </span>
                              <span>
                                <div>{cluster.properties.limit} </div>
                              </span>
                            </Row>
                            <Divider
                              style={{
                                "background-color": "#90A4AE",
                                marginBottom: "-3px",
                                marginTop: "-3px",
                              }}
                            ></Divider>
                          </div>

                          // <div>{cluster.properties.count}</div>
                        )}
                      </div>
                    ));
                  })}
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default observer(TransactionsETDRealTime);
