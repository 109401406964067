import React, { useEffect, useState } from "react";
import { Col, Row, Divider, Button, Tag, Tooltip } from "antd";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import Loader from "../../components/loader/Loader";
import EmptyData from "../../components/emptydata/EmptyData";
import { mdiTranslate, mdiGraph, mdiOpenInNew } from "@mdi/js";
import Icon from "@mdi/react";
import { ReactComponent as TextSearchIcon } from "../../assets/textSearch.svg";
import moment from "moment";
import { FileTextOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import { mdiBomb } from "@mdi/js";
import { mdiImageSearch } from "@mdi/js";
import { mdiTextBoxSearchOutline } from "@mdi/js";

import { useParams } from "react-router-dom";

const TextTranslationPage = (props) => {
  const { mainStore, webCrawlStore } = useMobxStores();

  const [text_words, setTextWords] = useState([]);

  const [pageShown, setPageShown] = useState(1);
  const [itemShown, setItemShown] = useState("");

  const [content, setContent] = useState(null);
  const [filteredItems, setFilteredItems] = useState(
    webCrawlStore.filteredItems
  );
  const [loading, setLoading] = useState(false);
  const [loadingNewWeb, setLoadingNewWeb] = useState(false);
  const [clusterClicked, setClusterClicked] = useState("");
  const [filteredPagesByCluster, setFilteredPagesByCluster] = useState([]);

  var _ = require("lodash");
  let params = useParams();

  const [itemsToShow, setItemsToShow] = useState([]);

  useEffect(() => {
    setFilteredItems(webCrawlStore.filteredItems);
  }, [webCrawlStore.filteredItems]);
  useEffect(() => {
    setPageShown(webCrawlStore.webCrawlPaginationPage);
  }, [webCrawlStore.webCrawlPaginationPage]);

  const showReport = (item) => {
    webCrawlStore.setWebCrawlPaginationPageToReport(item);
  };

  return (
    <div>
      {filteredItems
        .slice((pageShown - 1) * 4, (pageShown - 1) * 4 + 4)
        .map((item, index) => {
          return (
            <div>
              <div
                style={{
                  border: "2px   #B0BEC5 solid",
                  borderRadius: "6px",
                  margin: "4px",
                  // marginTop: "1px",
                }}
                key={index}
              >
                <Row justify="center" align="center" gutter={[20, 20]}>
                  <Col span={24} lg={24} md={24} xs={24} sm={24}>
                    <Row>
                      <Col span={21} lg={21} md={21} xs={21} sm={21}>
                        <div
                          style={{
                            marginTop: "1px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              marginLeft: "4px",
                              fontWeight: "bold",
                            }}
                          >
                            Url:
                            <a
                              href={"https://" + item.name}
                              // target="OpenWikipediaWindow"
                            >
                              {item.name && item.name.length > 50 && (
                                <span>
                                  <span>
                                    {" "}
                                    <Tooltip title={item.name}>
                                      {item.name.substring(0, 50)}
                                    </Tooltip>
                                  </span>
                                  <span style={{ marginLeft: "6px" }}>
                                    {" "}
                                    ...{" "}
                                  </span>
                                </span>
                              )}
                              {item.name && item.name.length <= 50 && (
                                <Tooltip title={item.name}>{item.name}</Tooltip>
                              )}
                            </a>
                          </span>
                        </div>
                        <div style={{ marginTop: "1px" }}>
                          <span
                            style={{
                              marginLeft: "4px",
                              fontWeight: "bold",
                            }}
                          >
                            Title:
                          </span>
                          <span> {item.title}</span>
                        </div>
                        <div style={{ marginTop: "1px" }}>
                          <Row>
                            <span>
                              <span style={{ fontWeight: "bold" }}>
                                {item.classification && (
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      marginLeft: "4px",
                                    }}
                                  >
                                    <Tag
                                      style={{
                                        width: "120px",
                                        height: "28px",
                                      }}
                                      color="orange"
                                    >
                                      <Row>
                                        <span>
                                          <Icon path={mdiBomb} size={1} />
                                        </span>
                                        <span>{item.classification}</span>
                                      </Row>
                                    </Tag>
                                  </span>
                                )}
                              </span>
                            </span>
                            <span>
                              {item.images.preview && (
                                <span style={{ fontWeight: "bold" }}>
                                  <Tag
                                    style={{ width: "146px", height: "28px" }}
                                    color="green"
                                  >
                                    <Row>
                                      <span>
                                        <Icon path={mdiImageSearch} size={1} />
                                      </span>
                                      <span>Visual Analysis</span>
                                    </Row>
                                  </Tag>
                                </span>
                              )}
                            </span>
                            <span>
                              {item.attributes.translation && (
                                <span style={{ fontWeight: "bold" }}>
                                  <Tag
                                    style={{ width: "130px", height: "28px" }}
                                    color="blue"
                                  >
                                    <Row>
                                      <span>
                                        <Icon path={mdiTranslate} size={1} />
                                      </span>
                                      <span>Translation</span>
                                    </Row>
                                  </Tag>
                                </span>
                              )}
                            </span>
                          </Row>
                        </div>
                      </Col>
                      <Col span={3} lg={3} md={3} xs={3} sm={3}>
                        <div
                          style={{
                            display: "flex", // Enable flexbox on Col
                            justifyContent: "center", // Horizontally center the button
                            alignItems: "center", // Vertically center the button
                            height: "100%", // Ensure Col height takes up space
                          }}
                        >
                          <Button
                            type="default"
                            onClick={() => {
                              showReport(item);
                            }}
                            size="medium"
                            style={{
                              width: "50px",
                              backgroundColor: "#354289",
                              color: "white",
                            }}
                          >
                            <span>
                              <Icon path={mdiTextBoxSearchOutline} size={1} />
                            </span>
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>

              {/* <Divider
              style={{
                "background-color": "#90A4AE",

                // borderTop: "2px #B0BEC5 solid",
                // borderRadius: "6px",
                marginBottom: "5px",
                marginTop: "-3px",
              }}
            ></Divider> */}
            </div>
          );
        })}
    </div>
  );
};

export default observer(TextTranslationPage);
