import React, { useState } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  Table,
  Divider,
  DatePicker,
  Space,
  Spin,
  Modal,
  Drawer,
} from "antd";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";
import {
  mdiEarth,
  mdiHandshakeOutline,
  mdiWeb,
  mdiTruck,
  mdiReceiptTextOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import TransactionMap from "./TransactionMap";

import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { useParams } from "react-router-dom";

const { RangePicker } = DatePicker;

var _ = require("lodash");

const SearchForm = (props) => {
  const { mainStore } = useMobxStores();
  const [isSTDItemsModalOpen, setItemsIsModalOpen] = useState(false);
  const [itemsData, setItemsData] = useState([]);
  const [showmap, setShowMap] = useState(false);
  const [coords, setCoords] = useState([[10], [10]]);
  let params = useParams();

  const showSTDItemsModal = (value) => {
    setItemsIsModalOpen(true);
    setItemsData(value.items);
  };

  const closeMap = () => {
    mainStore.setSupplyChain([]);
    setShowMap(false);
  };

  const handleSTDItemsModalOk = () => {
    setItemsIsModalOpen(false);
  };
  const handleSTDItemsModalCancel = () => {
    setItemsIsModalOpen(false);
  };
  const OnMap = (record) => {
    console.log("fd");
    console.log(record);
    mainStore.Publish({
      topics: ["ETD_GET_SUPPLY_CHAIN"],
      body: JSON.stringify({
        header: {
          topicName: "ETD_GET_SUPPLY_CHAIN",
          sender: "INTRA",
          sentUtc: moment().format("yyyy-MM-dd'T'HH:mm:ss'Z'"),
          source: "INTRA",
          caseId: params.pk,
        },
        body: {
          transactions: [record.id.toString()],
        },
      }),
    });

    setShowMap(true);
    setCoords([[record.latitude], [record.longitude]]);
  };

  const itemColumns = [
    {
      title: "Classification Violation",
      responsive: ["lg", "sm", "md", "xs"],
      key: "classification Violation",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.classificationViolation ? "true" : "false"}</>
        </p>
      ),
    },
    {
      title: "concentration",
      responsive: ["lg", "sm", "md", "xs"],
      key: "concentration",
      dataIndex: "concentration",
    },
    {
      title: "name",
      responsive: ["lg", "sm", "md", "xs"],
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Precursor Name",
      responsive: ["lg", "sm", "md", "xs"],
      key: "precursorName",
      dataIndex: "precursorName",
    },
    {
      title: "price",
      responsive: ["lg", "sm", "md", "xs"],
      key: "price",
      dataIndex: "price",
    },
    {
      title: "quantity",
      responsive: ["lg", "sm", "md", "xs"],
      key: "quantity",
      dataIndex: "quantity",
    },
    {
      title: "Quantity Violation",
      responsive: ["lg", "sm", "md", "xs"],
      key: "quantityViolation",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.quantityViolation ? "true" : "false"}</>
        </p>
      ),
    },
    {
      title: "Regulation Violation",
      responsive: ["lg", "sm", "md", "xs"],
      key: "regulationViolation",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.regulationViolation ? "true" : "false"}</>
        </p>
      ),
    },
    {
      title: "Total Quantity",
      responsive: ["lg", "sm", "md", "xs"],
      key: "totalquantity",
      // dataIndex: "totalquantity",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && Number(record.totalquantity).toFixed(4)}</>
        </p>
      ),
    },
    {
      title: "unitsize",
      responsive: ["lg", "sm", "md", "xs"],
      key: "unitsize",
      dataIndex: "unitsize",
    },
    {
      title: "Component of",
      responsive: ["lg", "sm", "md", "xs"],
      key: "iscomponentof",
      render: (record) =>
        record.iscomponentof.map((item) => {
          return <li>{item}</li>;
        }),
    },
  ];

  const columns = [
    {
      title: "",
      responsive: ["lg", "sm", "md", "xs"],
      key: "suspicious",
      render: (record) => (
        <>
          {record.suspicious === 1 && (
            <div
              style={{
                backgroundColor: "red",
                width: "20px",
                height: "20px",
              }}
            ></div>
          )}
          {record.suspicious === 0 && (
            <div
              style={{
                backgroundColor: "green",
                width: "20px",
                height: "20px",
              }}
            ></div>
          )}
        </>
      ),
    },
    {
      title: "",
      responsive: ["lg", "sm", "md", "xs"],
      key: "transactionType",
      render: (record) => (
        <p style={{ width: "30px", height: "30px" }}>
          <>
            {record.transactionType && record.transactionType === "Online" && (
              <Icon path={mdiWeb}></Icon>
            )}
            {record.transactionType &&
              record.transactionType === "Distributor" && (
                <Icon path={mdiTruck}></Icon>
              )}
            {record.transactionType &&
              record.transactionType === "In Person" && (
                <Icon path={mdiHandshakeOutline}></Icon>
              )}
          </>
        </p>
      ),
    },
    {
      title: "Buyer info",
      responsive: ["lg", "sm", "md", "xs"],
      key: "buyer",
      render: (record) => (
        <div>
          <p style={{ marginTop: "-2px" }}>
            <>{record.buyer}</>
          </p>
          <p style={{ marginTop: "-12px" }}>
            <>{record.buyerlicence && record.buyerlicence}</>
          </p>
          <p style={{ marginTop: "-12px", marginBottom: "-12px" }}>
            <>{record.cardNumber && record.cardNumber}</>
          </p>
        </div>
      ),
    },
    // {
    //   title: "Buyer licence",
    //   responsive: ["lg", "sm", "md", "xs"],
    //   key: "buyerlicence",
    //   render: (record) => (
    //     <p>
    //       <>{record.buyerlicence && record.buyerlicence}</>
    //     </p>
    //   ),
    // },
    // {
    //   title: "cardNumber",
    //   responsive: ["lg", "sm", "md", "xs"],
    //   key: "buyerlicence",
    //   render: (record) => (
    //     <p>
    //       <>{record.cardNumber && record.cardNumber}</>
    //     </p>
    //   ),
    // },
    {
      title: "Location",
      responsive: ["lg", "sm", "md", "xs"],
      key: "country",
      render: (record) => (
        <div>
          <p>
            <>{record.country && record.country}</>
          </p>
          <p>
            <Icon path={mdiEarth} size={1} />
          </p>
        </div>
      ),
    },
    // {
    //   title: "Item description",
    //   responsive: ["lg", "sm", "md", "xs"],
    //   key: "item_description",
    //   render: (record) => (
    //     <p>
    //       <>{record.item_description && record.item_description[0]}</>
    //     </p>
    //   ),
    // },
    {
      title: "hash",
      responsive: ["lg", "sm", "md", "xs"],
      key: "hash",
      render: (record) => (
        <p>
          <>{record.hash && record.hash}</>
        </p>
      ),
    },
    {
      title: "id",
      responsive: ["lg", "sm", "md", "xs"],
      key: "id",
      render: (record) => (
        <p>
          <>{record.id && record.id}</>
        </p>
      ),
    },
    // {
    //   title: "Latitude",
    //   responsive: ["lg", "sm", "md", "xs"],
    //   key: "latitude",
    //   render: (record) => (
    //     <p>
    //       <>{record.latitude && record.latitude}</>
    //     </p>
    //   ),
    // },
    // {
    //   title: "Longituded",
    //   responsive: ["lg", "sm", "md", "xs"],
    //   key: "longitude",
    //   render: (record) => (
    //     <p>
    //       <>{record.longitude && record.longitude}</>
    //     </p>
    //   ),
    // },
    {
      title: "Seller",
      responsive: ["lg", "sm", "md", "xs"],
      key: "seller",
      render: (record) => (
        <p>
          <>{record.seller && record.seller}</>
        </p>
      ),
    },

    {
      title: "Timedate",
      responsive: ["lg", "sm", "md", "xs"],
      key: "timedate",
      render: (record) => (
        <p>
          <>{record.timedate && record.timedate}</>
        </p>
      ),
    },

    {
      title: "",
      responsive: ["lg", "sm", "md", "xs"],
      key: "items",
      render: (record) => {
        // console.log(record);
        return (
          <>
            <Button
              size="small"
              key={() => uuidv4()}
              type="primary"
              onClick={() => showSTDItemsModal(record)}
              disabled={false}
            >
              View Items
            </Button>
          </>
        );
      },
    },
  ];

  const onFinish = (values) => {
    mainStore.setIsSearching(true);
    let send = {
      header: {
        topicName: "TRANSACTION_SEARCH",
        sender: "INTRA",
        sentUtc: "2023-01-01T00:0:00+0000",
        source: "Dashboard",
        // caseId: "86b8b6bd42ce110000000900",
        caseId: props.projectId,
      },
      body: {
        criteria: {
          // seller: values.name || "",
          seller: values.seller || "",
          buyer: values.buyer || "",
          dateTime: {
            from: values.start_date.format("DD-MM-YYYY") || "",
            to: values.end_date.format("DD-MM-YYYY") || "",
          },
          geo: {
            lat: values.lat || "",
            long: values.lon || "",
            threshold: values.threshold || "",
          },
          substance: values.substance || "",
        },
      },
    };

    mainStore.Publish({
      topics: ["TRANSACTION_SEARCH"],
      body: JSON.stringify(send),
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // Example usage
  // const latitude = 37.7749; // Replace with actual latitude
  // const longitude = -122.4194; // Replace with actual longitude

  return (
    <div>
      {/* <Divider>Search Transactions</Divider> */}
      <Modal
        width={"100%"}
        open={isSTDItemsModalOpen}
        onCancel={handleSTDItemsModalCancel}
        onOk={handleSTDItemsModalOk}
        title={"Transaction Items"}
      >
        <Table
          rowKey={() => uuidv4()}
          bordered={true}
          scroll={{ x: "400px" }}
          dataSource={itemsData}
          columns={itemColumns}
        />
      </Modal>
      <Form
        title="Search"
        name="search-form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
        style={{
          maxWidth: "none",
        }}
      >
        {/* <Space direction="horizontal">
          <Form.Item
            label="Start date"
            name="start_date"
            rules={[
              {
                required: true,
                message: "Please input date!",
              },
            ]}
          >
            <DatePicker format={"DD/MM/YYYY"} />
          </Form.Item>
          <Form.Item
            label="End date"
            name="end_date"
            rules={[
              {
                required: true,
                message: "Please input date!",
              },
            ]}
          >
            <DatePicker format={"DD/MM/YYYY"} />
          </Form.Item>
        </Space> */}

        <Row align={"middle"} gutter={[20, 20]}>
          <Col span={3}>
            <Form.Item
              label="Start date"
              name="start_date"
              rules={[
                {
                  required: true,
                  message: "Please input date!",
                },
              ]}
            >
              <DatePicker format={"DD/MM/YYYY"} />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              label="End date"
              name="end_date"
              rules={[
                {
                  required: true,
                  message: "Please input date!",
                },
              ]}
            >
              <DatePicker format={"DD/MM/YYYY"} />
            </Form.Item>
          </Col>
          <Divider style={{ height: "60px" }} type="vertical"></Divider>
          <Col span={3}>
            {" "}
            <Form.Item
              style={{ marginTop: "-6px" }}
              name="buyer"
              rules={[
                {
                  required: false,
                  message: "Please input buyer!",
                },
              ]}
            >
              <Input placeholder="Buyer" />
            </Form.Item>{" "}
            <Form.Item
              style={{ marginTop: "-18px" }}
              name="seller"
              rules={[
                {
                  required: false,
                  message: "Please input your seller!",
                },
              ]}
            >
              <Input placeholder="Seller" />
            </Form.Item>
          </Col>
          <Divider style={{ height: "60px" }} type="vertical"></Divider>
          <Col span={3}>
            {" "}
            <Form.Item
              style={{ marginTop: "-6px" }}
              name="lat"
              rules={[
                {
                  required: false,
                  message: "Please input lat!",
                },
              ]}
            >
              <Input placeholder="Latitude" />
            </Form.Item>{" "}
            <Form.Item
              style={{ marginTop: "-18px" }}
              name="lon"
              rules={[
                {
                  required: false,
                  message: "Please input lon!",
                },
              ]}
            >
              <Input placeholder="Longitude" />
            </Form.Item>
          </Col>
          <Col span={3}>
            {" "}
            <Form.Item
              style={{ marginTop: "30px" }}
              name="threshold"
              rules={[
                {
                  required: false,
                  message: "Please input threshold!",
                },
              ]}
            >
              <Input placeholder="Threshold" />
            </Form.Item>
          </Col>
          <Divider style={{ height: "60px" }} type="vertical"></Divider>
          <Col span={3}>
            {" "}
            <Form.Item
              style={{ marginTop: "30px" }}
              name="substance"
              rules={[
                {
                  required: false,
                  message: "Please input substance!",
                  type: "string",
                },
              ]}
            >
              <Input placeholder="Substance" />
            </Form.Item>
          </Col>

          <Col span={2}>
            {" "}
            <Form.Item
              wrapperCol={{
                offset: 2,
                span: 4,
              }}
              style={{ marginTop: "30px" }}
            >
              <Button
                type="default"
                htmlType="submit"
                size="medium"
                style={{
                  backgroundColor: "#354289",
                  color: "white",
                }}
              >
                <div align="center">
                  <span>
                    <SearchOutlined
                      style={{
                        fontSize: "20px",
                        marginRight: "4px",
                        color: "white",
                      }}
                    />
                  </span>
                  <span>Search</span>
                </div>
              </Button>

              {/* <Button type="primary" htmlType="submit">
                Search
              </Button> */}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {!mainStore.isSearching ? (
        <div>
          <div
            class="showOnPrintAllHeight etdSearch"
            style={{
              border: "1px black solid",
              overflowY: "auto",
              // height: "100%",
              // minHeight: "400px",
              // maxHeight: "800px",
              width: "100%",
            }}
          >
            {mainStore.searchedData.map((item, index) => {
              return (
                <div
                  style={{
                    // borderTop: "2px #B0BEC5 solid",
                    // borderRadius: "6px",
                    marginBottom: "1px",
                    marginTop: "1px",
                  }}
                  key={index}
                >
                  <Row justify="start">
                    <span style={{ width: "2%", marginLeft: "4px" }}>
                      <div
                        style={{
                          marginTop: "100%",
                        }}
                      >
                        {" "}
                        {item.suspicious === 1 && (
                          <div
                            style={{
                              backgroundColor: "red",
                              width: "20px",
                              height: "20px",
                            }}
                          ></div>
                        )}
                        {item.suspicious === 0 && (
                          <div
                            style={{
                              backgroundColor: "green",
                              width: "20px",
                              height: "20px",
                            }}
                          ></div>
                        )}
                      </div>
                    </span>
                    <Divider
                      style={{ height: "60px" }}
                      type="vertical"
                    ></Divider>
                    <span style={{ width: "2%" }}>
                      <p style={{ width: "24px", height: "24px" }}>
                        <>
                          {item.transactionType &&
                            item.transactionType === "Online" && (
                              <Icon path={mdiWeb}></Icon>
                            )}
                          {item.transactionType &&
                            item.transactionType === "Distributor" && (
                              <Icon path={mdiTruck}></Icon>
                            )}
                          {item.transactionType &&
                            item.transactionType === "In Person" && (
                              <Icon path={mdiHandshakeOutline}></Icon>
                            )}
                        </>
                      </p>
                    </span>
                    <Divider
                      style={{ height: "60px" }}
                      type="vertical"
                    ></Divider>
                    <span style={{ width: "9%" }}>
                      <Row>
                        <Col span={24}>
                          <div>{item.country} </div>
                          {/* <div>{item.latitude} </div>
                          <div>{item.longitude} </div> */}
                          <div>
                            <Button
                              type="default" // Set the button type to "default" to get a white button
                              onClick={() => OnMap(item)} // Handle the click event
                              size="small"
                              style={{
                                backgroundColor: "#354289",
                                color: "white",
                                marginLeft: "6px",
                                height: "26px",
                              }} // Set the background color to white and font color to blue
                            >
                              <Row align="center">
                                <span>
                                  {/* <RollbackOutlined
                          style={{ fontSize: "20px", marginRight: "4px" }}
                        /> */}
                                  <Icon
                                    style={{ width: "18px" }}
                                    path={mdiEarth}
                                  ></Icon>{" "}
                                </span>
                                <span
                                  style={{
                                    marginLeft: "2px",
                                  }}
                                >
                                  Map
                                </span>{" "}
                              </Row>
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </span>
                    <Divider
                      style={{ height: "60px" }}
                      type="vertical"
                    ></Divider>
                    <span style={{ width: "9%" }}>
                      <Row>
                        <Col span={24}>{item.timedate}</Col>
                      </Row>
                    </span>
                    <Divider
                      style={{ height: "60px" }}
                      type="vertical"
                    ></Divider>
                    <span style={{ width: "22%" }}>
                      <div>
                        {" "}
                        <Row>
                          <Col style={{ fontWeight: "bold" }} span={8}>
                            Buyer :
                          </Col>
                          <Col span={10}>{item.buyer}</Col>
                        </Row>
                      </div>
                      <div>
                        {" "}
                        <Row>
                          <Col style={{ fontWeight: "bold" }} span={8}>
                            Licence :
                          </Col>
                          <Col span={10}>{item.buyerlicence}</Col>
                        </Row>
                      </div>
                      <div>
                        {" "}
                        <Row>
                          <Col style={{ fontWeight: "bold" }} span={8}>
                            Card no. :
                          </Col>
                          <Col span={10}>{item.cardNumber}</Col>
                        </Row>
                      </div>
                    </span>
                    <Divider
                      style={{ height: "60px" }}
                      type="vertical"
                    ></Divider>
                    <span style={{ width: "22%" }}>
                      <div
                        style={{
                          marginTop: "5%",
                        }}
                      >
                        {" "}
                        <Row>
                          <Col style={{ fontWeight: "bold" }} span={6}>
                            Seller :
                          </Col>
                          <Col span={18}>{item.seller}</Col>
                        </Row>
                      </div>
                    </span>
                    <Divider
                      style={{ height: "60px" }}
                      type="vertical"
                    ></Divider>
                    <span style={{ width: "13%" }}>
                      <div
                        style={{
                          marginTop: "5%",
                        }}
                      >
                        {" "}
                        <Row>
                          <Col style={{ fontWeight: "bold" }} span={9}>
                            Id :
                          </Col>
                          <Col span={15}>{item.id}</Col>
                        </Row>
                        <Row>
                          <Col style={{ fontWeight: "bold" }} span={9}>
                            Hash :
                          </Col>
                          <Col span={15}>{item.hash}</Col>
                        </Row>
                      </div>
                    </span>
                    <Divider
                      style={{ height: "60px", marginLeft: "-6px" }}
                      type="vertical"
                    ></Divider>
                    <span style={{ width: "4%" }}>
                      <div style={{ marginTop: "40%" }}>
                        <Button
                          type="default"
                          // onClick={() => showSTDItemsModal(item)}
                          // onClick={this.handleClick}
                          onClick={() => props.onClick(item)}
                          size="small"
                          style={{
                            backgroundColor: "#354289",
                            color: "white",
                            display: "flex", // Use flexbox
                            alignItems: "center", // Align items vertically
                            gap: "4px", // Add gap between icon and text
                            paddingLeft: "8px", // Add left padding for icon alignment
                            paddingRight: "8px", // Add right padding for icon alignment
                            height: "26px",
                            marginLeft: "-2px",
                          }}
                        >
                          <Icon path={mdiReceiptTextOutline} size={1} />
                          <div style={{ marginLeft: "-2px" }}>Details</div>
                        </Button>
                      </div>
                    </span>
                  </Row>
                  <Divider
                    style={{
                      "background-color": "#90A4AE",

                      // borderTop: "2px #B0BEC5 solid",
                      // borderRadius: "6px",
                      marginBottom: "5px",
                      marginTop: "-3px",
                    }}
                  ></Divider>
                </div>
              );
            })}
          </div>
          <div> </div>{" "}
          <Row
            style={{
              marginTop: "6px",
              // marginBottom: "-16px",
            }}
          >
            <span>
              <div
                style={{
                  backgroundColor: "red",
                  width: "20px",
                  height: "20px",
                }}
              ></div>
            </span>
            <span
              style={{
                marginLeft: "6px",
              }}
            >
              {" "}
              = suspicious transaction ,
            </span>
            <span>
              <div
                style={{
                  backgroundColor: "green",
                  width: "20px",
                  height: "20px",
                }}
              ></div>
            </span>
            <span
              style={{
                marginLeft: "6px",
              }}
            >
              {" "}
              = Not suspicious transaction ,
            </span>

            <span
              style={{
                width: "26px",
              }}
            >
              <Icon path={mdiHandshakeOutline}></Icon>
            </span>
            <span
              style={{
                marginLeft: "6px",
              }}
            >
              {" "}
              = Done in person ,
            </span>
            <span
              style={{
                width: "26px",
              }}
            >
              <Icon path={mdiWeb}></Icon>
            </span>
            <span
              style={{
                marginLeft: "6px",
              }}
            >
              {" "}
              = Done online ,
            </span>
            <span
              style={{
                width: "26px",
              }}
            >
              <Icon path={mdiTruck}></Icon>
            </span>
            <span
              style={{
                marginLeft: "6px",
              }}
            >
              {" "}
              = Done by a Distributor
            </span>
          </Row>
        </div>
      ) : (
        // <Table
        //   style={{ marginTop: "20px" }}
        //   columns={columns}
        //   dataSource={_.cloneDeep(mainStore.searchedData)}
        //   rowKey={(record) => record.id}
        //   bordered={true}
        //   scroll={{ x: "400px" }}
        //   rowClassName="custom-rowTrans"
        // />
        <Row style={{ textAlign: "center" }}>
          <Col span={24} style={{ textAlign: "center" }}>
            <Spin tip="Searching..." />
          </Col>
        </Row>
      )}
      <Drawer
        title="Transaction on Map"
        placement="right"
        size={"large"}
        onClose={() => closeMap()}
        open={showmap}
        headerStyle={{ textAlign: "center" }}
      >
        <TransactionMap key={coords[0][0]} coords={coords} />
      </Drawer>
    </div>
  );
};
export default observer(SearchForm);
