import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import { useMobxStores } from "../../stores/stores";
import { Modal, Row, Button, Spin } from "antd";
import { constants } from "../../consts/Variables";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-svg-shape-markers";
import "./leaflet-starcircle.js";
import "./fakeATD.js";
// import addressPoints from "./fakeATD.js";

const LeafletMap = () => {
  const { mainStore } = useMobxStores();
  const [addressPoints, setAddressPoints] = useState("");
  const [centerLat, setCenterLat] = useState("");
  const [centerLong, setCenterLong] = useState("");
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [startPage, setStartPage] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false); // Close modal
    mainStore.errorATDL = false;
  };

  const handleCancel = () => {
    setIsModalOpen(false); // Close modal
    mainStore.errorATDL = false;
  };

  useEffect(() => {
    console.log(mainStore.errorATDL);
    console.log("mainStore.errorATDL");
    if (mainStore.errorATDL) {
      setIsModalOpen(true);
      setSpinner(false);

      setStartPage(true);
    }
  }, [mainStore.errorATDL]);

  // useEffect(() => {
  //   mainStore.errorATDL = false;
  // }, []);

  const radius = 49.8;

  useEffect(() => {
    console.log("ijmmmmmmmm");
    if (mainStore.addressPoints.length > 0) {
      console.log("dfsdf");
      setAddressPoints(mainStore.addressPoints);
      const initialValue = 0;
      const sumOfLats = mainStore.addressPoints
        .map((point) => {
          return point[0];
        })
        .reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          initialValue
        );
      const sumOfLongs = mainStore.addressPoints
        .map((point) => {
          return point[1];
        })
        .reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          initialValue
        );
      let CalculatedCenterLat = parseFloat(
        (sumOfLats / mainStore.addressPoints.length).toFixed(7)
      );
      let CalculatedCenterLong = parseFloat(
        (sumOfLongs / mainStore.addressPoints.length).toFixed(7)
      );
      let CalculatedMinValue = mainStore.addressPoints[0][2];
      let CalculatedMaxValue =
        mainStore.addressPoints[mainStore.addressPoints.length - 1][2];

      setCenterLat(CalculatedCenterLat);
      setCenterLong(CalculatedCenterLong);
      setMinValue(CalculatedMinValue);
      setMaxValue(CalculatedMaxValue);
      setSpinner(false);
    }
  }, [mainStore.addressPoints]);

  // useEffect(() => {
  //   if (mainStore.errorATDL === true) {
  //     setIsModalOpen(true);
  //   } else {
  //     setIsModalOpen(false);
  //   }
  // }, [mainStore.errorATDL]);

  const calculateSquareCorners = (centerLat, centerLng) => {
    const earthRadius = 6378137; // Radius of the Earth in meters

    // Convert radius from meters to degrees
    const deltaLat = (radius / earthRadius) * (180 / Math.PI);
    const deltaLng =
      (radius / (earthRadius * Math.cos((Math.PI * centerLat) / 180))) *
      (180 / Math.PI);

    // Calculate the four corners
    const topLeft = [centerLat + deltaLat, centerLng - deltaLng]; // Top-left
    const bottomLeft = [centerLat - deltaLat, centerLng - deltaLng]; // Bottom-left (SW)
    const bottomRight = [centerLat - deltaLat, centerLng + deltaLng]; // Bottom-right
    const topRight = [centerLat + deltaLat, centerLng + deltaLng]; // Top-right (NE)

    return [topLeft, bottomLeft, bottomRight, topRight, topLeft];
  };

  const valueScale = (value) => {
    return parseFloat(((value - minValue) / maxValue).toFixed(7));
  };

  const showHistoricalAnalysis = () => {
    setSpinner(true);
    setStartPage(false);
    mainStore.GetHistoricalATDLData();
  };

  const startAnalysis = () => {
    setSpinner(true);
    setStartPage(false);
    mainStore.GetRealTimeATDLData();

    // return parseFloat(((value - minValue) / maxValue).toFixed(7));
  };

  const getColor = (value) => {
    // Ensure the value is between 0 and 1

    // if (value < 0 || value > 1) {
    //   throw new Error("Value must be between 0 and 1");
    // }

    // Convert hex to RGB
    function hexToRgb(hex) {
      const bigint = parseInt(hex.slice(1), 16);
      return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
    }

    // Convert RGB to hex
    function rgbToHex(r, g, b) {
      return (
        "#" +
        [r, g, b]
          .map((x) => {
            const hex = x.toString(16);
            return hex.length === 1 ? "0" + hex : hex;
          })
          .join("")
      );
    }

    // Interpolate between two values
    function interpolate(start, end, factor) {
      return start + (end - start) * factor;
    }

    const colorStops = [
      { position: 0, color: "#37b8ff" }, // 0%
      // { position: 0.2, color: "#37b8ff" }, // 19%
      { position: 0.3, color: "#fffa00" }, // 52%
      { position: 0.65, color: "#e5562b" }, // 79%
      { position: 1, color: "#c60000" }, // 100%
    ];

    // Find the segment in which the value falls
    for (let i = 0; i < colorStops.length - 1; i++) {
      if (
        value >= colorStops[i].position &&
        value <= colorStops[i + 1].position
      ) {
        const startColor = hexToRgb(colorStops[i].color);
        const endColor = hexToRgb(colorStops[i + 1].color);
        const segmentRange =
          colorStops[i + 1].position - colorStops[i].position;
        const segmentValue = (value - colorStops[i].position) / segmentRange;

        const r = Math.round(
          interpolate(startColor[0], endColor[0], segmentValue)
        );
        const g = Math.round(
          interpolate(startColor[1], endColor[1], segmentValue)
        );
        const b = Math.round(
          interpolate(startColor[2], endColor[2], segmentValue)
        );

        return rgbToHex(r, g, b);
      }
    }

    // Fallback to return the first color if the value is somehow out of bounds
    return colorStops[0].color;
  };

  const StarCircleLayer = () => {
    const map = useMap();

    useEffect(() => {
      if (map && addressPoints.length > 0) {
        for (let i = 0; i < addressPoints.length; i++) {
          // var latlngs = [addressPoints[i][0], addressPoints[i][1]];

          var polyline = L.polygon(
            calculateSquareCorners(addressPoints[i][0], addressPoints[i][1]),
            {
              opacity: 0.0,
              fillColor: getColor(valueScale(addressPoints[i][2])), // Set the fill color to red
              fillOpacity: 0.3,
            }
          ).addTo(map);
        }

        return () => {};
      }
    }, [map, addressPoints]);

    return null;
  };
  return (
    <div style={{ width: "100%" }}>
      <Modal title="Error" open={isModalOpen} closable={false} footer={null}>
        <div>Dispersion model still running. Please try again later</div>
        <div>
          <Row justify="end">
            <span>
              <Button
                type="default" // Set the button type to "default" to get a white button
                onClick={handleOk}
                // Handle the click event
                size="medium"
                style={{
                  backgroundColor: "#354289",
                  color: "white",
                  marginLeft: "6px",
                }} // Set the background color to white and font color to blue
              >
                <Row align="center">
                  <span>
                    {/* <RollbackOutlined
    style={{
      fontSize: "20px",
      marginRight: "4px",
    }}
  /> */}
                  </span>
                  <span>Ok</span>{" "}
                </Row>
              </Button>
            </span>
          </Row>
        </div>
      </Modal>{" "}
      {centerLat &&
        centerLong &&
        minValue &&
        maxValue &&
        !spinner &&
        !startPage && (
          <div>
            {" "}
            <MapContainer
              center={[centerLat, centerLong]}
              zoom={16}
              style={{ height: "380px", width: "100%" }}
            >
              <TileLayer
                url={
                  constants.ONLINE == "true"
                    ? "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    : `https://map.romania.odysseus-offline.rid-intrasoft.eu/tile/{z}/{x}/{y}.png`
                }
                // url= "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <StarCircleLayer />
            </MapContainer>{" "}
          </div>
        )}
      {spinner && !startPage && (
        <div>
          {" "}
          <div style={{ width: "100%", height: "50vh" }}>
            <Row justify="center">
              <span>
                <Spin style={{ marginTop: "200px" }} />
              </span>
            </Row>
          </div>
        </div>
      )}
      {!spinner && startPage && (
        <div
          style={{
            height: "200px",
          }}
        >
          {" "}
          <div
            style={{
              display: "flex",
              marginTop: "200px",
              // alignItems: "center", // Center vertically
              justifyContent: "center",
              // height: "300px",
              width: "100%",
            }}
          >
            No analysis requested yet!
          </div>
        </div>
      )}
      <div
        style={{
          marginBottom: "400px",

          width: "100%",
        }}
      >
        <Row
          style={{
            display: "flex",
            marginTop: "20px",

            // alignItems: "center", // Center vertically
            justifyContent: "space-between",
            // height: "300px",
            width: "100%",
          }}
        >
          <span>
            <Button
              type="default" // Set the button type to "default" to get a white button
              onClick={startAnalysis}
              // Handle the click event
              size="medium"
              style={{
                backgroundColor: "#354289",
                color: "white",
                marginLeft: "6px",
              }} // Set the background color to white and font color to blue
            >
              <Row align="center">
                <span></span>
                <span>Show historical analysis</span>{" "}
              </Row>
            </Button>
          </span>
          <span>
            <Button
              type="default" // Set the button type to "default" to get a white button
              onClick={showHistoricalAnalysis}
              // Handle the click event
              size="medium"
              style={{
                backgroundColor: "#354289",
                color: "white",
                marginLeft: "6px",
              }} // Set the background color to white and font color to blue
            >
              <Row align="center">
                <span></span>
                <span>Start analysis</span>{" "}
              </Row>
            </Button>
          </span>
        </Row>
      </div>
    </div>
  );
};

export default observer(LeafletMap);
