import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Divider,
  Button,
  Space,
  Tag,
  Image,
  Select,
  Radio,
  Checkbox,
} from "antd";
import PieChartComponent from "../../components/web-crawl/PieChart";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import Loader from "../../components/loader/Loader";
import EmptyData from "../../components/emptydata/EmptyData";
import { mdiTranslate, mdiGraph, mdiOpenInNew } from "@mdi/js";
import Icon from "@mdi/react";
import { ReactComponent as TextSearchIcon } from "../../assets/textSearch.svg";
import moment from "moment";
import { FileTextOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import { useParams } from "react-router-dom";
import Confer from "../../components/web-crawl/Confer";
import Ref from "../../components/web-crawl/Ref";

const ButtonGroup = Button.Group;

const { TabPane } = Tabs;

const { Option } = Select;

const text_style = {
  backgroundColor: "white",
  color: "black",
  padding: "1%",
  borderRadius: "0px",
  fontSize: "18px",
};

const TextTranslationPage = (props) => {
  const { mainStore, webCrawlStore, errorStore } = useMobxStores();

  const [text_words, setTextWords] = useState([]);

  const [pageShown, setPageShown] = useState(-1);
  const [itemShown, setItemShown] = useState("");
  const [analysisTitle, setAnalysisTitle] = useState("Original text");

  const [content, setContent] = useState(null);
  // const [textTranslation, setTextTranslation] = useState(
  //   mainStore.textTranslation
  // );

  const [receivedItems, setReceivedItems] = useState(
    webCrawlStore.receivedItems
  );
  const [tabContent, setTabContent] = useState("1");
  const [tabTop, setTabTop] = useState("1");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingNewWeb, setLoadingNewWeb] = useState(false);
  const [clusterClicked, setClusterClicked] = useState("");
  const [pageAnalysisView, setPageAnalysisView] = useState("ner");

  const [groupBlueChecked, setGroupBlueChecked] = useState(true);
  const [groupOrangeChecked, setGroupOrangeChecked] = useState(true);
  const [groupRedChecked, setGroupRedChecked] = useState(true);
  const [groupLimeChecked, setGroupLimeChecked] = useState(true);
  const [groupPurpleChecked, setGroupPurpleChecked] = useState(true);
  const [groupGreenChecked, setGroupGreenChecked] = useState(true);
  const [groupCyanChecked, setGroupCyanChecked] = useState(true);
  const [groupYellowChecked, setGroupYellowChecked] = useState(true);

  const [blueGroup, setBlueGroup] = useState([]);
  const [orangeGroup, setOrangeGroup] = useState([]);
  const [redGroup, setRedGroup] = useState([]);
  const [purpleGroup, setPurpleGroup] = useState([]);
  const [limeGroup, setLimeGroup] = useState([]);
  const [greenGroup, setGreenGroup] = useState([]);
  const [cyanGroup, setCyanGroup] = useState([]);
  const [yellowGroup, setYellowGroup] = useState([]);

  useEffect(() => {
    setBlueGroup(mainStore.blueGroup);
  }, [mainStore.blueGroup]);
  useEffect(() => {
    setOrangeGroup(mainStore.orangeGroup);
    setRedGroup(mainStore.redGroup);
    setPurpleGroup(mainStore.purpleGroup);
    setLimeGroup(mainStore.limeGroup);
    setGreenGroup(mainStore.greenGroup);
    setCyanGroup(mainStore.cyanGroup);
    setYellowGroup(mainStore.yellowGroup);
  }, [mainStore.orangeGroup]);
  useEffect(() => {
    setRedGroup(mainStore.redGroup);
  }, [mainStore.redGroup]);
  useEffect(() => {
    setPurpleGroup(mainStore.purpleGroup);
  }, [mainStore.purpleGroup]);
  useEffect(() => {
    setLimeGroup(mainStore.limeGroup);
  }, [mainStore.limeGroup]);
  useEffect(() => {
    setGreenGroup(mainStore.greenGroup);
  }, [mainStore.greenGroup]);
  useEffect(() => {
    setCyanGroup(mainStore.cyanGroup);
  }, [mainStore.cyanGroup]);
  useEffect(() => {
    setYellowGroup(mainStore.yellowGroup);
  }, [mainStore.yellowGroup]);
  const [finalPage, setFinalPage] = useState(false);

  const [value, setValue] = useState(1);
  const changeRadio = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const onCheckBlue = (e) => {
    setGroupBlueChecked(e.target.checked);
  };
  const onCheckOrange = (e) => {
    setGroupOrangeChecked(e.target.checked);
  };
  const onCheckRed = (e) => {
    setGroupRedChecked(e.target.checked);
  };
  const onCheckLime = (e) => {
    setGroupLimeChecked(e.target.checked);
  };
  const onCheckPurple = (e) => {
    setGroupPurpleChecked(e.target.checked);
  };
  const onCheckGreen = (e) => {
    setGroupGreenChecked(e.target.checked);
  };
  const onCheckCyan = (e) => {
    setGroupCyanChecked(e.target.checked);
  };
  const onCheckYellow = (e) => {
    setGroupYellowChecked(e.target.checked);
  };

  var _ = require("lodash");
  let params = useParams();

  useEffect(() => {
    setItemShown(webCrawlStore.reportPage);

    // setSelectedClassification("");
  }, []);
  // Function to handle sorting based on classification
  const goBackToList = () => {
    webCrawlStore.setWebCrawlPaginationPageToList();
  };

  const newTab = (key) => {
    if (key === "1") {
      console.log(1);
    }
    if (key === "2") {
      console.log(3);
      mainStore.projectIdForSN = params.pk;
      mainStore.Publish({
        topics: ["MA_SNA_REQUEST"],
        body: JSON.stringify({
          header: {
            topicName: "MA_SNA_REQUEST",
            sender: "INTRA",
            sentUtc: moment().format("yyyy-MM-dd'T'HH:mm:ss'Z'"),
            source: "INTRA",
            caseId: params.pk,
          },
          body: {
            taskId: params.id, //enum: ["ABCD_SYNTHETIC","PHONE_DATASET"]
          },
        }),
      });
    }

    setTabTop(key);
  };

  const newContent = (key) => {
    if (key === "1") {
      setAnalysisTitle("Page Analysis - Original text");
    }
    if (key === "2") {
      setAnalysisTitle("Page Analysis - Translation");
    }
    if (key === "3") {
      setAnalysisTitle("Page Analysis - Text analysis");
      setTextWords(
        itemShown.attributes.translation
          ? itemShown.attributes.translation[0].split(" ")
          : itemShown.content.split(" ")
      );
      mainStore.setTextWords(
        itemShown.attributes.translation
          ? itemShown.attributes.translation[0].split(" ")
          : itemShown.content.split(" ")
      );
      mainStore.setCharachters(
        itemShown.attributes.translation
          ? itemShown.attributes.translation[0].split("")
          : itemShown.content.split("")
      );
      console.log(itemShown);
      mainStore.GetAnalyse(params.pk, itemShown.domainId);
      // mainStore.GetPieAnalysis(params.pk, itemShown.domainId);
    }
    if (key === "4") {
      setAnalysisTitle("Page Analysis - Visual analysis");
      // mainStore.GetLinksSourceDomainId(
      //   params.pk,
      //   _.cloneDeep(textTranslation)[pageShown].domainId
      // );
      mainStore.GetLinksSourceDomainId(params.pk, itemShown.domainId);
    }
    if (key === "5") {
      mainStore.projectIdForSN = params.pk;
      mainStore.Publish({
        topics: ["MA_SNA_REQUEST"],
        body: JSON.stringify({
          header: {
            topicName: "MA_SNA_REQUEST",
            sender: "INTRA",
            sentUtc: moment().format("yyyy-MM-dd'T'HH:mm:ss'Z'"),
            source: "INTRA",
            caseId: params.pk,
          },
          body: {
            taskId: params.id, //enum: ["ABCD_SYNTHETIC","PHONE_DATASET"]
          },
        }),
      });
    }
    if (key === "6") {
      mainStore.projectIdForClustering = params.pk;
      // mainStore.Publish({
      //   topics: ["MA_CLUSTERING_REQUEST"],
      //   body: JSON.stringify({
      //     header: {
      //       topicName: "MA_CLUSTERING_REQUEST",
      //       sender: "INTRA",
      //       sentUtc: "2023-01-01T00:00:00+0000",
      //       source: "DASHBOARD",
      //       caseId: params.pk,
      //     },
      //     body: {
      //       taskId: params.id,
      //     },
      //   }),
      // });
    }

    setTabContent(key);
  };

  useEffect(() => {
    setLoadingNewWeb(true); // Show spinner
    setTimeout(() => {
      setLoadingNewWeb(false); // Hide spinner after 1 second
    }, 1000);

    webCrawlStore.GetAllPagesFromWebCrawl(params.pk, params.id);
    mainStore.GetclassificationModule(params.pk);

    // setSelectedClassification("");
  }, [params.id]);

  const renderedText = mainStore.textWords.map((word, index) => {
    const matchedItem = mainStore.analyse_entities.find(
      (item) => item.text == word
    );
    if (matchedItem && matchedItem.color === "red") {
      return (
        <span
          key={index}
          style={{
            backgroundColor: matchedItem.color,
            marginLeft: "3px",
            marginRight: "3px",
            marginTop: "3px",
            marginBottom: "3px",
            color: "white",
          }}
        >
          {word}
        </span>
      );
    }
    if (matchedItem && matchedItem.color === "#00008B") {
      return (
        <span
          key={index}
          style={{
            backgroundColor: matchedItem.color,
            marginLeft: "3px",
            marginRight: "3px",
            marginTop: "3px",
            marginBottom: "3px",
            color: "white",
          }}
        >
          {word}
        </span>
      );
    }
    if (
      (matchedItem && matchedItem.color !== "red") ||
      (matchedItem && matchedItem.color !== "#00008B")
    ) {
      return (
        <span
          key={index}
          style={{
            backgroundColor: matchedItem.color,
            marginLeft: "3px",
            marginRight: "3px",
            marginTop: "3px",
            marginBottom: "3px",
            color: "black",
          }}
        >
          {word}
        </span>
      );
    }

    return (
      <span
        key={index}
        style={{
          marginLeft: "3px",
          marginRight: "3px",
        }}
      >
        {word}
      </span>
    );
  });

  return (
    <div>
      <div>
        {" "}
        <Row style={{ marginBottom: "-8px" }}>
          <Col
            span={20}
            style={{
              textAlign: "left",
            }}
          >
            <div style={{ marginTop: "-18px" }}>
              <Row
                justify={"center"}
                align={"top"}
                gutter={[20, 20]}
                style={{
                  height: "100%",
                }}
              >
                <Col
                  span={24}
                  style={{
                    textAlign: "left",
                  }}
                >
                  {" "}
                  <span style={{ fontWeight: "bold" }}>Page url:</span>
                  <span> {itemShown.name}</span>
                </Col>
              </Row>
            </div>
            <div style={{ marginTop: "2px", marginBottom: "-4px" }}>
              <Row
                justify={"start"}
                align={"top"}
                gutter={[20, 20]}
                style={{
                  height: "100%",
                }}
              >
                <Col
                  span={24}
                  style={{
                    textAlign: "left",
                  }}
                >
                  {" "}
                  <span style={{ fontWeight: "bold" }}>Page title:</span>
                  <span> {itemShown.title}</span>
                </Col>{" "}
              </Row>
            </div>
            <div style={{ marginTop: "2px", marginBottom: "-12px" }}>
              <Row
                justify={"start"}
                align={"top"}
                gutter={[20, 20]}
                style={{
                  height: "100%",
                }}
              >
                <Col
                  span={24}
                  style={{
                    textAlign: "left",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>
                    Report classification:
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    {itemShown.classification && (
                      <span>
                        <Tag color="orange">{itemShown.classification}</Tag>
                      </span>
                    )}
                  </span>
                </Col>
              </Row>
            </div>
          </Col>
          <Col
            span={4}
            style={{
              textAlign: "left",
            }}
          >
            <Button
              className="addRecipeButton"
              type="default"
              onClick={() => {
                goBackToList();
              }}
              size="medium"
              style={{
                backgroundColor: "#354289",
                color: "black", // Change text color to black
                marginLeft: "2px",
                color: "white",
                width: "160px",
              }}
            >
              <Row align="center">
                <span>
                  <UnorderedListOutlined
                    style={{
                      fontSize: "20px",
                      marginRight: "4px",
                      marginTop: "2px",
                      marginLeft: "6px",
                    }}
                  />
                </span>
                <span> View page list</span>{" "}
              </Row>
            </Button>
          </Col>
        </Row>
        <Divider></Divider>
      </div>

      <div style={{ marginTop: "-12px" }}>
        <Row>
          <Col span={4} lg={4} xl={4} md={4} xs={0} sm={0}>
            <Tabs defaultActiveKey="1" onChange={newContent} tabPosition="left">
              <TabPane
                tab={
                  <span>
                    <FileTextOutlined
                      style={{ width: "22px", height: "22px" }}
                    />
                    Original text
                  </span>
                }
                key="1"
              ></TabPane>
              <TabPane
                tab={
                  <span>
                    <Icon path={mdiTranslate} size={1} />
                    Translation
                  </span>
                }
                key="2"
              ></TabPane>
              <TabPane
                tab={
                  <span>
                    <TextSearchIcon style={{ width: "22px", height: "22px" }} />
                    Page analysis
                  </span>
                }
                key="3"
              ></TabPane>
              <TabPane
                tab={
                  <span>
                    <TextSearchIcon style={{ width: "22px", height: "22px" }} />
                    Visual analysis
                  </span>
                }
                key="4"
              ></TabPane>
            </Tabs>
          </Col>
          <Col span={20} lg={20} xl={20} md={20} xs={24} sm={24}>
            <div
              className="showOnPrint"
              style={{
                display: "none",
                justifyContent: "center" /* Centers horizontally */,
                alignItems: "center" /* Centers vertically */,

                width: "100%",
                height: "40px",
              }}
            >
              {" "}
              {analysisTitle}
            </div>{" "}
            {tabContent === "1" && (
              <div
                className="showOnPrintAllHeight pageAnalysisSizes"
                style={{
                  marginLeft: "40px",
                  // width: "52vw",
                  width: "90%",
                  height: "320px",
                  border: "1px solid black",
                  overflowY: "auto",
                }}
              >
                {itemShown.content}

                {
                  <div>
                    <Divider />
                  </div>
                }
              </div>
            )}
            {tabContent === "2" && (
              <div
                className="showOnPrintAllHeight pageAnalysisSizes"
                style={{
                  marginLeft: "40px",
                  width: "90%",
                  height: "320px",
                  border: "1px  solid black",
                  overflowY: "auto",
                }}
              >
                <Row align={"top"} justify={"center"}>
                  <Col span={12}>
                    <Divider orientation="center">Original text </Divider>
                    <p style={text_style}>{itemShown.content}</p>
                  </Col>
                  <Col span={12}>
                    <Divider orientation="center">Translated text </Divider>
                    <p style={text_style}>
                      {itemShown.attributes.translation
                        ? itemShown.attributes.translation[0]
                        : "No translation yet"}
                    </p>
                  </Col>
                </Row>
              </div>
            )}
            {tabContent === "3" && (
              <div>
                <div
                  className="showOnPrintAllHeight  "
                  style={{
                    // width: "52vw",
                    width: "90%",
                    border: "1px solid black",
                    borderBottom: "0px  ",
                    marginBottom: "6px",
                    marginTop: "6px",
                    marginLeft: "40px",
                    minWidth: "740px",
                    // marginLeft: "42px",
                    // // zIndex: 4,
                    // position: "static",
                    // backgroundColor: "white",
                  }}
                >
                  <Row>
                    <div
                      className="showOnPrintAllHeight"
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center", // Center vertically
                        justifyContent: "center",
                        marginTop: "4px",
                        marginBottom: "4px",
                      }}
                    >
                      {" "}
                      <ButtonGroup>
                        <Radio.Group
                          value={pageAnalysisView}
                          onChange={(e) => setPageAnalysisView(e.target.value)}
                        >
                          <Radio.Button value="ner">NER VIEW</Radio.Button>
                          <Radio.Button value="coref">COREF VIEW</Radio.Button>
                        </Radio.Group>
                      </ButtonGroup>
                    </div>
                  </Row>
                  {pageAnalysisView === "ner" && (
                    <div
                      className="showOnPrintAllHeight pageAnalysisSizesNer"
                      style={{
                        // marginLeft: "40px",
                        width: "100%",
                        height: "280px",
                        border: "1px  solid black",
                        overflowY: "auto",
                        marginTop: "6px",
                        minWidth: "740px",
                      }}
                    >
                      <Row
                        style={{
                          borderTop: "1px  solid black",
                        }}
                      >
                        <span>Labels:</span>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center", // Center vertically
                            justifyContent: "center",
                            width: "66px",
                            marginTop: "2px",
                            marginBottom: "2px",
                            marginLeft: "12px",
                            marginRight: "2px",
                            backgroundColor: "red",
                            color: "white",
                          }}
                        >
                          <div
                            style={{
                              marginRight: "1px",
                              marginLeft: "1px",
                            }}
                          >
                            Substance
                          </div>
                        </span>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center", // Center vertically
                            justifyContent: "center",
                            width: "46px",
                            marginTop: "2px",
                            marginBottom: "2px",
                            marginLeft: "1px",
                            marginRight: "1px",
                            backgroundColor: "#BDB76B",
                            color: "black",
                          }}
                        >
                          <div
                            style={{
                              justify: "center",
                              display: "flex",
                              marginRight: "1px",
                              marginLeft: "1px",
                            }}
                          >
                            Date
                          </div>
                        </span>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center", // Center vertically
                            justifyContent: "center",
                            width: "72px",
                            marginTop: "2px",
                            marginBottom: "2px",
                            marginLeft: "1px",
                            marginRight: "1px",
                            backgroundColor: "#FAEBD7",
                            color: "black",
                          }}
                        >
                          <div
                            style={{
                              marginRight: "1px",
                              marginLeft: "1px",
                            }}
                          >
                            Glassware
                          </div>
                        </span>

                        <span
                          style={{
                            display: "flex",
                            alignItems: "center", // Center vertically
                            justifyContent: "center",
                            width: "66px",
                            marginTop: "2px",
                            marginBottom: "2px",
                            marginLeft: "1px",
                            marginRight: "1px",
                            backgroundColor: "#7FFF00",
                            color: "black",
                          }}
                        >
                          <div
                            style={{
                              marginRight: "1px",
                              marginLeft: "1px",
                            }}
                          >
                            Location
                          </div>
                        </span>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center", // Center vertically
                            justifyContent: "center",
                            width: "66px",
                            marginTop: "2px",
                            marginBottom: "2px",
                            marginLeft: "1px",
                            marginRight: "1px",
                            backgroundColor: "#D2691E",
                            color: "black",
                          }}
                        >
                          <div
                            style={{
                              marginRight: "1px",
                              marginLeft: "1px",
                            }}
                          >
                            Group
                          </div>
                        </span>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center", // Center vertically
                            justifyContent: "center",
                            width: "66px",
                            marginTop: "2px",
                            marginBottom: "2px",
                            marginLeft: "1px",
                            marginRight: "1px",
                            backgroundColor: "#FF7F50",
                            color: "black",
                          }}
                        >
                          <div
                            style={{
                              marginRight: "1px",
                              marginLeft: "1px",
                            }}
                          >
                            Item
                          </div>
                        </span>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center", // Center vertically
                            justifyContent: "center",
                            width: "66px",
                            marginTop: "2px",
                            marginBottom: "2px",
                            marginLeft: "1px",
                            marginRight: "1px",
                            backgroundColor: "#40C4FF",
                            color: "black",
                          }}
                        >
                          <div
                            style={{
                              marginRight: "1px",
                              marginLeft: "1px",
                            }}
                          >
                            Person
                          </div>
                        </span>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center", // Center vertically
                            justifyContent: "center",
                            width: "66px",
                            marginTop: "2px",
                            marginBottom: "2px",
                            marginLeft: "1px",
                            marginRight: "1px",
                            backgroundColor: "#00FFFF",
                            color: "black",
                          }}
                        >
                          <div
                            style={{
                              marginRight: "1px",
                              marginLeft: "1px",
                            }}
                          >
                            Quantity
                          </div>
                        </span>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center", // Center vertically
                            justifyContent: "center",
                            width: "66px",
                            marginTop: "2px",
                            marginBottom: "2px",
                            marginLeft: "1px",
                            marginRight: "1px",
                            backgroundColor: "#00008B",
                            color: "white",
                          }}
                        >
                          <div
                            style={{
                              marginRight: "1px",
                              marginLeft: "1px",
                            }}
                          >
                            Status
                          </div>
                        </span>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center", // Center vertically
                            justifyContent: "center",
                            width: "60px",
                            marginTop: "2px",
                            marginBottom: "2px",
                            marginLeft: "1px",
                            marginRight: "1px",
                            backgroundColor: "#E040FB",
                            color: "black",
                          }}
                        >
                          <div
                            style={{
                              marginRight: "1px",
                              marginLeft: "1px",
                            }}
                          >
                            Art
                          </div>
                        </span>
                      </Row>

                      <div>
                        <Ref pk={params.pk}></Ref>
                      </div>

                      {mainStore.analyse_entities.length > 0 ? (
                        <span>
                          {pageAnalysisView === "ner" && (
                            <PieChartComponent pieData={mainStore.pieData} />
                          )}
                        </span>
                      ) : (
                        <span>No data</span>
                      )}

                      <PieChartComponent pieData={mainStore.pieData} />
                    </div>
                  )}
                  {pageAnalysisView === "coref" && (
                    <div
                      className="showOnPrintAllHeight pageAnalysisSizesNer"
                      style={{
                        // marginLeft: "40px",
                        width: "100%",
                        height: "280px",
                        border: "1px  solid black",
                        overflowY: "auto",
                        marginTop: "6px",
                        minWidth: "740px",
                      }}
                    >
                      <Row
                        style={{
                          borderTop: "1px  solid black",
                        }}
                      >
                        {" "}
                        <span
                          style={{
                            marginLeft: "3px  ",
                          }}
                        >
                          Words marked :
                        </span>
                        {blueGroup.length > 0 && (
                          <span
                            style={{
                              marginLeft: "3px  ",
                            }}
                          >
                            {" "}
                            <span
                              style={{
                                backgroundColor: "blue",
                                padding: "2px",
                                color: "white",
                              }}
                            >
                              Group 1
                            </span>{" "}
                            <span>
                              <Checkbox
                                checked={groupBlueChecked}
                                onChange={onCheckBlue}
                              ></Checkbox>
                            </span>
                          </span>
                        )}
                        {orangeGroup.length > 0 && (
                          <span
                            style={{
                              marginLeft: "6px  ",
                            }}
                          >
                            {" "}
                            <span
                              style={{
                                backgroundColor: "orange",
                                padding: "2px",
                                color: "black",
                              }}
                            >
                              Group 2
                            </span>{" "}
                            <span>
                              <Checkbox
                                checked={groupOrangeChecked}
                                onChange={onCheckOrange}
                              ></Checkbox>
                            </span>
                          </span>
                        )}{" "}
                        {redGroup.length > 0 && (
                          <span
                            style={{
                              marginLeft: "6px  ",
                            }}
                          >
                            {" "}
                            <span
                              style={{
                                backgroundColor: "red",
                                padding: "2px",
                                color: "white",
                              }}
                            >
                              Group 3
                            </span>{" "}
                            <span>
                              <Checkbox
                                checked={groupRedChecked}
                                onChange={onCheckRed}
                              ></Checkbox>
                            </span>
                          </span>
                        )}
                        {limeGroup.length > 0 && (
                          <span
                            style={{
                              marginLeft: "6px  ",
                            }}
                          >
                            {" "}
                            <span
                              style={{
                                backgroundColor: "#7FFF00",
                                padding: "2px",
                                color: "black",
                              }}
                            >
                              Group 4
                            </span>{" "}
                            <span>
                              <Checkbox
                                checked={groupLimeChecked}
                                onChange={onCheckLime}
                              ></Checkbox>
                            </span>
                          </span>
                        )}
                        {purpleGroup.length > 0 && (
                          <span
                            style={{
                              marginLeft: "6px  ",
                            }}
                          >
                            {" "}
                            <span
                              style={{
                                backgroundColor: "#E040FB",
                                padding: "2px",
                                color: "black",
                              }}
                            >
                              Group 5
                            </span>{" "}
                            <span>
                              <Checkbox
                                checked={groupPurpleChecked}
                                onChange={onCheckPurple}
                              ></Checkbox>
                            </span>
                          </span>
                        )}{" "}
                        {greenGroup.length > 0 && (
                          <span
                            style={{
                              marginLeft: "6px  ",
                            }}
                          >
                            {" "}
                            <span
                              style={{
                                backgroundColor: "green",
                                padding: "2px",
                                color: "white",
                              }}
                            >
                              Group 6
                            </span>{" "}
                            <span>
                              <Checkbox
                                checked={groupGreenChecked}
                                onChange={onCheckGreen}
                              ></Checkbox>
                            </span>
                          </span>
                        )}
                        {cyanGroup.length > 0 && (
                          <span
                            style={{
                              marginLeft: "6px  ",
                            }}
                          >
                            {" "}
                            <span
                              style={{
                                backgroundColor: "#00BCD4",
                                padding: "2px",
                                color: "white",
                              }}
                            >
                              Group 7
                            </span>{" "}
                            <span>
                              <Checkbox
                                checked={groupCyanChecked}
                                onChange={onCheckCyan}
                              ></Checkbox>
                            </span>
                          </span>
                        )}
                        {yellowGroup.length > 0 && (
                          <span
                            style={{
                              marginLeft: "6px  ",
                            }}
                          >
                            {" "}
                            <span
                              style={{
                                backgroundColor: "yellow",
                                padding: "2px",
                                color: "black",
                              }}
                            >
                              Group 8
                            </span>{" "}
                            <span>
                              <Checkbox
                                checked={groupYellowChecked}
                                onChange={onCheckYellow}
                              ></Checkbox>
                            </span>
                          </span>
                        )}
                        <span> </span>
                      </Row>

                      <div>
                        <Confer
                          pk={params.pk}
                          domainId={itemShown.domainId}
                          groupBlueChecked={groupBlueChecked}
                          groupOrangeChecked={groupOrangeChecked}
                          groupRedChecked={groupRedChecked}
                          groupLimeChecked={groupLimeChecked}
                          groupPurpleChecked={groupPurpleChecked}
                          groupGreenChecked={groupGreenChecked}
                          groupCyanChecked={groupCyanChecked}
                          groupYellowChecked={groupYellowChecked}
                        ></Confer>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {tabContent === "4" && (
              <div>
                <div
                  className="showOnPrintAllHeight pageAnalysisSizes"
                  style={{
                    marginLeft: "40px",
                    width: "90%",
                    height: "280px",
                    border: "1px  solid black",
                    overflowY: "auto",
                    marginTop: "-6px",
                    minWidth: "740px",
                  }}
                >
                  <div>
                    {" "}
                    {
                      // _.cloneDeep(textTranslation)[pageShown].id ==
                      //   expandedId &&
                      mainStore.visual_images != [] &&
                        mainStore.visual_images.map((item) => {
                          return (
                            <Space orientation="horizontal">
                              <Image width={"200px"} src={item} />{" "}
                            </Space>
                          );
                        })
                    }
                  </div>
                </div>
              </div>
            )}
            {/* <div>
                            {/* {errorStore.loading && <Loader />} */}
            {/* {textTranslation.length === 0 &&
                              !errorStore.loading && <EmptyData />}
                          </div>   */}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default observer(TextTranslationPage);
