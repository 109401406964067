import React, { useEffect, useState, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Tooltip,
  Polyline,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";
import axios from "axios";
import { Card } from "antd";
import "leaflet-arrowheads";
import { constants } from "../../consts/Variables";

const mapStyle = { height: "600px", width: "auto", borderRadius: "15px" };

const customOrange = new L.icon({
  iconUrl: "../../../orange-pin.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

const customMarker = new L.icon({
  iconUrl: "../../../blue-pin.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

const customRed = new L.icon({
  iconUrl: "../../../red-pin.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

const ArrowheadPolyline = ({ positions, color }) => {
  const map = useMap();
  const polylineRef = useRef(null);

  useEffect(() => {
    if (polylineRef.current) {
      const arrowheadOptions = {
        size: "20px",
        frequency: "endonly",
        fill: true,

        yawn: 40,
      };
      L.polyline(positions, { color: color })
        .arrowheads(arrowheadOptions)
        .addTo(map);
    }
  }, [map, positions, color]);

  return <Polyline ref={polylineRef} positions={positions} color={color} />;
};

const TransactionMap = (props) => {
  const { mainStore } = useMobxStores();
  const [maxBounds, setMaxBounds] = useState(null);

  const handleMapClick = (e) => {
    console.log(e.latlng.lat, e.latlng.lng);
    // setMarkerPosition([e.latlng.lat, e.latlng.lng]);
  };

  useEffect(() => {
    axios
      .get(
        "https://cdn.rawgit.com/johan/world.geo.json/34c96bba/countries/GRC.geo.json"
      )
      .then((response) => {
        const geoJSON = response.data;
        setMaxBounds(L.geoJSON(geoJSON).getBounds());
      });
  }, [setMaxBounds, mainStore.transcation_coords]);

  return maxBounds ? (
    mainStore.supplyChain.length === 0 ? (
      <div id="map">
        <MapContainer
          zoom={6}
          minZoom={1}
          scrollWheelZoom={true}
          // maxBounds={maxBounds}
          style={mapStyle}
          center={[props.coords[0][0], props.coords[1][0]]}
          onClick={handleMapClick}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url={
              constants.ONLINE == "true"
                ? "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                : `https://map.romania.odysseus-offline.rid-intrasoft.eu/tile/{z}/{x}/{y}.png`
            }
            // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {props != null && (
            <Marker
              position={[props.coords[0][0], props.coords[1][0]]}
              icon={customMarker}
            >
              <Popup style={{ width: "max-content" }}>
                {/* <Card
                  title={`Cluster ${item.id}`}
                  style={{
                    width: "auto",
                    minWidth: "300px",
                    textAlign: "left",
                  }}
                  bodyStyle={{
                    width: "auto",
                    textAlign: "left",
                    overflow: "scroll",
                    maxHeight: "60vh",
                    height: "auto",
                  }}
                >
                  {` Confidence: ${item.properties.confidence}`}
                  <br />
                  <a href={item.properties.detailsLink}>Details</a>
                </Card> */}
              </Popup>
            </Marker>
          )}
        </MapContainer>
      </div>
    ) : (
      <div id="map">
        <MapContainer
          zoom={6}
          minZoom={1}
          scrollWheelZoom={true}
          // maxBounds={maxBounds}
          style={mapStyle}
          //       center={[props.coords[0][0], props.coords[1][0]]}
          center={[mainStore.supplyChain[0][0], mainStore.supplyChain[1][0]]}
          onClick={handleMapClick}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {mainStore.supplyChain != null && (
            <Marker
              // position={[props.coords[0][0], props.coords[1][0]]}
              position={[
                mainStore.supplyChain[0][0] + 0.1,
                mainStore.supplyChain[0][1],
              ]}
              icon={customMarker}
            >
              <Tooltip direction="top" offset={[0, -40]} opacity={1} permanent>
                <div>date : {mainStore.supplyChain[0][2]}</div>
              </Tooltip>
              {/* <Popup style={{ width: "max-content" }}> */}
              {/* <Card
                title={`Cluster ${item.id}`}
                style={{
                  width: "auto",
                  minWidth: "300px",
                  textAlign: "left",
                }}
                bodyStyle={{
                  width: "auto",
                  textAlign: "left",
                  overflow: "scroll",
                  maxHeight: "60vh",
                  height: "auto",
                }}
              >
                {` Confidence: ${item.properties.confidence}`}
                <br />
                <a href={item.properties.detailsLink}>Details</a>
              </Card> */}
              {/* </Popup> */}
            </Marker>
          )}
          {mainStore.supplyChain && mainStore.supplyChain.length > 1 && (
            <>
              <ArrowheadPolyline
                positions={[mainStore.supplyChain[0], mainStore.supplyChain[1]]}
                color="red"
              ></ArrowheadPolyline>
              <Marker
                // position={[props.coords[0][0], props.coords[1][0]]}
                position={[
                  mainStore.supplyChain[1][0] + 0.1,
                  mainStore.supplyChain[1][1],
                ]}
                icon={customMarker}
              >
                <Tooltip
                  direction="top"
                  offset={[0, -40]}
                  opacity={1}
                  permanent
                >
                  <div>date : {mainStore.supplyChain[1][2]}</div>
                </Tooltip>
                {/* <Popup style={{ width: "max-content" }}> */}
                {/* <Card
                title={`Cluster ${item.id}`}
                style={{
                  width: "auto",
                  minWidth: "300px",
                  textAlign: "left",
                }}
                bodyStyle={{
                  width: "auto",
                  textAlign: "left",
                  overflow: "scroll",
                  maxHeight: "60vh",
                  height: "auto",
                }}
              >
                {` Confidence: ${item.properties.confidence}`}
                <br />
                <a href={item.properties.detailsLink}>Details</a>
              </Card> */}
                {/* </Popup> */}
              </Marker>
            </>
          )}
          {mainStore.supplyChain.length > 2 && (
            <>
              <ArrowheadPolyline
                positions={[mainStore.supplyChain[1], mainStore.supplyChain[2]]}
                color="blue"
              />
              <Marker
                // position={[props.coords[0][0], props.coords[1][0]]}
                position={[
                  mainStore.supplyChain[2][0] + 0.1,
                  mainStore.supplyChain[2][1],
                ]}
                icon={customMarker}
              >
                <Tooltip
                  direction="top"
                  offset={[0, -40]}
                  opacity={1}
                  permanent
                >
                  <div> date : {mainStore.supplyChain[2][2]}</div>
                </Tooltip>
                {/* <Popup style={{ width: "max-content" }}> */}
                {/* <Card
                title={`Cluster ${item.id}`}
                style={{
                  width: "auto",
                  minWidth: "300px",
                  textAlign: "left",
                }}
                bodyStyle={{
                  width: "auto",
                  textAlign: "left",
                  overflow: "scroll",
                  maxHeight: "60vh",
                  height: "auto",
                }}
              >
                {` Confidence: ${item.properties.confidence}`}
                <br />
                <a href={item.properties.detailsLink}>Details</a>
              </Card> */}
                {/* </Popup> */}
              </Marker>
            </>
          )}
          {mainStore.supplyChain.length > 3 && (
            <>
              <ArrowheadPolyline
                positions={[mainStore.supplyChain[2], mainStore.supplyChain[3]]}
                color="green"
              />
              <Marker
                // position={[props.coords[0][0], props.coords[1][0]]}
                position={[
                  mainStore.supplyChain[3][0] + 0.1,
                  mainStore.supplyChain[3][1],
                ]}
                icon={customMarker}
              >
                <Tooltip
                  direction="top"
                  offset={[0, -40]}
                  opacity={1}
                  permanent
                >
                  <div>date : {mainStore.supplyChain[3][2]} </div>
                </Tooltip>
                {/* <Popup style={{ width: "max-content" }}> */}
                {/* <Card
                title={`Cluster ${item.id}`}
                style={{
                  width: "auto",
                  minWidth: "300px",
                  textAlign: "left",
                }}
                bodyStyle={{
                  width: "auto",
                  textAlign: "left",
                  overflow: "scroll",
                  maxHeight: "60vh",
                  height: "auto",
                }}
              >
                {` Confidence: ${item.properties.confidence}`}
                <br />
                <a href={item.properties.detailsLink}>Details</a>
              </Card> */}
                {/* </Popup> */}
              </Marker>
            </>
          )}
        </MapContainer>
      </div>
    )
  ) : (
    <></>
  );
};

export default observer(TransactionMap);
